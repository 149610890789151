import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import PropTypes from "prop-types";

// Constants & Config
import {
  FEATURE,
  ROOT_CONSTANT,
  ROUTES,
  COMMON_FEATURES,
} from "../../constants";

// Components
import Layout from "./Layout";
import ConfirmLogout from '../../components/ButtonModal/modules/ConfirmLogout'

// Services

// Redux Action
import { v1_user } from "../../services/redux/actions";

const { FEATURE_TYPE } = COMMON_FEATURES;

const {
  FEATURE_MENU_ITEMS_OBJ,
  FEATURE_MENU_ITEMS_COMP_LAYER_FIRST,
  FEATURE_MENU_ITEMS_COMP_LAYER_SECOND,
} = FEATURE;

const { user_sign_out_request } = v1_user;

const LayoutContainer = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = (st) => {
		setIsModalOpen(st);
	};

	const onClickConfirmLogout = () => dispatch(user_sign_out_request())

	const SIClickHander = (sidebarItem) => {
		const {
			key
		} = sidebarItem

    const {
			type
		} = FEATURE_MENU_ITEMS_OBJ[key]

    switch (type) {
      case FEATURE_TYPE.ROUTE: {
        navigate(ROUTES.MODULE_ROUTE[key]);
        break;
      }
      case FEATURE_TYPE.LOGOUT: {
        handleModal(true)
        break;
      }
      default: {
        break;
      }
    }

    return 1;
  };

  const sidebarItems = [
    ...FEATURE_MENU_ITEMS_COMP_LAYER_FIRST,
    { key: "dvider", label: "dvider", icon: <></>, type: "divider" },
    ...FEATURE_MENU_ITEMS_COMP_LAYER_SECOND,
  ];

	return (
		<>
			<Layout
				collapsed = {false}
				sidebarItems = {sidebarItems}
				selectedkeys = {location ? location.pathname : ""}
				themeColor = {ROOT_CONSTANT.THEME.THEME_COLOR}
				onClickSidebarItem = {sidebarItem => SIClickHander(sidebarItem)}
			>
				{props.children}
			</Layout>
			<ConfirmLogout
				isModalOpen={isModalOpen}
				setIsModalOpen={() => setIsModalOpen(false)}
				onClick={() => onClickConfirmLogout()}
			/>
		</>
	);
}

LayoutContainer.propTypes = {
	children: PropTypes.element
};

export default LayoutContainer