import * as API from "../../api"
import * as ActionTypes from "../action-types";

// Redux Action

export const sso_verify_link_request = (link, cb) => dispatch => {

    API
    .verifyLink(link)
    .then(response => {
        const {
            status,
            msg,
            extra = {}
        } = response

        if(status && status >= 200 && status < 300) {

            cb({status: status, msg, extra})

            dispatch(sso_verify_link_success())
            
        } else {
            cb({status: 404, err: msg})
        }
    })
    .catch(err => {
        cb({status: 404, err})
    })
}

export const sso_verify_link_success = () => {
    return {
        type: ActionTypes.SSO_VERIFY_LINK
    };
}