import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

/**
 * Sub Components
 */
import SSOLoadingPanel from "./components/SSOLoadingPanel";

// Sytle
import classStyles from "./index.module.css";

const SSOLoading = () => {
  // const dispatch = useDispatch()
  // const navigate = useNavigate()

    // const dispatch = useDispatch()
    // const navigate = useNavigate()

    // const auth = useSelector(state => state.root.auth)

    // useEffect(() => {
    //     auth.currentUser && navigate('/')

    //     return (() => {})
    // }, [auth, dispatch])
    
    return (
        <div className={classStyles.inputForm}>
            <div className={classStyles.inputContainer}>
                <SSOLoadingPanel />
            </div>
        </div>
    );
}

export default React.memo(SSOLoading);
