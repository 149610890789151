// React Thirty Party Library
import React from "react";
import { message } from "antd";

// Constants Library

// Components Library
import WalletAmountInfo from "./components/WalletAmountInfo";
import TopUpAccountInfo from "./components/TopUpAccountInfo";
import TopUpStep from "./components/TopUpStep";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import styles from "./common.module.css";

const TopUp = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const messageApiFunc = () => {
    messageApi.open({
      type: "success",
      content: "Copied!",
      duration: 4,
    });
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles.banner}>
        <WalletAmountInfo />
        <TopUpAccountInfo messageApi={messageApiFunc} />
      </div>
      <TopUpStep messageApi={messageApiFunc} />
    </div>
  );
};

export default TopUp;
