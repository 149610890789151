import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

// Constants & Config
import { ROUTES } from "./constants";

// Components
import { BackgroundTask, NetworkValidator, PageHeaderBar } from "./components";
import MainLayout from "./layout/MainLayout";
// Route
import MainRoutes from "./services/navigation/route/main";

// Service
import { REDUX as REDUX_UTIL } from "./services/util";

// styles
import "./index.css";

const { MODULE_ROUTE_FEATURE_TITLE_BY_PATH } = ROUTES;

const { reduxStoreWrapper } = REDUX_UTIL;

const App = (props) => {
  const location = useLocation();
  const user = useSelector(reduxStoreWrapper("user"));
  const wallet = useSelector(reduxStoreWrapper("wallet"));

  const { pathname } = location;

  const { name = "", memid = "" } = user;

  const pageTitle = MODULE_ROUTE_FEATURE_TITLE_BY_PATH[pathname] || "";

  return (
    <>
      <BackgroundTask />
      <MainLayout>
        <>
          {<NetworkValidator />}
          <div className="app-frame">
            <div className="app-content">
              <PageHeaderBar
                title={pageTitle}
                name={name}
                memid={memid}
                wallet_currency={wallet.currency}
                wallet_amount={wallet.balance}
              />
              <MainRoutes {...props} />
            </div>
          </div>
        </>
      </MainLayout>
    </>
  );
};

export default React.memo(App);
