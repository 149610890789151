import 
    React
from "react"
import { useNavigate } from 'react-router'
import { 
    Button, 
} from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'

// Constant
import { ROUTE as ROUTE_CONSTANT } from '../../../../constants'

// Components
import { CopyrightFooter, ProgressCircleNumber } from '../../../../components'

/**
 * Redux Actions
 */

// Style
import classStyles from './index.module.css'
import './index.css'

const SSOResetPasswordSuccess = () => {

    const navigate = useNavigate(); 

    return (
        <div className={classStyles.inputForm}>
            <div className={classStyles.inputContainer}>
                <h4>
                    <CheckCircleFilled className={classStyles.checkedIcon}/>
                    <strong>All Done!</strong>
                </h4>
                <p className="mt-1">Your password has been reset.</p>
                <div className={classStyles.inputWrap}>

                    <div className={classStyles.invisibleBox} />

                    <div className={classStyles.inputFormContent}>
                        <Button 
                            className={classStyles.inputButton} type="primary" htmlType="submit"
                            onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Login)}
                        >
                            Log In
                        </Button>
                    </div>

                    <div className={classStyles.progressRow}>
                        <ProgressCircleNumber turn={4} circle={4}/>
                    </div>

                    <CopyrightFooter />
                </div>
            </div>
        </div>
    )
}

export default SSOResetPasswordSuccess