// Third-party Library
import { Upload } from "antd";
// import ImgCrop from 'antd-img-crop';

// Handler

import "../index.css";

const StatusUpdateAttachment = ({ data }) => {
  const imgUrls = data?.attachment;

  const img =
    imgUrls &&
    imgUrls.map((u, index) => {
      return {
        uid: index,
        name: `attachment-${index}`,
        url: u.url,
        status: "done",
      };
    });

  return (
    <>
      <Upload listType="picture-card" className="attachment" fileList={img} disabled>
        {imgUrls && img.length && ""}
      </Upload>
    </>
  );
};

export default StatusUpdateAttachment;
