// Third-party Library 
import React from "react";
import { Form } from "antd";

// Components 
import { ProfileData, ProfileForm } from "./components";

const ProfileCompany = ({ 
    user = {}, 
    isEdited = false, 
    form,
    optionsState = []
}) => {

    return (
        <>
        {!isEdited ? (
            <ProfileData forms={user} />
        ) : (
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    style={{ marginTop: "10px", overflow: 'hidden' }}
                >
                    <ProfileForm
                        optionsState={optionsState}
                    />
                </Form>
        )}
        </>
    );
};

export default ProfileCompany;
