import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config

// Services
import { TIME } from "../../util";

// Redux Action
import {
  setLoadingOrderSubmit,
  unsetLoadingOrderSubmit,
  setLoadingOrderList,
  unsetLoadingOrderList,
} from "./style";

// Neccessary Actions

const { lastNDay, currentTimeMilliSecond } = TIME;

export const submit_order_request =
  ({ order, uid, memid, name }, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLoadingOrderSubmit());

    API.submitOrder({
      order: order,
      uid: uid,
      memid: memid,
      name: name,
    })
      .then((info) => {
        const { status, order, msg } = info;

        cb(info)

        // if (status === 200) {
        //   // dispatch(submit_order_success(order, ordid))
        //   cb({ordid: order.ordid, status, msg});
        // } else {
        //   message.error(msg);
        // }

        dispatch(unsetLoadingOrderSubmit());
      })
      .catch(() => {
        dispatch(unsetLoadingOrderSubmit());
      })
      .finally(() => {
        dispatch(unsetLoadingOrderSubmit());
      });
  };

const submit_order_success = (order, ordid) => {
  return {
    type: ActionTypes.ORDER_SUBMIT,
    order,
    ordid,
  };
};

// Get Order TimeFrame Data

export const get_order_timeframe_thru_uid_last_90_days_request =
  (uid) => (dispatch) => {
    dispatch(
      get_order_timeframe_thru_uid_request(
        uid,
        lastNDay(90),
        currentTimeMilliSecond()
      )
    );
  };

export const get_order_timeframe_thru_uid_request =
  (uid, startTime, endTime) => (dispatch) => {
    dispatch(setLoadingOrderList());

    API.getOrderTimeFrameThruUID({
      uid,
      startTime,
      endTime,
    })
      .then((info) => {
        const { status, orders, msg } = info;

        if (status === 200) {
          dispatch(get_order_timeframe_thru_uid_success(orders));
          message.success("Get Order List Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingOrderList());
      });
  };

const get_order_timeframe_thru_uid_success = (orders) => {
  return {
    type: ActionTypes.ORDER_TIMEFRAME_GET_TRU_UID,
    orders,
  };
};

export const get_order_by_ordid_success = (order) => {
  return {
    type: ActionTypes.ORDER_GET_BY_ORDID,
    order,
  };
};

export const get_order_status_completed_request = (uid) => (dispatch) => {
  API.getOrderCompleted(uid).then((info) => {
    const { status, countCompletedOrders, msg } = info;

    if (status === 200) {
      dispatch(get_order_status_completed_success(countCompletedOrders));
      message.success("Get Order Completed Status Successfully");
    } else {
      message.warning(msg);
    }
  });
};

const get_order_status_completed_success = (order) => {
  return {
    type: ActionTypes.GET_ORDER_STATUS_COMPLETED,
    order,
  };
};
