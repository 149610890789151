export const TABLE_BILLING_INDEX = {
  key: "key",
  Billing_ID: "billid",
  Billing_Type: "type",
  Billing_Status: "status",
  Billing_Date: "createdAt",
  Price: "amount",
  Transaction_Id: "txnid",
  attachment: "url",
  orderId: "ordid",
  Remarks: "remark",
};

export const TABLE_BILLING_TITLE = {
  key: "key",
  Billing_ID: "Billing ID",
  Billing_Type: "Billing Type",
  Billing_Status: "Billing Status",
  Billing_Date: "Billing Date",
  Price: "Price",
  Transaction_Id: "Transaction ID",
  attachment: "Attachment",
  orderId: "Order ID",
  Remarks: "Remarks",
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "LAST 30 DAYS",
  LAST_60_DAYS: "LAST 60 DAYS",
  LAST_90_DAYS: "LAST 90 DAYS",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const BILLING_STATUS = {
  Rejected: 404,
  Success: 200,
  Pending: 100,
  Cancelled: 300,
};

export const OPTION_STATUS_TITLE = {
  [BILLING_STATUS.Rejected]: { tag: "Rejected", color: "red", status: 404 },
  [BILLING_STATUS.Success]: { tag: "Successful", color: "green", status: 200 },
  [BILLING_STATUS.Pending]: { tag: "Pending", color: "blue", status: 100 },
  [BILLING_STATUS.Cancelled]: { tag: "Cancelled", color: "red", status: 300 },
};

export const BILLING_TYPE = {
  Topup: "BILL001",
  Refund: "BILL002",
  Invoice: "BILL003",
};

export const OPTION_TYPE_TITLE = {
  [BILLING_TYPE.Topup]: "Top Up",
  [BILLING_TYPE.Refund]: "Refund",
  [BILLING_TYPE.Invoice]: "Invoice",
};

export const BILLING_STATUS_ALL = "ALL";
export const BILLING_TYPE_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));

export const SELECT_OPTION_TYPE = Object.keys(BILLING_TYPE).map((option) => ({
  label: BILLING_TYPE[option],
  value: BILLING_TYPE[option],
}));

export const SELECT_OPTION_STATUS = Object.keys(BILLING_STATUS).map(
  (option) => ({
    label: BILLING_STATUS[option],
    value: BILLING_STATUS[option],
  })
);
