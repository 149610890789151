// React Thirty Party Library

// Constants Library

// Components Library
import { BankInformation } from "../../../../components"

// Handler Library

// Services Library

// Redux Actions

// Styles Library


const TopUpAccountInfo = () => {
  return (
    <>
        <BankInformation
            style={{
                width: "95%",
                paddingTop: "1%"
            }}
        />
    </>
  );
};

export default TopUpAccountInfo;
