// React Thirty Party Library
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "antd";

// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../../../constants";
import { IMG } from "../../../../../../../../constants";

// Components Library
import {
  TableList,
  BtnSubmit,
  ButtonModal,
} from "../../../../../../components";

// Handler Library
import { exportHDLR } from "../../../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../../../services/util";

// Redux Actions

// Styles Library
import classes from "../../../../../../components/index.module.css";

const { TABLE_ORDERLIST_INDEX, TABLE_ORDERLIST_TITLE } = TEMPLATE.ORDERLIST;
const { ORDER_SUMMARY_COL } = TEMPLATE.REPORT.ORDER;

const { MODULE_ROUTE } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;

const { icon_export } = IMG.IMG.TRANSACTION;

const { reduxStoreWrapper } = REDUX_UTIL;
const { parseExportTime } = TIME;

const SearchOrder = ({
//   filteredData,
  setFilteredData,
  dataSource,
  selectedRowKeys
  // NavigateOrderSubmission,
}) => {
  const navigate = useNavigate();

  const orderStates = useSelector(reduxStoreWrapper("orderState.byID"));
  const orderSorted = useSelector(reduxStoreWrapper("orderState.bySorted"));
  const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
  const orders = useSelector(reduxStoreWrapper("order.byID"))
  const hubs = useSelector(reduxStoreWrapper("hub.byID"));
  const wallet = useSelector(reduxStoreWrapper("wallet"));
  const user = useSelector(reduxStoreWrapper("user"));
  const points = useSelector(reduxStoreWrapper("point.byID"));
  const uroleid = useSelector(reduxStoreWrapper("user.uroleid"));

    // selected data used for exporting selected order
    const selectedData = selectedRowKeys?.length && selectedRowKeys.map(key => ({
        key,
        ...orders[key]
    })) || Object.values(orders)

  const options = [
    {
      value: TABLE_ORDERLIST_INDEX.Order_ID,
      label: TABLE_ORDERLIST_TITLE.Order_ID,
      type: "text",
    },
    {
      value: TABLE_ORDERLIST_INDEX.Invoice,
      label: TABLE_ORDERLIST_TITLE.Invoice,
      type: "text",
    },
    {
      value: TABLE_ORDERLIST_INDEX.SO_Printed,
      label: TABLE_ORDERLIST_TITLE.SO_Printed,
      type: "timeframe",
    },
    {
      value: TABLE_ORDERLIST_INDEX.Created_At,
      label: TABLE_ORDERLIST_TITLE.Created_At,
      type: "timeframe",
    },
  ];

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_ORDERLIST_INDEX.Order_ID}
      />
      <div>
        <BtnSubmit
          display="inline-block"
          text="Export"
          bgColor="transparent"
          color="#4CAF50"
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: selectedData,
              filename: `OrderSummary_${parseExportTime()}`,
              template: ORDER_SUMMARY_COL({
                redux: {
                    orderSorted,
                    orderStates,
                    orderStatuses,
                    hubs,
                    points,
                    uroleid
                },
                exportState: true,
              }),
            })
          }
          showIcon={true}
          icon={icon_export}
          icon_alt={"export"}
        />
        {user.paymentModel === "PM01" ? (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUBMISSION]
              )
            }
          >
            Place Order
          </Button>
        ) : wallet.balance <= 30 ? (
          <ButtonModal setmodal="wallet_notif" model={"default"}>
            Place Order
          </ButtonModal>
        ) : (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUBMISSION]
              )
            }
          >
            Place Order
          </Button>
        )}
      </div>
    </div>
  );
};

export default SearchOrder;
