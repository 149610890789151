import { Tag } from "antd";
// Contanst
import { TEMPLATE } from "../../constants";

const { BILLING_STATUS, BILLING_TYPE, OPTION_STATUS_TITLE, OPTION_TYPE_TITLE } =
  TEMPLATE.BILLING;

export const billingStatus = () => {
  let tree = [];

  if (BILLING_STATUS) {
    const allChildren = Object.values(BILLING_STATUS).map((status) => {
      const { tag, color } = OPTION_STATUS_TITLE[status];

      const title = (
        <Tag color={color} key={status}>
          {tag}
        </Tag>
      );
      const key = `status#${status}`;
      const value = `status#${status}`;

      // console.log(value);

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const billingType = () => {
  let tree = [];

  if (BILLING_TYPE) {
    const allChildren = Object.values(BILLING_TYPE).map((type) => {
      const title = OPTION_TYPE_TITLE[type];
      const key = `type#${type}`;
      const value = `type#${type}`;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};
