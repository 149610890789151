// Third-party Library 
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Tabs, Form } from "antd";

// Constants 
import { ROOT_CONSTANT, TEMPLATE } from "../../../../../constants";

// Components
import { 
    ProfileUser,
    ProfileCompany,
    ProfileInfo
} from "./components";

// Services
import { REDUX as REDUX_UTIL, TIME } from "../../../../../services/util";

// Actions 
import * as REDUX_ACTION from "../../../../../services/redux/actions";

// Styles
import styles from "./common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const { parseYear } = TIME;

const { STATE } = TEMPLATE.ORDER
const { icon_edit } = ROOT_CONSTANT.IMG.ICON.MY_ACCOUNT;

const Profiles = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [ messageApi, contextHolder ] = message.useMessage()

    const user = useSelector(reduxStoreWrapper("user"));
    const userRoles = useSelector(reduxStoreWrapper("userRoles.byID"));
    const orderCompleted = useSelector(reduxStoreWrapper("order.completed"));
    
    const { createdAt, uid, company, uroleid = 'C' } = user;

    const userRole = userRoles
        ? userRoles?.[uroleid]?.name
        : "Customer";

    const [ isEdited, setIsEdited ] = useState(false);


    useEffect(() => {
        dispatch(
            REDUX_ACTION.v1_order.get_order_status_completed_request(user.uid)
        );

        return () => {};
    }, [dispatch]);


    const handleSubmit = async () => {
        const formValue = form.getFieldsValue()
        delete formValue.userRole

        const forms = { ...user, ...formValue, uroleid }

        messageApi.open({
            type: "loading",
            content: `Updating user profile...`,
            duration: 0,
        });

        await form
            .validateFields()
            .then(() => {
                dispatch(
                    REDUX_ACTION.v1_user.user_update_data_request(uid, forms, () => messageApi.destroy())
                );
                setIsEdited(false);
            })
            .catch((err) => {
                message.error(
                    "Validation failed. Please ensure all fields are filled correctly."
                );
            });
    };

    const optionsState = Object.keys(STATE)
        .map(state => ({
            label: STATE[state].name,
            value: STATE[state].code
        }))

    const profileAttr = {
        userRole,
        form,
        isEdited,
        optionsState
    }

    const items = [
        {
            key: '1',
            label: 'My Profile',
            children: <ProfileUser {...profileAttr} user={user} />
        },
        company && {
            key: '2',
            label: 'Company Profile',
            children: <ProfileCompany {...profileAttr} user={company} />
        }
    ]
  
    const action_edit = 
        isEdited ?
            <div className={styles["right"]}>
                <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className={styles["reset-button"]}
                    style={{ color: "#FF5F1F" }}
                >
                    Save
                </button>
                <button
                    className={styles["reset-button"]}
                    onClick={() => setIsEdited(false)}
                >
                    Cancel
                </button>
            </div>
        : 
            <button
                type="button"
                className={styles["reset-button"]}
                onClick={() => setIsEdited(true)}
            >
                <img src={icon_edit} alt="" />
            </button>

    return (
        <div className={styles.container}>
            {contextHolder}
            <ProfileInfo
                year={parseYear(createdAt)}
                order_completed={orderCompleted}
                memPTS={0}
            />
            <h1 style={{ fontSize: "22px", margin: '1em 0 .3em 0' }}>Basic Information</h1>
                <Tabs
                    tabBarExtraContent={action_edit}
                    defaultActiveKey="1"
                    items={items}
                    style={{ fontSize: "16px"}}
                />
        </div>
    );
};

export default Profiles;
