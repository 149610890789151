// React Thirty Party Library
import { Tag } from "antd";

export const getColumnsFilterStatus = (options) => {
  return Object.values(options).map((o) => ({
    text: <Tag color={o.colorTag}>{o.display || o.name}</Tag>,
    value: o.status,
  }));
};

export const getColumnsFilterHub = (options) => {
  return Object.keys(options).map((o) => ({
    text: options[o] && options[o].code,
    value: options[o] && options[o].hubid,
  }));
};

export const getColumnsFilterType = (options, title) => {
  return Object.values(options).map((o) => ({
    text: title[o],
    value: o,
  }));
};
