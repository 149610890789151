import { Modal } from "antd";

// Componetns
// eslint-disable-next-line import/namespace
import { ROOT_CONSTANT } from "../../../../constants";
import { ButtonLink } from '../../../../components'

// Style
import styles from "../common.module.css";

const { icon_person_del } = ROOT_CONSTANT.IMG.ICON.MODAL;

const DeleteSenderReceiver = (props) => {
  const { isModalOpen, setIsModalOpen, personname, personrole, onClick } =
    props;

    const handleOkButton = () => {
      onClick()
      setIsModalOpen()
    }

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={setIsModalOpen}
      width={400}
    >
      <div className={styles["content-container"]}>
        <img src={icon_person_del} alt="" />

        <div className={styles["contents"]}>
          <h2>Delete {personrole.replace(/^./, (p) => p.toUpperCase())}</h2>
          <div>
            <div>
              You are about to delete {personrole}{" "}
              <ButtonLink style={{ display: "inline-block" }}>
                {personname}
              </ButtonLink>
              .
            </div>
            <p>Are you sure you want to proceed?</p>
          </div>

          <div className={styles["buttons"]}>
            <button onClick={setIsModalOpen} className={styles["button"]}>
              No, Back to List
            </button>
            <button
              onClick={handleOkButton}

              className={`${styles["button"]} ${styles["active"]}`}
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteSenderReceiver;
