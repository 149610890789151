import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Form, Input, Row, Col, Select, Button, message, Checkbox, Image } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'

// Constant
import { ROOT_CONSTANT, ROUTE as ROUTE_CONSTANT } from '../../constants'

// Components
import { ButtonLink, CopyrightFooter, ProgressCircleNumber, NumericInput } from '../../components'


// Redux Action
import * as ACTIONS from '../../services/redux/actions';

// Styles
import classStyles from '../index.module.css'
import './index.css'

const {
    Option
} = Select

const { TextArea } = Input

const SSOSignUp = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [ loadingSignup, setLoadingSignup ] = useState(false)
    const [ input_number, setInput_number ] = useState('')
    const [ isChecked, setIsChecked ] = useState(false) 
    const [ isBzAcc, setIsBzAcc ] = useState(false)
    const [ company, setCompany ] = useState({
        name: "",
        address:'',
        postcode: "",
        city: "",
        state: "",
    })
    // console.log(bzInfo)
    const handleBzInfo =(info)=>{
        const {name, value} = info.target;
        setCompany({
            ...company,
            [name]: value
        })
    }
    const onFinish = (e) => {
        const {
            contact,
            cusamid,
            email,
            name,
            password,
            contactPrefix,

        } = e
        // console.log(company)
        dispatch(ACTIONS.main_user.sso_user_sign_up_request(
            {
                cusamid,
                company,
                email,
                name,
                password,
                contact: `${contactPrefix}-${contact}`
            }, 
            ({status, err}) => {
                if(status === 200) {
                    message.success("Sign Up Successfully.")
                    navigate(ROUTE_CONSTANT.MODULE_ROUTE.Signup_Pending, {state:{email}})
                    setLoadingSignup(false)
                } else if (status === 404) {
                    message.error(err)
                    setLoadingSignup(false)
                } 
            }
        ))

        setLoadingSignup(true)
    }

    const validateMessages = {
        required: '${name} is required!'
    }

    const prefixContactSelector = (
        <Form.Item name="contactPrefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="+60">+60</Option>
          </Select>
        </Form.Item>
    );

    const AccountManagerLabel = () => {
        return (
            <>
                <div><span className={classStyles.inputLabel}>Account Manager ID </span> <span className={classStyles.optionalInput}>(Optional)</span></div>
            </>
        )
    }

    return (
        <>
            <div className={classStyles.inputForm}>
                {window.innerWidth <= 1000 &&
                <div className={classStyles.progress}>
                    <ProgressCircleNumber turn={1} circles={3}/>
                </div>
                }
                 <div className={classStyles.tagline}>
                    <h4>
                        <strong>Sign Up</strong>
                        <p style={{marginTop: "20px"}}>Sign up an account to enjoy more discounts.</p>
                    </h4>
                </div>
                    <Form 
                        form={form}
                        name="form_signup" 
                        layout="vertical" 
                        validateMessages={validateMessages}
                        initialValues={{
                            'contactPrefix': '+60',
                        }}
                        onFinish={onFinish}
                    >
                        <div className={classStyles.inputWrap} style={isBzAcc? {height:'130vh'}:{height:'80vh'}}>
                            <Row style={{marginTop: "30px"}}>
                                <Col span={24}>
                                    <Form.Item 
                                        name="name" 
                                        label={<span className={classStyles.inputLabel}>Name</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Name is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={window.innerWidth >= 1000 ? 12 : 24}>
                                    <Form.Item 
                                        name="contact" 
                                        label={<span className={classStyles.inputLabel}>Contact</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Contact is required',
                                            },
                                            {
                                                min: 7
                                            }
                                        ]}
                                    >
                                        <NumericInput value={input_number} onChange={setInput_number} addonBefore={prefixContactSelector}/>
                                    </Form.Item>
                                </Col>
                                {window.innerWidth >= 1000 && 
                                    <Col span={12}>
                                        <Form.Item 
                                            name="cusamid" 
                                            label={<AccountManagerLabel />}
                                            tooltip={{ title: ROOT_CONSTANT.STATEMENT.EXPLANATION_CUSTOMER_ACCOUNT_MANAGER, icon: <QuestionCircleOutlined style={{color: "red"}}/> }}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item 
                                        name="email" 
                                        label={<span className={classStyles.inputLabel}>Email</span>}
                                        rules={[
                                            {
                                                required: true,
                                                type: "email",
                                                message: 'Email is required',
                                            }
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={window.innerWidth >= 1000 ? 12 : 24}>
                                    <Form.Item 
                                        name="password" 
                                        label={<span className={classStyles.inputLabel}>Password</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Password is required with at least 8 alphanumeric',
                                                min: 8
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password className={classStyles.inputPassword}/>
                                    </Form.Item>
                                </Col>
                                <Col span={window.innerWidth >= 1000 ? 12 : 24}>
                                    <Form.Item
                                        name="confirmPassword"
                                        label={<span className={classStyles.inputLabel}>Confirm Password</span>}
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Confirm password is required',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Confirm password doesnt match password!'));
                                                },
                                            })
                                        ]}
                                    >
                                        <Input.Password className={classStyles.inputPassword}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            
                            <Form.Item>
                                <Checkbox checked={isBzAcc} name="bzAccCheck" 
                                onChange={() => setIsBzAcc(!isBzAcc)}
                                    >
                                    Sign up as business account
                                </Checkbox>
                            </Form.Item>

                            {isBzAcc ? <> <Row>
                                <Col span={24}>
                                    <Form.Item 
                                        // name="compName" 
                                        label={<span className={classStyles.inputLabel}><span style={{color:'red'}}>* </span>Company Name</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Company Name is required',
                                            },
                                        ]}
                                    >
                                        <Input name="name" value={company.name} onChange={handleBzInfo} required/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item 
                                        label={<span className={classStyles.inputLabel}>Company Address</span>}
                                    >
                                        <TextArea name="address" value={company.address} rows={4} onChange={handleBzInfo}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={window.innerWidth >= 1000 ? 8 : 24}>
                                    <Form.Item  
                                        label={<span className={classStyles.inputLabel}>Postcode</span>}
                                        hasFeedback
                                    >
                                        <Input name="postcode" value={company.postcode} onChange={handleBzInfo} />
                                    </Form.Item>
                                </Col>
                                <Col span={window.innerWidth >= 1000 ? 8 : 24}>
                                    <Form.Item
                                        label={<span className={classStyles.inputLabel}>City</span>}
                                    >
                                        <Input name="city" value={company.city} onChange={handleBzInfo}/>
                                    </Form.Item>
                                </Col>
                                <Col span={window.innerWidth >= 1000 ? 8 : 24}>
                                    <Form.Item
                                        label={<span className={classStyles.inputLabel}>State</span>}
                                    >
                                        <Select
                                            name="state"
                                            value={company.state}
                                            onChange={(value) => {
                                                setCompany((prev) => ({ ...prev, state: value }));
                                            }}
                                            placeholder="-- SELECT --"
                                            options={[
                                                { value: "Johor", label: "Johor" },
                                                { value: "Kedah", label: "Kedah" },
                                                { value: "Melaka", label: "Melaka" },
                                                { value: "Negeri Sembilan", label: "Negeri Sembilan" },
                                                { value: "Pahang", label: "Pahang" },
                                                { value: "Penang", label: "Penang" },
                                                { value: "Perak", label: "Perak" },
                                                { value: "Perlis", label: "Perlis" },
                                                { value: "Sabah", label: "Sabah" },
                                                { value: "Sarawak", label: "Sarawak" },
                                                { value: "Selangor", label: "Selangor" },
                                                { value: "Trengganu", label: "Trengganu" },
                                                { value: "WP Labuan", label: "WP Labuan" },
                                                { value: "WP Putrajaya", label: "WP Putrajaya" },
                                                { value: "WP Kuala Lumpur", label: "WP Kuala Lumpur" },
                                            ]}
                                            />
                                    </Form.Item>
                                </Col>
                            </Row></> : null}

                            <Form.Item>
                                <Checkbox checked={isChecked} name="signupAgreement" onChange={() => setIsChecked(!isChecked)}>
                                    By clicking “sign up”,  you confirm that you have read and accept the <span className={classStyles.link}>Terms of Services</span> and{" "}
                                    <span className={classStyles.link}>Privacy Policy.</span>
                                </Checkbox>
                            </Form.Item>
                        </div>

                            <Form.Item>
                                <Button className={classStyles.inputButton} type="primary" htmlType="submit" loading={loadingSignup}>
                                    Sign Up
                                </Button>
                            </Form.Item>
                        </Form>

                    <div className={classStyles.footer}>
                        Already have account?{" "}
                        <ButtonLink
                            className={classStyles["link"]}
                            onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Login)}
                        >
                            Log in
                        </ButtonLink><br /><br />
                        <CopyrightFooter /> <br /><br />
                    </div>
            </div>
        </>
    )
}

export default SSOSignUp