import React from "react";
import {
  CodeSandboxOutlined,
  HomeOutlined,
  LogoutOutlined,
  SnippetsOutlined,
  SwapOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";

// Constants
import { FEATURE_NAME, FEATURE_TYPE } from "../common/features";

// Services
export const FEATURES = FEATURE_NAME;

export const FEATURE_LABEL = {
  // Order
  [FEATURE_NAME.ORDER_LIST]: "Order List",
  // Finance
  [FEATURE_NAME.BILLING_LIST]: "Billing List",
  [FEATURE_NAME.TRANSACTION_LIST]: "Transaction List",
  [FEATURE_NAME.TOPUP_DASHBOARD]: "Top Up",
  // App Info
  [FEATURE_NAME.DASHBOARD]: "Dashboard",
  [FEATURE_NAME.ACCOUNT_PROFILE]: "My Account",
  [FEATURE_NAME.LOGOUT]: "Log out",
};

const MENU_ICON_SIZE = 24;

const MENU_COMPONENT_ITEM = (menu) => ({
  key: menu.feature,
  icon: <menu.icon style={{ fontSize: MENU_ICON_SIZE }} />,
  label: FEATURE_LABEL[menu.feature],
  feature: menu.feature,
  type: menu.type,
  disabled: menu.disabled,
  function: menu.function,
});

export const FEATURE_MENU_ITEMS_OBJ = {
  [FEATURE_NAME.DASHBOARD]: {
    feature: FEATURE_NAME.DASHBOARD,
    icon: HomeOutlined,
    type: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ORDER_LIST]: {
    feature: FEATURE_NAME.ORDER_LIST,
    icon: CodeSandboxOutlined,
    type: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.TOPUP_DASHBOARD]: {
    feature: FEATURE_NAME.TOPUP_DASHBOARD,
    icon: WalletOutlined,
    type: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.TRANSACTION_LIST]: {
    feature: FEATURE_NAME.TRANSACTION_LIST,
    icon: SwapOutlined,
    type: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.BILLING_LIST]: {
    feature: FEATURE_NAME.BILLING_LIST,
    icon: SnippetsOutlined,
    type: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ACCOUNT_PROFILE]: {
    feature: FEATURE_NAME.ACCOUNT_PROFILE,
    icon: UserOutlined,
    type: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.LOGOUT]: {
    feature: FEATURE_NAME.LOGOUT,
    icon: LogoutOutlined,
    type: FEATURE_TYPE.LOGOUT,
    disabled: false,
  },
};

export const FEATURE_MENU_ITEMS = Object.values(FEATURE_MENU_ITEMS_OBJ);
export const FEATURE_MENU_ITEMS_LAYER_FIRST = [
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.DASHBOARD],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ORDER_LIST],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.TOPUP_DASHBOARD],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.TRANSACTION_LIST],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.BILLING_LIST],
];
export const FEATURE_MENU_ITEMS_LAYER_SECOND = [
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ACCOUNT_PROFILE],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.LOGOUT],
];

export const FEATURE_MENU_ITEMS_COMP = FEATURE_MENU_ITEMS.map((menu) =>
  MENU_COMPONENT_ITEM(menu)
);
export const FEATURE_MENU_ITEMS_COMP_LAYER_FIRST =
  FEATURE_MENU_ITEMS_LAYER_FIRST.map((menu) => MENU_COMPONENT_ITEM(menu));
export const FEATURE_MENU_ITEMS_COMP_LAYER_SECOND =
  FEATURE_MENU_ITEMS_LAYER_SECOND.map((menu) => MENU_COMPONENT_ITEM(menu));
