// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE } from "../../../../constants";
import { IMG } from "../../../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../components";

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions

// Styles Library
// Styles Library
import classes from "../../../../components/index.module.css";

const { TABLE_BILLING_INDEX, TABLE_BILLING_TITLE } = TEMPLATE.BILLING;
const { icon_export } = IMG.IMG.TRANSACTION;
const { BILLING_SUMMARY_COL } = TEMPLATE.REPORT.BILLING;

const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;

const SearchBilling = ({ setFilteredData, dataSource, filteredData, selectedRowKeys }) => {
    const billings = useSelector(reduxStoreWrapper("billing.byID"))

    
    // selected data used for exporting selected data
    const selectedData = selectedRowKeys?.length && selectedRowKeys.map(key => ({
        key,
        ...billings[key]
    })) || Object.values(billings)

  const template = BILLING_SUMMARY_COL({
    exportState: true,
  });

  const options = [
    {
      value: TABLE_BILLING_INDEX.Billing_ID,
      label: TABLE_BILLING_TITLE.Billing_ID,
      type: "text",
    },
    {
      value: TABLE_BILLING_INDEX.Transaction_Id,
      label: TABLE_BILLING_TITLE.Transaction_Id,
      type: "text",
    },
    {
      value: TABLE_BILLING_INDEX.Billing_Date,
      label: TABLE_BILLING_TITLE.Billing_Date,
      type: "timeframe",
    },
  ];

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_BILLING_INDEX.Billing_ID}
      />
      <div>
        <BtnSubmit
          display="inline-block"
          text="Export"
          bgColor="transparent"
          color="#4CAF50"
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: selectedData,
              filename: `BillingSummary_${parseExportTime()}`,
              template: template,
            })
          }
          showIcon={true}
          icon={icon_export}
          icon_alt={"export"}
        />
      </div>
    </div>
  );
};

export default SearchBilling;
