import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Constant
import { ROOT_CONSTANT } from "../../../../constants";

// Components
import { OrderSummary } from "../../components";
import { ButtonLink } from "../../../../components";

import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Service

//Redux Actions

// Styles
import cs from "../../components/OrderSummary/index.module.css";
import styles from "../../../../components/index.module.css";

const { ICON_ARROW } = ROOT_CONSTANT.IMG.ICON;

const { reduxStoreWrapper } = REDUX_UTIL;

const OrderSummaryPage = (props) => {
  const { ordid = "" } = props;

  const orders = useSelector(reduxStoreWrapper(`order.byID.${ordid}`));

  const navigate = useNavigate();
  function handleButtonBack() {
    navigate(-1);
  }

  return (
    <div className={cs.container}>
      <div className={styles.headerPage} style={{width: 50}}>
        <ButtonLink
          onClick={handleButtonBack}
        >
          <img
            src={ICON_ARROW}
            className={styles.filterIcon}
            alt=""
          />
        </ButtonLink>
        <h4>  {ordid} </h4>
      </div>
      <div style={{ marginTop: '7em' }}>
        <OrderSummary srcData={orders} type="out"/>
      </div>
    </div>
  );
};

export default OrderSummaryPage;
