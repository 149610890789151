import * as ActionTypes from "../action-types"

const point_defaultState = {
    byID: {},
    allIDs: []
}

export const point = (state = point_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState
    
    switch(action.type) {
        case ActionTypes.POINT_GET_ACTIVE: {

            const points = action.points

            points
            &&
            points.length
            &&
            points.map(point => {

                const { hubid, code } = point

                newState.byID[hubid] = point
                // newState.byCode[code] = point

                if(!newState.allIDs.includes(hubid)) newState.allIDs.push(hubid)

                return null
            })

            return newState;
        }
        default: {
            return state;
        }
    }
}