import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation, useNavigate } from "react-router";
import { Row, Col, Image } from "antd";

// Constants
import { ROUTE, ROOT_CONSTANT } from "../../../constants";

/**
 * Components
 */
import { asyncComponent, HeaderBar } from "../../../components";

/**
 * Pages
 */
import SSOPanel from "../../../pages/SSOPanel";
import SSOLoading from "../../../pages/SSOLoading";
import SSOLogin from "../../../pages/SSOLogin";
import SSOSignUp from "../../../pages/SSOSignUp";
import SSOSignUpSucess from "../../../pages/SSOSignUpSuccess";
import SSOSignUpPending from "../../../pages/SSOSignUpPending";
import {
  SSOForgotPassword,
  SSOForgotPasswordPending,
  SSOResetPassword,
  SSOResetPasswordSuccess,
} from "../../../pages/SSOResetCredential";
import SSOLinkVerify from "../../../pages/SSOLinkVerify";
import SSOLinKUnvalidated from "../../../pages/SSOLinKUnvalidated";

// Styles
import classStyles from "./index.module.css";

const AsyncNotFoundPage = asyncComponent(() =>
  import("../../../pages/NotFoundPage")
);

function AuthSelector(props) {
    // const navigate = useNavigate();
  
    const { pathname } = props;
  
    const auth = useSelector((state) => state.root.auth);
  
    const _authSelector = () => {
      if (auth.loadingState) {
        return (
          <SSOContainer pathname={pathname}>
            <SSOLoading />
          </SSOContainer>
        );
      } else {
        if (auth.currentUser) {
            return <SSOPanel currentUser={auth.currentUser} />
        } else {
          return (
            <SSOContainer pathname={pathname}>
              <SSOLogin />
            </SSOContainer>
          );
        }
      }
    };
  
    return <>{_authSelector()}</>;
  }

const SSOContainer = ({ pathname, children }) => {
  const RouteContextFullWidth = 24;
  const RouteContextWidth = () => {
    let width = 12;

    switch (pathname) {
      case ROUTE.MODULE_ROUTE.Signup:
      case ROUTE.MODULE_ROUTE.Signup_Success: {
        width = 14;
        break;
      }
      case ROUTE.MODULE_ROUTE.Landing_Page:
      case ROUTE.MODULE_ROUTE.Login:
      default: {
        width = 12;
        break;
      }
    }

    return width;
  };

  return (
    <>
      <div className={classStyles.container}>
        {window.innerWidth <= 1000 ? (
          <>
            <HeaderBar />
            {children}
          </>
        ) : (
          <>
            <HeaderBar />
            <Row className={classStyles.contextLayout}>
              <Col span={RouteContextFullWidth - RouteContextWidth()}>
                <div className={classStyles["signup-bg"]} alt="" />
              </Col>
              <Col span={RouteContextWidth()}>{children}</Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

const SSORoutes = () => {
  const location = useLocation();

  const { pathname = "", state = {} } = location;

  const email = state && state.email;
  const verify = state && state.verify;
  const success = state && state.success;
  const invalid = state && state.invalid;
  const authid = state && state.authid;

  return (
    <>
      <Routes>
        <Route
          exact
          path={ROUTE.MODULE_ROUTE.Login}
          element={<AuthSelector pathname={pathname} />}
        />

        <Route
          index
          path={ROUTE.MODULE_ROUTE.Landing_Page}
          element={<AuthSelector />}
        />

        <Route
          index
          path={ROUTE.MODULE_ROUTE.Signup}
          element={
            <SSOContainer pathname={pathname}>
              <SSOSignUp />
            </SSOContainer>
          }
        />

        {email && (
          <Route
            index
            path={ROUTE.MODULE_ROUTE.Signup_Pending}
            element={
              <SSOContainer pathname={pathname}>
                <SSOSignUpPending email={email} />
              </SSOContainer>
            }
          />
        )}

        {verify && (
          <Route
            index
            path={ROUTE.MODULE_ROUTE.Signup_Success}
            element={
              <SSOContainer pathname={pathname}>
                <SSOSignUpSucess />
              </SSOContainer>
            }
          />
        )}

        <Route
          index
          path={ROUTE.MODULE_ROUTE.ResetCredential_Forgot_Password}
          element={
            <SSOContainer pathname={pathname}>
              <SSOForgotPassword />
            </SSOContainer>
          }
        />

        {email && (
          <Route
            index
            path={ROUTE.MODULE_ROUTE.ResetCredential_Forgot_Password_Pending}
            element={
              <SSOContainer pathname={pathname}>
                <SSOForgotPasswordPending email={email} />
              </SSOContainer>
            }
          />
        )}

        {verify && authid && (
          <Route
            index
            path={ROUTE.MODULE_ROUTE.ResetCredential_Reset_Password}
            element={
              <SSOContainer pathname={pathname}>
                <SSOResetPassword authid={authid} />
              </SSOContainer>
            }
          />
        )}

        {success && (
          <Route
            index
            path={ROUTE.MODULE_ROUTE.ResetCredential_Reset_Password_Success}
            element={
              <SSOContainer pathname={pathname}>
                <SSOResetPasswordSuccess />
              </SSOContainer>
            }
          />
        )}

        <Route
          index
          path={ROUTE.MODULE_ROUTE.Link_Verify}
          element={
            <SSOContainer pathname={pathname}>
              <SSOLinkVerify />
            </SSOContainer>
          }
        />

        {invalid && (
          <Route
            index
            path={ROUTE.MODULE_ROUTE.Link_Unvalidated}
            element={
              <SSOContainer pathname={pathname}>
                <SSOLinKUnvalidated />
              </SSOContainer>
            }
          />
        )}

        <Route
          element={
            <SSOContainer pathname={pathname}>
              <AsyncNotFoundPage />
            </SSOContainer>
          }
        />
      </Routes>
    </>
  );
};

export default SSORoutes;
