import * as ActionTypes from "../action-types";

const billing_defaultState = {
  byID: {},
  allIDs: [],
};

export const billing = (state = billing_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_BILLING_LIST: {
      const billing = action.billing;

      billing &&
        billing.length &&
        billing.map((billing) => {
          const billid = billing.billid;

          newState.byID[billid] = billing;

          if (!newState.allIDs.includes(billid)) newState.allIDs.push(billid);

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
