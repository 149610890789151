import { combineReducers } from "redux";

// V1 Reducer
import { addressBook } from "./addressBook";
import { billing } from "./billing";
import { hub } from "./hub";
import { zone } from "./zone";
import { point } from "./point";
import { order } from "./order";
import { orderLog } from "./orderLog";
import { orderState } from "./orderState";
import { orderStatus } from "./orderStatus";
import { style } from "./style";
import { transaction } from "./transaction";
import { user } from "./user";
import { userRoles } from "./userRoles";
import { wallet } from "./wallet";
import { journalNote } from "./journalNote";
import { vehicle } from "./vehicle";

export const v1Reducer = combineReducers({
  addressBook,
  billing,
  hub,
  zone,
  point,
  order,
  orderLog,
  orderState,
  orderStatus,
  style,
  transaction,
  user,
  userRoles,
  wallet,
  journalNote,
  vehicle,
});
