// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { message } from "antd";

// Constants Library
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import { SearchOrderlist, TableOrderlist } from "./component";

// Handler Library
import { reactHDLR } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles Library
import styles from "./index.module.css";

const { MODULE_ROUTE } = ROUTES;

const { reduxStoreWrapper } = REDUX_UTIL;

const OrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orders = useSelector(reduxStoreWrapper("order.byID"));
  const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
  const uid = useSelector(reduxStoreWrapper("user.uid"));
  const billings = Object.values(
    useSelector(reduxStoreWrapper("billing.byID"))
  );
  const vehicles = useSelector(reduxStoreWrapper("vehicle.byID"));
  const loading = useSelector(reduxStoreWrapper("style.loading.order_list"));

  const [messageApi, contextHolder] = message.useMessage();

  const dataSource = Object.values(orders).map((o) => {
    const matchedBill = billings.find((b) => o.ordid === b.ordid);
    const refid = matchedBill ? matchedBill.refid : "";
    const createdInv = matchedBill ? matchedBill.createdAt : "";

    const vhc = vehicles[o.vid];

    return {
      ...o,
      key: o.ordid,
      type: orderStatuses[o.status] && orderStatuses[o.status].type,
      refid: refid,
      createdNote: createdInv,
      isTracking: o.v_plate ? vhc && vhc.isTracking : 0,
      statusBill: matchedBill?.status
    };
  });

  const [filteredData, setFilteredData] = useState(dataSource);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (uid) {
      dispatch(
        REDUX_ACTION.v1_order.get_order_timeframe_thru_uid_last_90_days_request(
          uid
        )
      );
      dispatch(
        REDUX_ACTION.v1_billing.get_billing_timeframe_thru_uid_last_90_days_request(
          uid
        )
      );
      dispatch(REDUX_ACTION.v1_point.get_all_points_request());
      dispatch(REDUX_ACTION.v1_vehicle.get_all_vehicle_request());
    }

    // return () => {};
  }, [dispatch, uid]);

  useEffect(() => {
    if (filteredData !== dataSource) {
      setFilteredData(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData]);

  const SORenavigate = (soid) => {
    messageApi.open({
      type: "loading",
      content: "Getting shipment order...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_shipmentOrder.get_shipment_order_thru_soid_request(
        soid,
        (res) => {
          const { status, url } = res;

          if (status === 200) {
            reactHDLR.openInNewTab(url);
          }

          messageApi.destroy();
        }
      )
    );
  };

  const PrintSO = (ordid) => {
    messageApi.open({
      type: "loading",
      content: "Getting shipment order...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_shipmentOrder.get_shipment_order_thru_ordid_request(
        ordid,
        (res) => {
          const { status, url } = res;

          if (status === 200) {
            reactHDLR.openInNewTab(url);
          }

          messageApi.destroy();
        }
      )
    );
  };

  const NavigateOrderSummary = (ordid) => {
    navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY], {
      state: { ordid },
    });
  };

  const NavigateOrderTimeline = (ordid, status, record = {}) => {
    navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_TIMELINE], {
      state: { ordid, status, record },
    });
  };

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <SearchOrderlist
          //   filteredData={filteredData}
          setFilteredData={setFilteredData}
          dataSource={dataSource}
          selectedRowKeys={selectedRowKeys}
          // NavigateOrderSubmission={NavigateOrderSubmission}
        />
        <TableOrderlist
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          dataSource={dataSource}
          loading={loading}
          SORenavigate={SORenavigate}
          PrintSO={PrintSO}
          NavigateOrderSummary={NavigateOrderSummary}
          NavigateOrderTimeline={NavigateOrderTimeline}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>
    </>
  );
};

export default OrderList;
