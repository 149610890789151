import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Form, Input, Button, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

// Constant
import { ROUTE as ROUTE_CONSTANT } from "../../../../constants";
import { APP_CONFIG } from "../../../../config";

// Components
import { CopyrightFooter, ProgressCircleNumber } from "../../../../components";

/**
 * Redux Actions
 */
import * as ACTIONS from "../../../../services/redux/actions";

// Style
import classStyles from "./index.module.css";
import "./index.css";

const SSOResetPassword = (props) => {
  const { authid } = props;

  const authProvider = APP_CONFIG.AUTHENTICATION_PROVIDER;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loadingReset, setLoadingReset] = useState(false);

  const onFinish = (e) => {
    const { password } = e;

    dispatch(
      ACTIONS.main_user.sso_user_auth_reset_user_password_request(
        { authid, authProvider },
        password,
        ({ status, err }) => {
          if (status === 200) {
            navigate(
              ROUTE_CONSTANT.MODULE_ROUTE
                .ResetCredential_Reset_Password_Success,
              { state: { success: true } }
            );
            setLoadingReset(false);
          } else {
            message.error(err);
            setLoadingReset(false);
          }
        }
      )
    );

    setLoadingReset(true);
  };

  const validateMessages = {
    required: "${name} is required!",
  };

  return (
    <div className={classStyles.inputForm}>
      <div className={classStyles.inputContainer}>
        <h4>
          <strong>Reset Password</strong>
        </h4>
        <p className="mt-1">Must be at least 8 characters.</p>

        <div className={classStyles.inputWrap}>
          <div className={classStyles.input}>
            <Form
              form={form}
              name="form_login"
              layout="vertical"
              validateMessages={validateMessages}
              onFinish={onFinish}
            >
              <Form.Item
                name="password"
                label={
                  <span className={classStyles.inputLabel}>New Password</span>
                }
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                    min: 8,
                  },
                ]}
                hasFeedback
              >
                <Input.Password className={classStyles.inputPassword} />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label={
                  <span className={classStyles.inputLabel}>
                    Confirm Password
                  </span>
                }
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Confirm password is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Confirm password doesnt match password!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password className={classStyles.inputPassword} />
              </Form.Item>
              <div className={classStyles.block}></div>
              <Form.Item>
                <Button
                  className={classStyles.inputButton}
                  type="primary"
                  htmlType="submit"
                  loading={loadingReset}
                >
                  Continue
                </Button>
              </Form.Item>
              <div className={classStyles.backtoLOGIN}>
                <button
                  className={classStyles.transparentBTN}
                  onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Login)}
                >
                  <ArrowLeftOutlined /> Back to Login
                </button>
              </div>
            </Form>
          </div>

          <div className={classStyles.progressRow}>
            <ProgressCircleNumber turn={3} circle={4} />
          </div>

          <CopyrightFooter />
        </div>
      </div>
    </div>
  );
};

export default SSOResetPassword;
