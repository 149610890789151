import React from 'react'

const GoogleMapIFrame = (props) => {

    const {
        title,
        width,
        height,
        APIKey,
        coordinate,
        zoom,
        maptype
    } = props


    const mapZoom = zoom || 18
    const mapType = maptype || 'roadmap'

    return (
        <>
        <iframe
          title={title}
          width={ width || "251"}
          height={height || "252.99"}
          style={{border: 0}}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/view?key=${APIKey}&center=${coordinate.lat},${coordinate.lon}&zoom=${mapZoom}&maptype=${mapType}`}>
            </iframe>
        </>
    )
}

export default GoogleMapIFrame