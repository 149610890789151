import { Avatar } from 'antd'

// Constant
import { ROOT_CONSTANT } from '../../constants'

// Styles
import cs from './index.module.css'

const AvatarIcon = () => {
    return (
        <>
            <Avatar className={cs.avatar} size={40} src={ROOT_CONSTANT.THEME.LOGO}></Avatar>
        </>
    )
}

export default AvatarIcon