import { useNavigate } from 'react-router'
import { Button } from 'antd'
import { MailOutlined } from '@ant-design/icons'

// Constant
import { ROUTE as ROUTE_CONSTANT } from '../../constants'

// Components
import { CopyrightFooter, ProgressCircleNumber } from '../../components'

// Style
import classStyles from '../index.module.css'

const SSOSignUpPending = (props) => {

    const navigate = useNavigate();

    const {
        email = "example@ipick.com.my"
    } = props

    return (
        <>
            <div className={classStyles.inputForm}>
                {window.innerWidth <= 1000 &&
                <div className={classStyles.progress}>
                    <ProgressCircleNumber turn={2} circles={3}/>
                </div>
                }
                <div className={classStyles.tagline}>
                    <h4>
                        <strong>Verify Your Email</strong>
                        <MailOutlined className={classStyles.checkedIcon}/>
                        <p style={{marginTop: "20px", lineHeight: "25px", fontWeight: "400"}}>You’re almost there! We’ve sent an verification email to <span className={classStyles.email}>{email}</span>. Go to your email and click on the link to complete the sign up.</p>
                    </h4>
                </div>
                <div style={{height: "45vh"}} />
                {window.innerWidth >= 1000 && 
                    <Button 
                        className={classStyles.inputButton} type="primary"
                        onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Landing_Page)}
                    >
                        Back
                    </Button>
                }
                <div className={classStyles.footer}>
                    <CopyrightFooter />
                </div>
            </div>
        </>
    )
}

export default SSOSignUpPending