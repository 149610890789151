// React Thirty Party Library
import { useNavigate } from "react-router";
import { Tag, Typography } from "antd";

// Constant
import { TEMPLATE, COMMON_FEATURES, ROUTES } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";

//handlers
import { filter } from "../../../handlers";

// Service
import { TIME } from "../../../services/util";

const {
  TABLE_TRANSACTION_INDEX,
  TABLE_TRANSACTION_TITLE,
  OPTION_STATUS_TITLE,
  OPTION_TYPE_TITLE,
  OPTION_TYPE,
} = TEMPLATE.TRANSACTION;

const { MODULE_ROUTE } = ROUTES;

const { parseTimeMin } = TIME;
const { searchHDLR, filterHDLR } = filter;

export const TRANSACTION_SUMMARY_COL = ({
  redux = {},
  func = {},
  exportState = false,
}) => {
  const navigate = useNavigate();
  const { billing } = redux;
  const { NavigateOrderSummary, handleSearch } = func;

  const { Text } = Typography;

  return [
    {
      title: TABLE_TRANSACTION_TITLE.Transaction_Id,
      key: TABLE_TRANSACTION_INDEX.Transaction_Id,
      render: (record) => {
        const { txnid } = record;
        return exportState ? txnid : <ButtonLink>{txnid} </ButtonLink>;
      },
      ...searchHDLR.getColumnSearchProps("txnid", handleSearch),
    },
    {
      title: TABLE_TRANSACTION_TITLE.Transacted_At,
      key: TABLE_TRANSACTION_INDEX.Transacted_At,
      render: (record) => {
        const { createdAt } = record;
        return exportState ? (
          parseTimeMin(createdAt)
        ) : (
          <span>{parseTimeMin(createdAt)} </span>
        );
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Transaction_Status,
      key: TABLE_TRANSACTION_INDEX.Transaction_Status,
      render: (record) => {
        const { status } = record;

        const name =
          OPTION_STATUS_TITLE[status] && OPTION_STATUS_TITLE[status].tag;
        const colorTag =
          OPTION_STATUS_TITLE[status] && OPTION_STATUS_TITLE[status].color;
        return exportState ? (
          name
        ) : (
          <>
            <Tag color={colorTag} key={status}>
              {name}
            </Tag>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterStatus(OPTION_STATUS_TITLE),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Transaction_Type,
      key: TABLE_TRANSACTION_INDEX.Transaction_Type,
      render: (record) => {
        const { type } = record;
        const typeTxn = OPTION_TYPE_TITLE[type];
        return exportState ? typeTxn : <span>{typeTxn} </span>;
      },
      filters: filterHDLR.getColumnsFilterType(OPTION_TYPE, OPTION_TYPE_TITLE),
      onFilter: (value, record) => record.type === value,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Before_Transaction,
      key: TABLE_TRANSACTION_INDEX.Before_Transaction,
      render: (record) => {
        const { currency, amount_before } = record;
        return exportState ? (
          `${currency} ${amount_before}`
        ) : (
          <span>
            RM
            {amount_before}
          </span>
        );
      },
    },
    {
      title: TABLE_TRANSACTION_TITLE.Amount,
      key: TABLE_TRANSACTION_INDEX.Amount,
      render: (record) => {
        const { currency, action, amount } = record;
        return exportState ? (
          `${action} ${currency} ${amount}`
        ) : (
          <span>
            {action}
            RM
            {amount}
          </span>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Wallet_Balance,
      key: TABLE_TRANSACTION_INDEX.Wallet_Balance,
      render: (record) => {
        const { action, amount, amount_before, currency } = record;

        const total =
          action === "+"
            ? parseFloat(amount_before) + parseFloat(amount)
            : parseFloat(amount_before) - parseFloat(amount);

        return exportState ? (
          `${currency} ${total}`
        ) : (
          <span>
            {currency}
            {total}
          </span>
        );
      },
    },
    {
      title: TABLE_TRANSACTION_TITLE.Billing_ID,
      key: TABLE_TRANSACTION_INDEX.Billing_ID,
      render: (record) => {
        const { billid } = record;
        return exportState ? (
          billid
        ) : (
          <ButtonLink
            onClick={() =>
              navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.BILLING_LIST])
            }
          >
            {billid}
          </ButtonLink>
        );
      },
      ...searchHDLR.getColumnSearchProps("billid", handleSearch),
    },
    {
      title: TABLE_TRANSACTION_TITLE.orderId,
      key: TABLE_TRANSACTION_INDEX.orderId,
      render: (record) => {
        const { billid } = record;

        const bill = () => {
          const matchedBill = billing.find((b) => billid === b.billid);
          return matchedBill ? matchedBill.ordid : "";
        };
        const ordid = bill();

        const order = () => {
          if (ordid === undefined || ordid === "" || ordid === "-") {
            return <Text disabled>{"-"}</Text>;
          } else {
            return (
              <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>
                {ordid}
              </ButtonLink>
            );
          }
        };

        const orderID = order();

        return exportState ? ordid : orderID;
      },
      ...searchHDLR.getColumnSearchProps("ordid", handleSearch),
    },
    // {
    //   title: TABLE_TRANSACTION_TITLE.Attachment,
    //   key: TABLE_TRANSACTION_INDEX.Attachment,
    //   render: (record) => {
    //     // const { Attachment } = record;
    //     return <span>{record} </span>;
    //   },
    // },
    {
      title: TABLE_TRANSACTION_TITLE.Transacted_By,
      key: TABLE_TRANSACTION_INDEX.Transacted_By,
      render: (record) => {
        const { transactedBy } = record;
        return exportState ? transactedBy : <span>{transactedBy} </span>;
      },
    },
    {
      title: TABLE_TRANSACTION_TITLE.Remarks,
      key: TABLE_TRANSACTION_INDEX.Remarks,
      render: (record) => {
        const { remark } = record;
        return exportState ? remark : <span>{remark} </span>;
      },
    },
  ];
};
