// React Thirty Party Library
import { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "antd";

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { ButtonLink, TableList } from "../../../../../../components";
import ProgressTable from "../ProgressTable";

// Handler Library
import { orderHDLR } from "../../../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

import * as CONFIG from "../../../../../../config";

// Redux Actions

// Styles Library
// import classes from "../../../../../common.module.css";

const {
  TABLE_ORDERLIST_INDEX,
  TABLE_ORDERLIST_TITLE,
  SELECT_OPTION_DAYS,
  OPTION_DAYS,
} = TEMPLATE.ORDERLIST;

const { ORDER_SUMMARY_COL } = TEMPLATE.REPORT.ORDER;

const { reduxStoreWrapper } = REDUX_UTIL;

const TableOrderlist = ({
  filteredData,
  setFilteredData,
  dataSource,
  loading,
  SORenavigate,
  PrintSO,
  NavigateOrderSummary,
  NavigateOrderTimeline,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const orderStates = useSelector(reduxStoreWrapper("orderState.byID"));
  const orderSorted = useSelector(reduxStoreWrapper("orderState.bySorted"));
  const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
  const hubs = useSelector(reduxStoreWrapper("hub.byID"));
  const points = useSelector(reduxStoreWrapper("point.byID"));
  const uroleid = useSelector(reduxStoreWrapper("user.uroleid"));
  // console.log(ORDER_SUMMARY_COL)
  const [isModalShow, setIsModalShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const ActionItems = [
    {
      label: "Print Shipment Order ",
      onClick: (data) => {
        const { key } = data;
        const ordid = key.split("#")[1];
        PrintSO(ordid);
      },
    },
  ];

  const [searchText, setSearchText] = useState("");
  //   const [searchedColumn, setSearchedColumn] = useState('');

  //   const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //     confirm();
  //     setSearchText(selectedKeys[0]);
  //     setSearchedColumn(dataIndex);
  //   };

  // handler for search, for filtering data shown in table
  const handleSearch = (name, keyword) => {
    const filteredKeyword = dataSource.filter((data) =>
      data[name]?.includes(keyword)
    );

    setFilteredData(filteredKeyword);
  };

  const navigateMap = (ordid) => {
    const map = `https://${CONFIG.APP_CONFIG.PT_URL}?ordid=${ordid}`;
    window.open(map, "_blank");
  };

  const columns = [
    ...ORDER_SUMMARY_COL({
      redux: {
        orderSorted,
        orderStates,
        orderStatuses,
        hubs,
        points,
        uroleid,
      },
      func: {
        NavigateOrderSummary,
        navigateMap,
        SORenavigate,
        handleSearch,
      },
    }),
    {
      title: TABLE_ORDERLIST_TITLE.Actions,
      dataIndex: TABLE_ORDERLIST_INDEX.Actions,
      key: TABLE_ORDERLIST_INDEX.Actions,
      width: 100,
      fixed: "right",
      render: (ordid) => (
        <Dropdown
          menu={{
            items: ActionItems.map((ac, index) => ({
              key: `${index}#${ordid}`,
              ...ac,
            })),
          }}
        >
          <ButtonLink>Action</ButtonLink>
        </Dropdown>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const { ordid } = record;

    const status =
      (orderStates[ordid] && orderStates[ordid].status) || record.status;

    return (
      <ProgressTable
        record={record}
        hubs={hubs}
        points={points}
        NavigateOrderTimeline={NavigateOrderTimeline}
      />
    );
  };

  let modifiedColumns = [...columns];

  // if (uroleid === "POP") {
  //   modifiedColumns = modifiedColumns.filter(
  //     (column) =>
  //       column.key === TABLE_ORDERLIST_INDEX.origin_point &&
  //       column.key === TABLE_ORDERLIST_INDEX.destination_point &&
  //       column.key !== TABLE_ORDERLIST_INDEX.Delivery_Instruction
  //   );
  // } else {
  //   modifiedColumns = modifiedColumns.filter(
  //     (column) =>
  //       column.key !== TABLE_ORDERLIST_INDEX.origin_point &&
  //       column.key !== TABLE_ORDERLIST_INDEX.destination_point
  //   );
  // }

  const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    printedAt: (a, b) => a.printedAt - b.printedAt,
    createdAt: (a, b) => a.createdAt - b.createdAt,
    updatedAt: (a, b) => a.updatedAt - b.updatedAt,
  };

  const filtered = sortedData
    .filter((record) => {
      let include = true;

      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] && filteredInfo[key].length > 0) {
          include = include && filteredInfo[key].includes(record[key]);
        }
      });

      if (searchText) {
        if (
          (typeof record.ordid === "string" &&
            record.ordid.includes(searchText)) ||
          (typeof record.memid === "string" &&
            record.memid.includes(searchText))
        ) {
          include = true;
        }
      }

      return include;
    })
    .sort((a, b) => {
      const { columnKey } = sortedInfo;
      return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
    });

  return (
    <>
      <TableList.DataList
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) =>
            setSelectedRowKeys(newSelectedRowKeys),
        }}
        dataSource={dataSource}
        filteredData={filtered}
        setFilteredData={setFilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionStatus={orderHDLR.orderStatusesTreeStructure(
          orderStatuses,
          uroleid
        )}
        optionType={orderHDLR.orderDeliveryTypeTreeStructure()}
        dataDate={TABLE_ORDERLIST_INDEX.Created_At}
        placeholder="DATE"
        text1="ORDER STATUS"
        text2="DELIVERY INSTRUCTION"
        columnData={modifiedColumns}
        loading={loading}
        expandedRowRender={expandedRowRender}
        onChange={handleTableChange}
      />

      {isModalShow && selectedRecord && (
        <Businfo
          dataOrder={selectedRecord}
          onClose={() => setIsModalShow(false)}
        />
      )}
    </>
  );
};

export default TableOrderlist;
