import React from "react";
import { useLocation } from 'react-router'

// Constants & Config
import {
  ROOT_CONSTANT,
  ROUTES,
  COMMON_FEATURES
} from '../../../constants'
import * as CONFIG from '../../../config'

// Styles
import "./Logo.css";

const Logo = (props) => {

  const location = useLocation()

  const {
    pathname
  } = location

  const { onClick, pKey } = props

  return (
    <div 
      aria-hidden="true" 
      className="sidebar-logo-container" 
      key={pKey}
      onClick={onClick}
      style={{
        background: pathname === ROUTES.MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.DASHBOARD] ? "rgba(0, 0, 0, 0.25)" : ""
      }}
    >
      <img
        src={ROOT_CONSTANT.THEME.LOGO_TITLE_SVG}
        alt={CONFIG.APP_INFO.PUBLICATION_COMPANY}
        className="sidebar-logo"
      />
    </div>
  );
};

Logo.defaultProps = {
  onClick: () => {}
};

export default Logo;
