/* eslint-disable */
import React from "react";
import { Routes, Route, useLocation } from "react-router";

// Constant
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components
import { asyncComponent } from "../../../../components";
import { ROOT_PAGES } from "../../../../pages";
import {
  OrderSubmission,
  OrderList,
  OrderSummaryPage,
  OrderTimeline,
} from "../../../../pages/Order";
import Dashboard from "../../../../pages/Dashboard";
import TopUp from "../../../../pages/TopUp";
import MyAccount from "../../../../pages/MyAccount";
import TransactionList from "../../../../pages/Transaction";
import BillingList from "../../../../pages/BillingList";
// import OrderSummary from "../../../../../v1/pages/Order/modules/OrderSubmission/components/OrderSummary";

// Modules

const { MODULE_ROUTE } = ROUTES;

const AsyncNotFoundPage = asyncComponent(() => <ROOT_PAGES.NotFoundPage />);

const MainRoutes = () => {
  const location = useLocation();

  const { state = {} } = location;

  const ordid = state && state.ordid;
  const status = state && state.status;
  const record = state && state.record;

  return (
    <Routes>
      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.DASHBOARD]}
        element={<Dashboard />}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.LANDING_PAGE]}
        element={<></>}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_LIST]}
        element={<OrderList />}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY]}
        element={<OrderSummaryPage ordid={ordid} />}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_TIMELINE]}
        element={
          <OrderTimeline ordid={ordid} status={status} record={record} />
        }
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUBMISSION]}
        element={<OrderSubmission />}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.BILLING_LIST]}
        element={<BillingList />}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.TRANSACTION_LIST]}
        element={<TransactionList />}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.TOPUP_DASHBOARD]}
        element={<TopUp />}
      />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ACCOUNT_PROFILE]}
        element={<MyAccount />}
      />

      <Route element={<AsyncNotFoundPage />} />
    </Routes>
  );
};
export default MainRoutes;
