import * as ActionTypes from '../action-types'

const zone_defaultState = {
    // byID: {},
    allIDs: [],
    byZoneid: {}
}

export const zone = (state = zone_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_ZONE_PRICE_LIST: {

            const priceList = action.priceList
            const zoneid = action.zoneid

            newState.byZoneid[zoneid] = priceList

            return newState;
        }
        default: {
            return state;
        }
    }
}
