import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config

// Services

// Redux Action

// Neccessary Actions
// import {
//     setLoadingHub,
//     unsetLoadingHub
// } from './style'

export const get_all_points_request = () => dispatch => {

    // dispatch(setLoadingHub())

    API
        .getAllActivePoints()
        .then(info => {

            const {
                status,
                points,
                msg
            } = info

            if (status === 200) {
                dispatch(get_all_points_success(points))
            } else {
                message.error(msg)
            }
        })
        .finally(() => {
            // dispatch(unsetLoadingHub())
        })
}

const get_all_points_success = (points) => {
    return {
        type: ActionTypes.POINT_GET_ACTIVE,
        points
    };
}
