import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Row, Col, message } from "antd";

// Constant
import { TEMPLATE } from "../../../../../constants";

// Service
import { REDUX as REDUX_UTIL } from "../../../../../services/util";

/**
 * Redux Actions
 */
import * as ACTIONS from "../../../../../services/redux/actions";

import styles from "../../../common.module.css";

const { SIDEBAR_FEATURE } = TEMPLATE.ACCOUNT;
const { reduxStoreWrapper } = REDUX_UTIL;

const ChangePassword = (props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const user = useSelector(reduxStoreWrapper("user"));

  const [password, setPassword] = useState({
    pwd: "",
    confirmPwd: "",
    currentPwd: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onFinish = () => {
    const { pwd, currentPwd } = password;

    messageApi.open({
      type: "loading",
      content: "Changing the password...",
      duration: 0,
    });

    dispatch(
      ACTIONS.v1_user.user_change_password_request(
        user.email,
        currentPwd,
        pwd,
        ({ status }) => {
          if (status === 200) {
            messageApi.destroy();
            message.success(
              "Your password has been successfully changed. Kindly proceed to log in once more!"
            );
            props.onChangePage(SIDEBAR_FEATURE.PROFILE);
          } else if (status === 404) {
            messageApi.destroy();
            message.error(
              "Current password is input wrong. So, change password action is voided!"
            );
          } else {
            message.error("Failed to change password!");
          }
        }
      )
    );
  };

  const validateMessages = {
    required: "${name} is required!",
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        name="form_login"
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={onFinish}
      >
        <div className={styles["form-action"]} style={{ paddingTop: 0 }}>
          <h1 style={{ padding: "15px 0", fontSize: "22px" }}>
            Change Password
          </h1>
          <div className={styles.right}>
            <button
              type="submit"
              className={styles["reset-button"]}
              style={{ color: "#FF5F1F" }}
            >
              Save
            </button>
            <button type="reset" className={styles["reset-button"]}>
              Clear
            </button>
          </div>
        </div>

        <div style={{ margin: "3em 0" }}>
          <p>* Must be at least 8 characters</p>
          <p>* Not be the same as your username</p>
        </div>

        <Row>
          <Col span={11}>
            <Form.Item
              label="Current Password"
              name="currentPwd"
              rules={[
                {
                  required: true,
                  message: "Input your password!",
                  min: 8,
                },
              ]}
              // hasFeedback
            >
              <Input.Password
                name="currentPwd"
                value={password.currentPwd}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label="New Password"
              name="pwd"
              // hasFeedback
              rules={[
                {
                  // pattern: PWD_REGEX,
                  required: true,
                  message: "Password must be 8 in length",
                  min: 8,
                },
              ]}
            >
              <Input.Password
                name="pwd"
                value={password.pwd}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label="Confirm Password"
              name="confirmPwd"
              dependencies={["pwd"]}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "Confirm password is required!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("pwd") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Confirm password doesnt match password!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                name="confirmPwd"
                value={password.confirmPwd}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ChangePassword;
