export const FEATURE_NAME = {
  // Order
  ORDER_SUBMISSION: "ORDER_SUBMISSION",
  ORDER_LIST: "ORDER_LIST",
  ORDER_SUMMARY: "ORDER_SUMMARY",
  ORDER_TIMELINE: "ORDER_TIMELINE",
  // Finance
  TOPUP_DASHBOARD: "TOPUP_DASHBOARD",
  TRANSACTION_LIST: "TRANSACTION_LIST",
  BILLING_LIST: "BILLING_LIST",
  // App Info
  DASHBOARD: "DASHBOARD",
  LANDING_PAGE: "LANDING_PAGE",
  ACCOUNT_PROFILE: "ACCOUNT_PROFILE",
  ACCOUNT_PROFILE_DATA: "ACCOUNT_PROFILE_DATA",
  ACCOUNT_PROFILE_PWD_CHANGE: "ACCOUNT_PROFILE_PWD_CHANGE",
  ACCOUNT_PROFILE_ADDRESS_BOOK: "ACCOUNT_PROFILE_ADDRESS_BOOK",
  LOGOUT: "LOGOUT",
};

export const FEATURE_TYPE = {
  ROUTE: "ROUTE",
  LOGOUT: "LOGOUT",
};

export const PAGE_TITLE = {
  [FEATURE_NAME.ORDER_SUBMISSION]: "Order List",
};
