import React from "react";
import { Form } from "antd";

import { ProfileData, ProfileForm } from "./components";

const ProfileUser = ({ 
    user = {}, 
    isEdited = false, 
    form,
    optionsState = []
}) => {

    const { contact } = user;
    const filteredContact = contact?.split("-")[1] || null;

    const formDataSrc = {
        ...user,
        contactPrefix: (filteredContact && contact?.split("-")[0]) || user.contactPrefix || "+60",
        contact: filteredContact || contact,
    }

    return (
        <>
            {!isEdited ? (
                <ProfileData forms={user} />
            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    style={{ marginTop: "10px", overflow: 'hidden' }}
                    initialValues={formDataSrc}
                >
                <ProfileForm
                    optionsState={optionsState}
                />
                </Form>
            )}
        </>
    );
};

export default ProfileUser;
