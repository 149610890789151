// React Thirty Party Library
import React from "react";
import { Row, Col, Card, Skeleton } from 'antd';

// Constants Library
import { TEMPLATE } from '../../../../constants'

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import styles from "../common.module.css";

const {
    PROGRESS_SUMMARIES,
    PROGRESS_SUMMARY_FEATURE,
    PROGRESS_SUMMARY_TITLE
} = TEMPLATE.PROGRESS_SUMMARY

const ProgressSummary = (props) => {
    
    return (
        <Row gutter={16}>
            {Object.keys(PROGRESS_SUMMARY_FEATURE).map((key, i) => (
                <Col span={8} key={`${key}#${i}`}>
                    <Card 
                        bordered={false} 
                        className={styles["card-bg"]}  
                        key={`${key}#${i}`}
                        loading={props.loading}
                     >
                        <div className={styles["card-content"]}>
                            <img 
                                src={PROGRESS_SUMMARIES[key].icon} 
                                className={styles["card-icon-center"]} 
                                alt={PROGRESS_SUMMARY_FEATURE[key]} 
                            />
                            <div>
                                {
                                    props.loading ?
                                    <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
                                    :
                                    <h1 className={styles.num}>{Number.isInteger(props[key]) ? props[key] : props[key]?.toFixed(2)}</h1>
                                }
                                {PROGRESS_SUMMARY_TITLE[key]}
                            </div>
                        </div>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}

export default ProgressSummary;