import * as API_CONSTANT from '../constants'

export const getAllActivePoints = () => (
    fetch(`${API_CONSTANT.cp_v1_fe_path}/point/get/active`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())
)
