// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Constants Library

// Components Library
import { SearchBilling, TableBilling } from "./components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import classes from "../../../v1/pages/Transaction/index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const BillingList = () => {
  const dispatch = useDispatch();
  const uid = useSelector(reduxStoreWrapper("user.uid"));
  const billing = useSelector(reduxStoreWrapper("billing.byID"));
  const loading = useSelector(reduxStoreWrapper("style.loading.billing_list"));
  const dataSource = Object.values(billing).map((b) => ({
    key: b.billid,
    ...b,
  }));
  const [filteredData, setFilteredData] = useState(dataSource);
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    if(uid) {
        dispatch(
          REDUX_ACTION.v1_billing.get_billing_timeframe_thru_uid_last_90_days_request(
            uid
          )
        );
        dispatch(
          REDUX_ACTION.v1_order.get_order_timeframe_thru_uid_last_90_days_request(
            uid
          )
        );
    }

    return () => {};
  }, [dispatch, uid]);

  useEffect(() => {
    if (filteredData !== dataSource) {
      setFilteredData(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData]);

  return (
    <div className={classes.container}>
      <SearchBilling
        dataSource={dataSource}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        selectedRowKeys={selectedRowKeys}
      />
      <TableBilling
        dataSource={dataSource}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default BillingList;
