import * as ActionTypes from "../action-types";

const vehicle_defaultState = {
  byID: {},
  allIDs: [],
};

export const vehicle = (state = vehicle_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.VEHICLE_GET_ALL: {
      const vehicles = action.vehicles;
      vehicles &&
        vehicles.length &&
        vehicles.map((vehicle) => {
          const { vid } = vehicle;

          newState.byID[vid] = vehicle;

          if (!newState.allIDs.includes(vid)) newState.allIDs.push(vid);

          return null;
        });
      return newState;
    }

    default: {
      return state;
    }
  }
};
