export const TABLE_ORDERLIST_INDEX = {
  key: "key",
  Order_ID: "ordid",
  Order_Status: "status",
  Bus_Info: "busid",
  origin_point: "oPoint",
  Origin: "origid",
  Destination: "dstid",
  destination_point: "dPoint",
  Delivery_Instruction: "deliveryType",
  customer_instruction: "",
  Price: "price",
  Invoice: "refid",
  order_tracking: "isTracking",
  Parcel_Info: "parcel",
  Last_Updated: "updatedAt",
  Created_At: "createdAt",
  SO_Printed: "printedAt",
  Actions: "ordid",
};

export const TABLE_ORDERLIST_TITLE = {
  key: "key",
  Order_ID: "Order ID",
  Order_Status: "Order Status",
  Bus_Info: "Bus Info",
  origin_point: "Origin Point",
  Origin: "Origin Hub",
  Destination: "Destination Hub",
  destination_point: "Destination Point",
  Delivery_Instruction: "Delivery Instruction",
  customer_instruction: "Customer Instruction",
  Price: "Price (RM)",
  Invoice: "Invoice",
  order_tracking: "Order Tracking",
  Invoice_Status: "Invoice / Status",
  Parcel_Info: "Parcel Type",
  Volume: "Volume (m)",
  Parameter: "Quantity/ Volume (m³)/ Weight (kg)",
  Last_Updated: "Last Updated",
  SO_Printed: "SO Printed At",
  Status_Remarks: "Status Remarks",
  Created_At: "Created At",
  Actions: "Actions",
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "LAST 30 DAYS",
  LAST_60_DAYS: "LAST 60 DAYS",
  LAST_90_DAYS: "LAST 90 DAYS",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const OPTION_STATUS = {
  Submitted: "Submitted Succesfully",
  Pending: "Pending Self Lodge",
  Received: "Received At Origin Hub",
  Departed: "Departed From Origin Hub",
  Arrived: "Arrived & Ready For Self Collection",
  Collected: "Receiver Collected",
};

export const OPTION_DELIVERY = {
  HubToHub: "Hub to Hub",
  HubtoDelivery: "Hub to Delivery",
};

export const STATUS_DELIVERY = {
  Submitted: "Submitted Succesfully",
  Pending: "Pending Self Lodge",
  Received: "Received At Origin Hub",
  Departed: "Departed From Origin Hub",
  Arrived: "Arrived & Ready For Self Collection",
  Collected: "Receiver Collected",
};

export const OPTION_STATUS_ALL = "ALL";
export const OPTION_DELIVERY_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));
