import {
	GET_ADDRESS_BOOK_LIST,
	ARCHIVE_ADDRESS_BOOK_BY_ID,
	ADD_ADDRESS_BOOK,
	EDIT_ADDRESS_BOOK_BY_ID,
} from "../action-types";
import { TEMPLATE } from "../../../constants";

const { ADDRB_TYPE_THRU_CODE } = TEMPLATE.ADDRESS_BOOK;

const addressBook_defaultState = {
	byID: {},
	allIDs: [],
	byDefault: {
		SENDER: "",
		RECEIVER: "",
	},
};

export const addressBook = (state = addressBook_defaultState, action) => {
	let newState = JSON.parse(JSON.stringify(state));

	if (!action) return newState;

	switch (action.type) {
		case GET_ADDRESS_BOOK_LIST: {
			const address = action.addrb;

			address &&
				address.length &&
				address.map((addrb) => {
					const { addrbid, type } = addrb;
					const _default = addrb.default;
					newState.byID[addrbid] = addrb;

					if (type > 0 && (_default || _default === 1)) {
						newState.byDefault[ADDRB_TYPE_THRU_CODE[type]] =
							addrbid;
					}

					if (!newState.allIDs.includes(addrbid))
						newState.allIDs.push(addrbid);

					return null;
				});
			return newState;
		}

		case ADD_ADDRESS_BOOK:
		case EDIT_ADDRESS_BOOK_BY_ID:
		case ARCHIVE_ADDRESS_BOOK_BY_ID: {
			const addrb = action.addrb;
			const { addrbid, type, activeStatus } = addrb;
			const _default = addrb.default;

			if (type > 0 && (_default || _default === 1)) {
				newState.byDefault[ADDRB_TYPE_THRU_CODE[type]] = addrbid;
			}

			newState.byID[addrbid] = addrb;
			if (!newState.allIDs.includes(addrbid))
				newState.allIDs.push(addrbid);

			return newState;
		}
		default: {
			return state;
		}
	}
};
