import IPICK_LOGO from "../../assets/logo/logo.png";
import RED_QUESTION from "../../assets/images/red_question.svg";
import PIN_ICON from "../../assets/images/place-order/pin-destination.svg";
import ORDER_BOX_ICON from "../../assets/images/dashboard/OrderSubmitted.svg";
import COPY_ICON from "../../assets/images/topup/copy.png";
import BACK_ICON from "../../assets/images/my-acc/back.svg";

import icon_wallet from "../../assets/images/dashboard/dashboard-page/wallet.svg";
import icon_unsorted_order_num from "../../assets/images/dashboard/dashboard-page/delivery3.svg";
import icon_pending_self_lodge_num from "../../assets/images/dashboard/dashboard-page/delivery4.svg";
import icon_ongoing_order_num from "../../assets/images/dashboard/dashboard-page/delivery5.svg";
import icon_pending_self_collection_num from "../../assets/images/dashboard/dashboard-page/delivery6.svg";
import icon_park_order_num from "../../assets/images/dashboard/dashboard-page/delivery7.svg";
import icon_bankIcon from "../../assets/images/topup/bankIcon.svg";
import icon_bankName from "../../assets/images/topup/bankName.svg";
import icon_copyBig from "../../assets/images/topup/copyIcon.svg";
import icon_topupBig from "../../assets/images/topup/topup.svg";
import icon_whatsappBig from "../../assets/images/topup/whatsappIcon.svg";
import icon_copyInfo from "../../assets/images/topup/copyInfo.png";
import icon_copyId from "../../assets/images/topup/copyId.png";
import icon_whatsapp_link from "../../assets/images/topup/whatsapp.png";
import icon_profile from "../../assets/images/my-acc/profile-icon.svg";
import icon_changePass from "../../assets/images/my-acc/change-pass-icon.svg";
import icon_addressBook from "../../assets/images/my-acc/addresses-book-icon.svg";
import icon_user from "../../assets/images/my-acc/profile/person.svg";
import icon_parcelChecked from "../../assets/images/my-acc/profile/parcel-check.svg";
import icon_star from "../../assets/images/my-acc/profile/star.svg";
import icon_edit from "../../assets/images/my-acc/profile/edit.svg";
import icon_bus from "../../assets/images/modal-icon/bus.svg";
import icon_logout from "../../assets/images/modal-icon/logout.svg";
import icon_person_del from "../../assets/images/modal-icon/person-delete.svg";
import icon_address_edit from "../../assets/images/my-acc/edit-address.svg";
import icon_address_del from "../../assets/images/my-acc/delete-address.svg";
import icon_arrow from "../../assets/images/Arrow.svg";
import icon_update_status from "../../assets/images/modal-icon/status-update.svg";
import icon_remarks from "../../assets/images/remarks.svg";

import IMG_MY_ACCOUNT_DEFAULT from "../../assets/images/my-acc/default-profile-photo.svg";

import img_location_dut from "../../assets/images/dashboard/dashboard-page/dut.svg";
import img_wallet_bg from "../../assets/images/topup/wallet-bg2.png";
import img_point_fee from "../../assets/images/dashboard/point_fee.png";

import icon_export from "../../assets/images/excel.svg";
import icon_filter from "../../assets/images/Filter.svg";

import icon_active_map from "../../assets/images/map-active.png";
import icon_inactive_map from "../../assets/images/map-inactive.png";

export const ICON = {
  IPICK_LOGO: IPICK_LOGO,
  RED_QUESTION: RED_QUESTION,
  PIN_ICON: PIN_ICON,
  ORDER_BOX_ICON: ORDER_BOX_ICON,
  COPY_ICON: COPY_ICON,
  BACK_ICON: BACK_ICON,
  ICON_ARROW: icon_arrow,
  ICON_REMARKS: icon_remarks,
  DASHBOARD: {
    icon_wallet,
    icon_unsorted_order_num,
    icon_pending_self_lodge_num,
    icon_ongoing_order_num,
    icon_pending_self_collection_num,
    icon_park_order_num,
    img_point_fee,
  },
  FINANCE: {
    icon_bankIcon,
    icon_bankName,
  },
  TOPUP: {
    icon_copyBig,
    icon_topupBig,
    icon_whatsappBig,
    icon_copyInfo,
    icon_copyId,
    icon_whatsapp_link,
  },
  MY_ACCOUNT: {
    icon_profile,
    icon_changePass,
    icon_addressBook,
    icon_edit,
    icon_user,
    icon_parcelChecked,
    icon_star,
    icon_address_edit,
    icon_address_del,
  },
  MODAL: {
    icon_bus,
    icon_logout,
    icon_person_del,
    icon_update_status,
  },
  icon_remarks,
  icon_active_map,
  icon_inactive_map,
};

export const IMG = {
  IMG_MY_ACCOUNT_DEFAULT: IMG_MY_ACCOUNT_DEFAULT,
  DASHBOARD: {
    img_location_dut,
  },
  TOPUP: {
    img_wallet_bg,
  },
  TRANSACTION: {
    icon_export,
    icon_filter,
  },
};
