export const ADDRB_TYPE = {
  SENDER: 'SENDER',
  RECEIVER: 'RECEIVER'
}

export const ADDRB_TYPE_TITLE = {
  [ADDRB_TYPE.SENDER]: 'Sender',
  [ADDRB_TYPE.RECEIVER]: 'Receiver'
} 

export const ADDRB_TYPE_CODE = {
  [ADDRB_TYPE.SENDER]: 1,
  [ADDRB_TYPE.RECEIVER]: 2 
}

export const ADDRB_TYPE_THRU_CODE = Object.fromEntries(Object.entries(ADDRB_TYPE_CODE).map(([k, v]) => [v, k]))