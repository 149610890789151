// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Constants Library

// Components Library
import { SearchTransaction, TableTransaction } from "./component";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import styles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const TransactionList = () => {
  const dispatch = useDispatch();

  const uid = useSelector(reduxStoreWrapper("user.uid"));
  const transactions = Object.values(
    useSelector(reduxStoreWrapper("transaction.byID"))
  );

  const loading = useSelector(
    reduxStoreWrapper("style.loading.transaction_list")
  );

  const dataSource = transactions.map((t) => ({
    key: t.txnid,
    ...t,
  }));

  const [filteredData, setFilteredData] = useState(dataSource);
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    if(uid) {
        dispatch(
          REDUX_ACTION.v1_transaction.get_transaction_timeframe_thru_uid_last_90_days_request(
            uid
          )
        );
    
        dispatch(
          REDUX_ACTION.v1_billing.get_billing_timeframe_thru_uid_last_90_days_request(
            uid
          )
        );
    
        dispatch(
          REDUX_ACTION.v1_order.get_order_timeframe_thru_uid_last_90_days_request(
            uid
          )
        );
    }

    return () => {};
  }, [dispatch, uid]);

  useEffect(() => {
    if (filteredData !== dataSource) {
      setFilteredData(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData]);

  return (
    <div className={styles.container}>
      <SearchTransaction
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        dataSource={dataSource}
        selectedRowKeys={selectedRowKeys}
      />
      <TableTransaction
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        dataSource={dataSource}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default TransactionList;
