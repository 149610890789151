import { 
    Card, 
    Row, 
    Col
} from "antd";

import {
    ROOT_CONSTANT,
    TEMPLATE
  } from "../../constants";
  
  // Components Library
  import { 
    ButtonCopy 
} from "..";
  
// Handler Library

// Services Library

// Redux Actions

// Styles Library
import styles from "./index.module.css";

const { icon_bankIcon, icon_bankName } = ROOT_CONSTANT.IMG.ICON.FINANCE;
const { Account_Holder, Account_Number } = TEMPLATE.BANK.ACCOUNT_INFO;

const BankInformation = (props) => {
  
    const bankInfo = (
        <div className={styles["flex-img"]}>
            <img src={icon_bankIcon} alt="icon_bankIcon" />
            <img src={icon_bankName} alt="icon_bankName" />
        </div>
    );
  
    const formContent = [
        { formLabel: "Bank Account", formValue: bankInfo },
        { formLabel: "Account Holder", formValue: Account_Holder },
        { formLabel: "Account Number", formValue: Account_Number },
    ]; 

    return (
        <Card {...props}>
            {formContent.map((form, i) => (
                <Row key={`${form.formLabel}#${i}`}>
                    <Col span={8} className={styles["col-info"]}>
                        {form.formLabel}
                    </Col>
                    <Col span={12} className={styles["col-info"]}>
                        {form.formValue} &emsp; 
                        {i === 2 ? <ButtonCopy copiedtext={Account_Number}/> : ""}
                    </Col>
                </Row>
            ))}
      </Card>
    )
}

export default BankInformation