import * as API_CONSTANT from "../constants";
import { APP_INFO } from "../../../config";

export const submitOrder = ({ order, uid, memid, name }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/order/submit`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      order,
      uid,
      memid,
      name,
    }),
  }).then((res) => res.json());

export const getOrderTimeFrameThruUID = ({ uid, startTime, endTime }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/order/get/timeframe/uid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
      startTime,
      endTime,
    }),
  }).then((res) => res.json());

export const getProceedingOrderStateThruUID = ({ uid }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/order/state/proceed/get/uid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
    }),
  }).then((res) => res.json());

export const getOrderLogsByORDID = ({ ordid }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/order/log/get/ordid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      ordid,
    }),
  }).then((res) => res.json());

// export const getAllOrderStatues = ({ platid = APP_INFO.APP_PLATFORM } = {}) =>
export const getAllOrderStatues = (platid) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/order/status/get/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(
        platid
    ),
  }).then((res) => res.json());

export const getOrderCompleted = (uid) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/order/state/count/completed/get/uid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
    }),
  }).then((res) => res.json());
