// Third-party Library 
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Space,
    Form,
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    Button,
    Tag,
    Radio,
    // Tooltip,
    Switch,
    Card
  } from "antd";

// Constants
import { TEMPLATE } from "../../../../../../constants";
import { APP_CONFIG } from "../../../../../../config"; 

// Components
import { GoogleMapIFrame, NumericInput } from "../../../../../../components";

// Services Library
import {
    REDUX as REDUX_UTIL,
    // TIME,
} from "../../../../../../services/util";

  // Styles
import coms from "../../index.module.css";

const { 
    DATA_FIELD, 
    STATE,
    HUB_OPTIONS
} = TEMPLATE.ORDER;

const { reduxStoreWrapper } = REDUX_UTIL;

const ReceiverInformation = ({
    form,
    order
}) => {

    const uroleid = useSelector(reduxStoreWrapper('user.uroleid'))
    const hubs = useSelector(reduxStoreWrapper("hub.byID"));
    const zones = useSelector(reduxStoreWrapper("zone.byZoneid"))
    const points = useSelector(reduxStoreWrapper('point.byID'))
    const hubsIDs = useSelector(reduxStoreWrapper("hub.allIDs"));
    const addressBook = useSelector(reduxStoreWrapper("addressBook.byID"));
    const defReceiver = useSelector(reduxStoreWrapper('addressBook.byDefault.RECEIVER'))

    const [ hub, setHub ] = useState(uroleid === 'C' ? 'hub#amj' : 'point#mkr')
    const [ defaultReceiver, setDefaultReceiver ] = useState(defReceiver)

    const [ receiverPostcode, setreceiverPostcode ] = useState('')
    const [ filteredPoint, setFilteredPoint ] = useState(Object.values(points))

    // const { dstid } = form.getFieldsValue()

    // const showHubDetailDefault = (dstid === HUBS.OTHERS.hubid && true) || false;
    // const [showHubDetail, setShowHubDetail] = useState(showHubDetailDefault);
    const prefixContactSelector = (
        <Form.Item name={["receiver","contactPrefix"]} noStyle>
            <Select style={{ width: 70 }} >
                <Select.Option value="+60">+60</Select.Option>
            </Select>
        </Form.Item>
      );

    //   const disabledDate = current => {
    //     // Disable dates before today (including today)
    //     const today = form.getFieldValue('etd');
    //     return current && current < today;
    //   };

      
    //   const hubMap = {};

    //   hubsIDs.forEach((hubid) => {
    //       const hubState = hubs[hubid].state;
          
    //       if (hubState !== undefined) {
    //           if (!hubMap[hubState]) {
    //               hubMap[hubState] = [];
    //             }
                
    //             hubMap[hubState].push({
    //                 label: hubs[hubid].name,
    //                 value: hubid,
    //             });
    //         }
    //     });

    //     // OptionGroup -- Main OptionGroup value
    //     const orderOrigid = hubs[order.origid];

    //     if (Object.prototype.hasOwnProperty.call(hubMap, orderOrigid?.state)) {
    //     // Delete the array associated with the key
    //     delete hubMap[orderOrigid.state];
    //     }

    // const hubList = Object.keys(hubMap).map((hubState) => ({
    //     label: hubState,
    //     options: hubMap[hubState],
    // }));

    const hubList = HUB_OPTIONS(hubs, hubsIDs).filter(hub => hub.label !== hubs[order.origid]?.state)

    const orderOrigpid = points[order.origid]
    const filteredPoints = filteredPoint.filter(item => item.zone !== orderOrigpid?.zone)

    // OptionGroup -- Main OptionGroup value for suggested point
    const pointList = filteredPoints.map(point => ({
        label: point.address,
        value: point.hubid
    }))
        

    // const HubSelector = (hubid) => {
    //     if (hubid === HUBS.OTHERS.hubid) setShowHubDetail(true);
    //     else if (showHubDetail) setShowHubDetail(false);
    // };

    useEffect(() => {

        const defaultValueReceiver = Object.values(addressBook).find(ab => ab.default === 1 && ab.type === 2) // get desired details of selected addressbook
        
        if(defaultValueReceiver && Object.values(addressBook).length) {
            let { name, contact, email, address, postcode, city, state } = defaultValueReceiver
    
            form.setFieldsValue({
                receiver: {
                    name,
                    contact,
                    email,
                    address,
                    postcode,
                    city,
                    state,
                }
            });

            setDefaultReceiver(defaultValueReceiver.addrbid)

        }
    
      }, [Object.values(addressBook).length]);

    
    const receiverSelector = (addrbid) => {
        const defaultValueReceiver = Object.values(addressBook).find((ab) => ab.addrbid === addrbid); 
        let { name, contact, email, address, postcode, city, state } = defaultValueReceiver

        form.setFieldsValue({
            receiver: {
                name,
                contact,
                email,
                address,
                postcode,
                city,
                state,
            }
        });
    };

    const optionsReceiver = Object.values(addressBook)
        .filter((ab) => ab.type === 2)
        .map((ab) => ({
            label: (
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    {ab.name}
                    {ab.default === 1 && <Tag color="orange">Default</Tag>}
                </div>
            ),
            value: ab.addrbid,
        }));
    
    const optionsState = Object.keys(STATE).map((state) => ({
        label: STATE[state].name,
        value: STATE[state].code,
    }));


    const coordinates = uroleid === 'C' ? hubs[hub]?.coordinate : points[hub]?.coordinate

    // Search Point function
    const searchPointHDLR = (e) => {

        const { value } = e.target
        let selectedPoint = []

        if(value) {
            selectedPoint = Object.values(points).filter(point => point.name.toLowerCase().includes(value.toLowerCase()));
        } else {
            selectedPoint = Object.values(points).filter(point => point.postcode.includes(receiverPostcode))
        }

        setFilteredPoint(selectedPoint)
    }
    
    return (
        <>
            <p>RECEIVER INFORMATION</p>
            <Card className={coms["card"]}>
                <Row gutter={3} justify={'space-between'}>
                    <Col span={10}>
                        <Form.Item label='Delivery Instruction' name={'deliveryType'}  rules={[{ required: true }]}>
                            <Radio.Group>
                                <Space direction='vertical'>
                                {
                                        uroleid === 'C'
                                        &&
                                        <>
                                            <Radio value={'h2h'}>Pick up at hub</Radio>
                                            <Radio value={'h2d'}>Doorstep drop off</Radio>
                                        </>
                                        ||
                                            <Radio value={'p2p'}>Pick up at point</Radio>
                                    }
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        {
                            uroleid === "C"
                            &&
                            <Form.Item label={`Select a destination hub`} name={'dstid'} rules={[{ required: true }]}>
                                <Select
                                    onChange={e => setHub(e)}
                                    options={hubList}
                                />
                            </Form.Item>
                            ||
                            <>
                                <Row gutter={10}>
                                    <Col span={18}>
                                        <Form.Item label={'Receiver Postcode'}>
                                            <Input maxLength={5} onChange={(e) => setreceiverPostcode(e.target.value)}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Button type='primary' style={{ position: 'absolute', bottom: '30%' }} onClick={searchPointHDLR}>Search</Button>
                                    </Col>
                                </Row>
                                <Form.Item label={`Suggested Pick Up Point (${filteredPoints.length})`} name={'dstid'} rules={[{ required: true }]}>
                                    <Select
                                        disabled={!Object.values(zones)}
                                        showSearch
                                        options={pointList}
                                        onChange={e => setHub(e)}
                                        filterOption={(input, option) => {
                                            return option.label.toLowerCase().includes(input.toLowerCase())
                                        }}
                                    />
                                </Form.Item>
                            </>
                        }
                        <Form.Item label='Desired Arrival Datetime' name='eta' rules={[{ required: true }]}>
                            <DatePicker showTime format={"DD/MM/YYYY hh a"} style={{ width: '100%' }} 
                            // disabledDate={disabledDate} 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    {
                        coordinates 
                        &&
                        <GoogleMapIFrame 
                            title={uroleid === 'C' ? hubs[hub]?.code : points[hub]?.code}
                            APIKey={APP_CONFIG.API_KEY.GOOGLE_MAP}
                            coordinate={coordinates}
                            width="100%"
                            height="100%"
                        />
                    }
                    </Col>
                </Row>
            </Card>
            <Card className={coms["card"]}>
                {/* <Row>
                    <Col span={8}>
                        <Form.Item
                            label={"Destination"}
                            name={DATA_FIELD.dstid}
                            rules={[{ required: true }]}
                        >
                            <Select
                                options={hubList}
                                style={{ width: "14vw" }}
                                onSelect={(s) => HubSelector(s)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={"Expected Arrival Date"}
                            name={DATA_FIELD.eta}
                            rules={[{ required: true }]}
                            initialValue={TIME.dayJS(TIME.latestTime)}
                        >
                            <DatePicker
                                className={coms.inputText}
                                style={{ width: "14vw" }}
                                format={"DD/MM/YYYY"}
                            />
                        </Form.Item>
                    </Col> */}
                    <Col span={8}>
                       {<Form.Item label={"Favorite's Receiver"}>
                            <Select
                                onSelect={(ab) => receiverSelector(ab)}
                                defaultValue={defaultReceiver}
                                options={optionsReceiver}
                            />
                        </Form.Item>}
                    </Col>
                {/* </Row> */}
            {/* {showHubDetail && (
            <Row>
                <Col span={24}>
                <Form.Item
                    label={"Please specify the hub, city and state"}
                    name={DATA_FIELD.dst_detail}
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Eg: Kuantan Sentral/ Kuantan/ Pahang" />
                </Form.Item>
                </Col>
            </Row>
            )} */}
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label={"Receiver Name"}
                        name={DATA_FIELD.receiver_name.split("_")}
                        rules={[{ required: true }]}
                    >
                    <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={"Receiver Contact"}
                        name={DATA_FIELD.receiver_contact.split("_")}
                        rules={[{ required: true }]}
                    >
                        <NumericInput
                            addonBefore={prefixContactSelector}
                            // style={{ width: "14vw" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={"Receiver Email"}
                        name={DATA_FIELD.receiver_email.split("_")}
                        rules={[{ required: false, type: "email" }]}
                    >
                    <Input
                        placeholder="example@example.com"
                    />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={"Receiver Address"}
                        name={DATA_FIELD.receiver_address.split("_")}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
            <Col span={8}>
                <Form.Item
                    label={"Postcode"}
                    name={DATA_FIELD.receiver_postcode.split("_")}
                    rules={[{ required: true }]}
                >
                    <Input maxLength={5} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label={"City"}
                    name={DATA_FIELD.receiver_city.split("_")}
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label={"State"}
                    name={DATA_FIELD.receiver_state.split("_")}
                    rules={[{ required: true }]}
                >
                    <Select options={optionsState} />
                </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col span={24}>
                <Form.Item
                    label={"Receiver Remark"}
                    name={DATA_FIELD.receiver_remark.split("_")}
                >
                    <Input placeholder={"Eg: Will come to self collect"} />
                </Form.Item>
            </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Item
                        name={"addrbook_save_receiver"}
                        label={"Save to address book"}
                        valuePropName='checked'
                        noStyle
                    >
                    <Switch
                        size="small"
                        style={{
                            marginRight: "10px",
                        }}
                    />
                    </Form.Item>
                    Save to address book
                </Col>
            </Row>
            </Card>
        </>
    )
}


export default ReceiverInformation