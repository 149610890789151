import React from "react";
import PropTypes from "prop-types";
import { Layout as LayoutAntd } from "antd";
import Sidebar from "./Sidebar";

const Layout = (props) => {
  return (
    <LayoutAntd>
        <LayoutAntd.Sider
            width={"15vw"}
        >
            <Sidebar
                collapsed={props.collapsed || false}
                items={props.sidebarItems}
                onClick={props.onClickSidebarItem}
                selectedkeys={props.selectedkeys}
                themeColor={props.themeColor}
            />
        </LayoutAntd.Sider>
        <LayoutAntd.Content>{props.children}</LayoutAntd.Content>
    </LayoutAntd>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  sidebarItems: PropTypes.arrayOf(
    PropTypes.shape({
		key: PropTypes.string.isRequired,
		icon: PropTypes.object.isRequired,
		label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClickSidebarItem: PropTypes.func,
  selectedkeys: PropTypes.string,
  themeColor: PropTypes.string,
};

Layout.defaultProps = {
  sidebarItems: [],
  onClickSidebarItem: () => {},
};

export default Layout;
