/* eslint-disable import/namespace */
// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { ROOT_CONSTANT } from "../../../../constants";

// Components Library
import {
  HeaderSidebar,
  ContentSidebar
} from "./components"

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions

// Styles Library
import styles from "../../common.module.css";

const { IMG_MY_ACCOUNT_DEFAULT } = ROOT_CONSTANT.IMG.IMG;

const { reduxStoreWrapper } = REDUX_UTIL;

const Sidebar = (props) => {
  const user = useSelector(reduxStoreWrapper("user"));

  return (
    <div className={styles.sidebar}>
      <HeaderSidebar 
        profilePic={IMG_MY_ACCOUNT_DEFAULT} 
        {...user} 
      />
      <ContentSidebar
        onChangePage={props.onChangePage}
      />
    </div>
  );
};

export default Sidebar;
