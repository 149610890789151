import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config

// Services

// Redux Action

// Neccessary Actions

export const get_all_order_statuses_request = (platid) => dispatch => {
    API
    .getAllOrderStatues({
        platid
    })
    .then(info => {

        const {
            status,
            orderStatuses,
            msg
        } = info

        if(status === 200) {
            dispatch(get_all_order_statuses_success(orderStatuses))
        } else {
            message.error(msg)
        }
    })
}

const get_all_order_statuses_success = (orderStatuses) => {
    return {
        type: ActionTypes.ORDER_STATUS_GET_ALL,
        orderStatuses
    };
}