import * as CONFIG from '../../../config'
import { CONSTANT_ACTIONS } from '../../../constants'
 
export const cp_v1_fe_path = CONFIG.APP_CONFIG.CP_V1_FE_API_GATEWAY
export const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const API_ACTION = CONSTANT_ACTIONS.ACTION_API
export const ACTION = CONSTANT_ACTIONS.COMMON_ACTION