// React Thirty Party Library
import { useState } from "react";

// Constants Library
import { TEMPLATE } from '../../constants'

// Components Library
import {
  MyAccountSidebar,
  MyAccountPages
} from "./components"

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import styles from "./common.module.css";

const {
  SIDEBAR_FEATURE
} = TEMPLATE.ACCOUNT

const MyAccount = () => {
  const [currentPage, setCurrentPage] = useState(<MyAccountPages.Profiles />);

  const onChangePage = (p) => {
    let componentChanged;

    switch(p) {
      case SIDEBAR_FEATURE.PROFILE: {
        componentChanged = 
          <MyAccountPages.Profiles />
        break;
      }
      case SIDEBAR_FEATURE.CHANGE_PASSWORD: {
        componentChanged = 
          <MyAccountPages.ChangePassword 
            onChangePage={onChangePage} 
          />
        break;
      }
      case SIDEBAR_FEATURE.ADDRESS_BOOK: {
        componentChanged = 
          <MyAccountPages.AddressBook />;
        break;
      }
      default: 
        break;
    }
    
    setCurrentPage(componentChanged);
  };

  return (
    <div className={styles.container}>
      <MyAccountSidebar onChangePage={onChangePage} />
      <div className={styles["container-routes-content"]}>
        {currentPage}
      </div>
    </div>
  );
};

export default MyAccount;
