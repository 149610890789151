import * as ActionTypes from '../action-types'

const orderState_defaultState = {
    byID: {},
    byStatus: {},
    bySorted: {
        SORTED: [],
        UNSORTED: []
    },
    allStatuses: []
}

export const orderState = (state = orderState_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.ORDER_STATE_PROCEED_GET: {
            const orderStates = action.orderStates;

            orderStates
            &&
            orderStates.length
            &&
            orderStates.map(os => {

                const { status, sorted = 0, ordid } = os

                newState.byID[ordid] = os

                if(!Object.keys(newState.byStatus).includes(status)) {
                    newState.byStatus[status] = []
                } else {
                    if(!newState.byStatus[status].includes(ordid))
                        newState.byStatus[status].push(ordid) 
                }

                if(sorted) {
                    if(!newState.bySorted.SORTED.includes(ordid))
                        newState.bySorted.SORTED.push(ordid) 
                } else {
                    if(!newState.bySorted.UNSORTED.includes(ordid))
                        newState.bySorted.UNSORTED.push(ordid) 
                }

                return null
            })

            return newState;
        }
        default: {
            return state;
        }
    }
}