import * as ActionTypes from "../action-types";

const order_defaultState = {
  byID: {},
  allIDs: [],
  byStatus: {},
  bySorted: {
    SORTED: [],
    UNSORTED: []
},
  completed: 0,
};

export const order = (state = order_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.ORDER_SUBMIT: {
      const order = action.order;
      const ordid = action.ordid;

      if (ordid) {
        newState.byID[ordid] = order;

        if (!newState.allIDs.includes(ordid)) newState.allIDs.push(ordid);
      }

      return newState;
    }
    case ActionTypes.ORDER_GET_BY_ORDID: {
      const order = action.order;
      const { ordid } = order;

      if (ordid) {
        newState.byID[ordid] = { ...newState.byID[ordid], ...order };

        if (!newState.allIDs.includes(ordid)) newState.allIDs.push(ordid);
      }

      return newState;
    }
    case ActionTypes.ORDER_TIMEFRAME_GET_TRU_UID: {
      const orders = action.orders;

      orders &&
        orders.length &&
        orders.map((order) => {
          const { ordid, status, sorted = 0 } = order;

          newState.byID[ordid] = order;
          if (!newState.allIDs.includes(ordid)) newState.allIDs.push(ordid);

            if(!Object.keys(newState.byStatus).includes(status)) {
                newState.byStatus[status] = []
            } else {
                if(!newState.byStatus[status].includes(ordid))
                    newState.byStatus[status].push(ordid) 
            }

            if(sorted) {
                if(!newState.bySorted.SORTED.includes(ordid))
                    newState.bySorted.SORTED.push(ordid) 
            } else {
                if(!newState.bySorted.UNSORTED.includes(ordid))
                    newState.bySorted.UNSORTED.push(ordid) 
            }

          return null;
        });

      return newState;
    }
    case ActionTypes.GET_ORDER_STATUS_COMPLETED: {
      const order = action.order;

      newState.completed = order;

      return newState;
    }
    default: {
      return state;
    }
  }
};
