import { Tag } from "antd";

// Contanst
import { TEMPLATE } from "../../constants";

const { DATA_FIELD_VALUES } = TEMPLATE.ORDER;

// export const orderStatusesTreeStructure = (orderStatues, uroleid) => {
//   let tree = [];

//   orderStatues &&
//     Object.keys(orderStatues) &&
//     Object.keys(orderStatues).length &&
//     Object.entries(orderStatues).map(([status, os]) => {
//       const { type, display, colorTag = "default" } = os;

//       let node = {};

//       if (uroleid === "POP") {
//         if (status !== "O0202" && status !== "O0502") {
//           node = {
//             title: (
//               <Tag color={colorTag} key={status}>
//                 {display}
//               </Tag>
//             ),
//             key: `status#${status}`,
//             value: `status#${status}`,
//           };
//         }
//       } else {
//         if (status !== "O0203" && status !== "O0503") {
//           node = {
//             title: (
//               <Tag color={colorTag} key={status}>
//                 {display}
//               </Tag>
//             ),
//             key: `status#${status}`,
//             value: `status#${status}`,
//           };
//         }
//       }

//       if (Object.keys(node).length > 0) {
//         const branch = tree.find((t) => t.value === `type#${type}`) || {};

//         const children = [...(branch.children || []), node];
//         const uniqueChildren = [
//           ...new Map(children.map((m) => [m.key, m])).values(),
//         ];

//         if (branch && Object.keys(branch).length) {
//           tree = tree.map(
//             (b) =>
//               (b.key === `type#${type}` && {
//                 ...b,
//                 children: uniqueChildren,
//               }) ||
//               b
//           );
//         } else {
//           tree.push({
//             title: type,
//             key: `type#${type}`,
//             value: `type#${type}`,
//             children: uniqueChildren,
//           });
//         }
//       }
//     });
//   return tree;
// };

export const orderStatusesTreeStructure = (orderStatues) => {
  let tree = [];

  orderStatues &&
    Object.keys(orderStatues) &&
    Object.keys(orderStatues).length &&
    Object.entries(orderStatues)
    .map(([status, os]) => {
      const { type, display, colorTag = "default" } = os;

      if (type === "ROOT") {
        const rootNode = {
          title: (
            <Tag color={colorTag} key={status}>
              {display}
            </Tag>
          ),
          key: `status#${status}`,
          value:` status#${status}`,
        };
  
        tree.push(rootNode);
        return; 
      }

      const node = {
        title: (
          <Tag color={colorTag} key={status}>
            {display}
          </Tag>
        ),
        key: `status#${status}`,
        value: `status#${status}`,
      };

      const branch = tree.find((t) => t.value === `type#${type}` && t.type !== "ROOT") || {};

      const children = [...(branch.children || []), node];
      const uniquwChildren = [
        ...new Map(children.map((m) => [m.key, m])).values(),
      ];

      if (branch && Object.keys(branch).length) {
        tree = tree.map(
          (b) =>
            (b.key === `type#${type}` && { ...b, children: uniquwChildren }) ||
            b
        )
        
      } 
      else {
        tree.push({
          title: type,
          key: `type#${type}`,
          value: `type#${type}`,
          children: uniquwChildren,
        });
      }
      
    })

  return tree;
};

export const orderDeliveryTypeTreeStructure = () => {
  let tree = [];

  const deliveryTypes = DATA_FIELD_VALUES.deliveryType;

  deliveryTypes &&
    Object.keys(deliveryTypes) &&
    Object.keys(deliveryTypes).length &&
    Object.entries(deliveryTypes).map(([dt, name]) => {
      const node = {
        title: name,
        key: `deliveryType#${dt}`,
        value: `deliveryType#${dt}`,
      };

      const branch = tree.find((t) => t.value === `deliveryType#${dt}`) || {};

      if (!(branch && Object.keys(branch).length)) {
        tree.push(node);
      }
    });

  return tree;
};
