import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { message } from 'antd'

// Constant
import { ROUTE } from '../../constants'

// Components
import SSOLoading from '../SSOLoading'

// Service
import { usePrevious } from '../../services/util'

// Redux Action
import { main_verify } from '../../services/redux/actions'

// Style

const {
    sso_verify_link_request
} = main_verify 

const SSOLinkVerify = () => {

    const dispatch = useDispatch()
    const params = useParams();
    const navigate = useNavigate()
    const link = params && params.link

    const prevLink = usePrevious(link)

    useEffect(() => {

        if(prevLink !== link) {

            dispatch(
                sso_verify_link_request(
                    link,
                    ({
                        status, err, msg, extra
                    }) => {
                        if(status >= 200 && status < 300) {
                            message.success(msg)
    
                            switch(status) {
                                case 201: {
                                    navigate(ROUTE.MODULE_ROUTE.Signup_Success, {state:{verify: true}})
                                    break;
                                }
                                case 202: {
                                    navigate(ROUTE.MODULE_ROUTE.ResetCredential_Reset_Password, {state:{verify: true, ...extra}})
                                    break;
                                }
                                default: {
                                    navigate(ROUTE.MODULE_ROUTE.Login)
                                    break;
                                }
                            }
                            
                        } else if (status >= 400) {
                            message.error(err)
                            navigate(ROUTE.MODULE_ROUTE.Link_Unvalidated, {state:{invalid:true}})
                        } 
                    }
                )
            )

        }

    }, [dispatch, link, prevLink])

    return (
        <>
            <SSOLoading />
        </>
    )
}

export default SSOLinkVerify