import { useState } from "react";
import { Button } from "antd";

import { ButtonLink, BtnSubmit } from "../../components";
import {
  DeleteSenderReceiver,
  ConfirmLogout,
  PopupBusInfo,
  ModalStatusUpdate,
  ModalNotif,
} from "./modules";

// eslint-disable-next-line import/namespace
import { TEMPLATE } from "../../constants";

const { MODAL_ACTIONS } = TEMPLATE.ACCOUNT;
const { MODALS_UPDATE_STATUS, MODAL_ACTION } = TEMPLATE.MODAL;

const ButtonModal = (props) => {
  const {
    children,
    setmodal,
    personrole,
    personname,
    busInfoData = {},
    onClick,
    model = "",
    data,
    extra = {},
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modals_update_status = MODALS_UPDATE_STATUS.filter(
    (k) => k === setmodal
  )[0];

  const handleModal = () => {
    setIsModalOpen(true);
  };

  const handleModalContext = (setmodal) => {
    switch (setmodal) {
      case MODAL_ACTIONS.deletePerson:
        return (
          <DeleteSenderReceiver
            personrole={personrole}
            setmodal={setmodal}
            personname={personname}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => setIsModalOpen(false)}
            onClick={onClick}
          />
        );

      case MODAL_ACTIONS.logout:
        return (
          <ConfirmLogout
            isModalOpen={isModalOpen}
            setIsModalOpen={() => setIsModalOpen(false)}
            onClick={onClick}
          />
        );
      case MODAL_ACTIONS.busInfo:
        return (
          <PopupBusInfo
            busInfoData={busInfoData}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => setIsModalOpen(false)}
            onClick={onClick}
          />
        );
      case modals_update_status:
        return (
          <ModalStatusUpdate
            setmodal={setmodal}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => setIsModalOpen(false)}
            onClick={onClick}
            extra={extra}
            data={data}
          />
        );
      case MODAL_ACTION.wallet_notif:
        return (
          <ModalNotif
            setmodal={setmodal}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => setIsModalOpen(false)}
            onClick={onClick}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {handleModalContext(setmodal)}
      {(model === "link" && (
        <ButtonLink {...props} onClick={handleModal}>
          {children}
        </ButtonLink>
      )) ||
        (model === "default" && (
          <Button
            {...props}
            onClick={handleModal}
            style={{
              backgroundColor: "var(--themeBackgroundColor)",
              color: "var(--creamColor)",
            }}
          >
            {children}
          </Button>
        ))}
    </>
  );
};
export default ButtonModal;
