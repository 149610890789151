import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

export const get_price_list_thru_zonid_request = (zoneid) => dispatch => {

    // dispatch(setLoadingZonePriceList())

    API.getPricelistThruZoneid(zoneid)
        .then(info => {

            const {
                status,
                zonePrices,
                msg
            } = info

            if (status === 200) {
                message.success(msg)
                dispatch(get_price_list_thru_zonid_success(zoneid, zonePrices))
            } else {
                message.error(msg)
            }
        })
        // .finally(() => {
        //     dispatch(unsetLoadingZonePriceList())
        // })
}

const get_price_list_thru_zonid_success = (zoneid, priceList) => {
    return {
        type: ActionTypes.GET_ZONE_PRICE_LIST,
        zoneid,
        priceList
    };
}