import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";
import { batch } from "react-redux";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions
// import {
//     setLoadingvehicle,
//     unsetLoadingvehicle,
// } from './style'

export const get_all_vehicle_request = () => (dispatch) => {
  // dispatch(setLoadingvehicle())

  API.getAllVehicle()
    .then((info) => {
      const { status, vehicles, msg } = info;

      if (status === 200) {
        dispatch(get_all_vehicle_success(vehicles));
        message.success("Get Vehicle List Data Successfully");
      } else {
        message.error(msg);
      }
    })
    .finally(() => {
      // dispatch(unsetLoadingvehicle())
    });
};

const get_all_vehicle_success = (vehicles) => {
  return {
    type: ActionTypes.VEHICLE_GET_ALL,
    vehicles,
  };
};
