import * as API_CONSTANT from "../constants";

export const getBillingTimeFrameThruUID = ({ uid, startTime, endTime }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/billing/get/timeframe/uid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
      startTime,
      endTime,
    }),
  }).then((res) => res.json());

export const getJournalNoteByJNID = ({ jnid, createdAt }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/jn/get/jnid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({ jnid, createdAt }),
  }).then((res) => res.json());
