import 
    React
from "react"
import { useNavigate } from 'react-router'
import { Image } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

// Constant
import { ROUTE as ROUTE_CONSTANT, ROOT_CONSTANT } from '../../../../constants'

// Components
import { CopyrightFooter, ProgressCircleNumber } from '../../../../components'

/**
 * Redux Actions
 */

// Style
import classStyles from '../../index.module.css'
import './index.css'

const SSOForgotPasswordPending = (props) => {

    const {
        email = "example@ipick.com.my"
    } = props

    const navigate = useNavigate(); 

    return (
        <div className={classStyles.inputForm}>
            <div className={classStyles.tagline}>
                <h4>
                    <strong>Forget Password?</strong>
                    <p style={{marginTop: "20px"}}>Check your email <span className={classStyles.email}>{email}</span> to reset the password.</p>
                </h4>
            </div>

            <div className={classStyles.inputWrap}>
                <div className={classStyles.backtoLOGIN}>
                    <button 
                        className={classStyles.transparentBTN}
                        onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Login)}
                    >
                        <ArrowLeftOutlined /> Back to Login!
                    </button>
                </div>
            </div>

            <div className={classStyles.progressRow}>
                {window.innerWidth <= 1000 &&
                    <Image
                        width={100}
                        preview={false}
                        src={ROOT_CONSTANT.IMG.ICON.IPICK_LOGO} 
                        className={classStyles["logo-mobile"]}
                    />
                }
                <ProgressCircleNumber turn={2} circle={4}/>
            </div>

            <div className={classStyles.footer}>
                <CopyrightFooter />
            </div>
        </div>
    )
}

export default SSOForgotPasswordPending