export const SET_LOADING_GENERAL = "V1_SET_LOADING_GENERAL";
export const UNSET_LOADING_GENERAL = "V1_UNSET_LOADING_GENERAL";

export const SET_LOADING_TRANSACTION_LIST = "V1_SET_LOADING_TRANSACTION_LIST";
export const UNSET_LOADING_TRANSACTION_LIST ="V1_UNSET_LOADING_TRANSACTION_LIST";

export const SET_LOADING_BILLING_LIST = "V1_SET_LOADING_BILLING_LIST";
export const UNSET_LOADING_BILLING_LIST = "V1_UNSET_LOADING_BILLING_LIST";

export const SET_LOADING_ADDRESS_BOOK = "V1_SET_LOADING_ADDRESS_BOOK"
export const UNSET_LOADING_ADDRESS_BOOK = "V1_UNSET_LOADING_ADDRESS_BOOK"

// Order
export const SET_LOADING_ORDER_SUBMIT = "V1_SET_LOADING_ORDER_SUBMIT";
export const UNSET_LOADING_ORDER_SUBMIT = "V1_UNSET_LOADING_ORDER_SUBMIT";

export const SET_LOADING_ORDER_LIST = "V1_SET_LOADING_ORDER_LIST";
export const UNSET_LOADING_ORDER_LIST = "V1_UNSET_LOADING_ORDER_LIST";

export const SET_LOADING_ORDER_LOG = "V1_SET_LOADING_ORDER_LOG";
export const UNSET_LOADING_ORDER_LOG = "V1_UNSET_LOADING_ORDER_LOG";

export const SET_LOADING_ORDER_STATE_PROCEEDING = "V1_SET_LOADING_ORDER_STATE_PROCEEDING";
export const UNSET_LOADING_ORDER_STATE_PROCEEDING = "V1_UNSET_LOADING_ORDER_STATE_PROCEEDING";