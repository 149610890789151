import * as API_CONSTANT from '../constants'

export const getAllHubs = () => (
    fetch(`${API_CONSTANT.cp_v1_fe_path}/hub/get/all`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())
)

export const getAllActiveHubs = () => 
    fetch(`${API_CONSTANT.cp_v1_fe_path}/hub/get/active`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())