import { Menu } from "antd";

const SidebarItems = ({
    theme, 
    selectedkeys,
    onClick,
    items
}) => {
  
  return (
    <Menu
        theme={theme}
        selectedkeys={[selectedkeys]}
        onClick={onClick}
        mode='inline'
        style={{
            // height: "calc(90vh - 68px)", // Minus the logo height
            overflowY: "auto",
            background: "transparent",
            padding: "1em 0"
        }}
        items={items.map(item => ({
            key: item.key,
            type: item.type,
            label: item.label,
            icon: item.icon || <></>,
            disabled: item.disabled || false
        }))}
        trigger={null}
    />  
  );
};

SidebarItems.defaultProps = {
    items: [],
    onClick: () => {},
    theme: "dark",
    collapsed: "true"
};

export default SidebarItems;
