// Components
import { OrderSummary } from "../../../../components";

// Styles
import style from "../../index.module.css";

const OrderSubmissionSummary = ({
    order = {},
    form
}) => {
    return (
        <div className={style["containerSub"]}>
            ORDER SUMMARY
            <h2 className={style["tagline"]}>
                Please make sure your order details are correct before submission.
            </h2>
            <OrderSummary srcData={order} />
      </div>
    )
}

export default OrderSubmissionSummary