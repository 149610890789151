// React Thirty Party Library
import React, { useState, useEffect } from "react";
import { Table, Pagination } from "antd";

// Constants Library
import { ROOT_CONSTANT } from "../../../constants";

// Components Library
import { FilterSelect, FilterDay } from "../../../components/TableList";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import classes from "../index.module.css";

const { icon_filter } = ROOT_CONSTANT.IMG.IMG.TRANSACTION;

const DataList = ({
  dataSource,
  filteredData,
  setFilteredData,
  loading,
  optionDay,
  optionStatus,
  optionType,
  optionAll,
  dataDate,
  placeholder,
  defaultValue,
  text1,
  text2,
  columnData,
  expandedRowRender,
  onChange,
  rowSelection,
}) => {
  //   const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDays, setSelectedDays] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  //   const onSelectRow = (newSelectedRowKeys) => {
  //     setSelectedRowKeys(newSelectedRowKeys);
  //   };

  //   const rowSelection = {
  //     selectedRowKeys,
  //     onChange: onSelectRow,
  //   };

  const handleOptionDays = (value) => {
    setSelectedDays(value);
  };

  const handleOptionStatus = (value) => {
    let selected = value.filter((option) => option !== optionAll);
    setSelectedStatus(selected);
  };

  const handleOptionType = (value) => {
    let selected = value.filter((option) => option !== optionAll);
    setSelectedType(selected);
  };

  const filterData = () => {
    const now = new Date();
    let filtered = dataSource.filter((item) => {
      const transactedAt = new Date(item[dataDate]);
      const diffTime = now - transactedAt;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);

      let daysSelected = selectedDays.length === 0 || diffDays <= selectedDays;

      let statusSelected =
        selectedStatus.length === 0 ||
        selectedStatus.some((option) => {
          const [t, v] = option.split("#");
          return option === "ALL" || item[t] === Number(v) || item[t] === v;
        });

      let typeSelected =
        selectedType.length === 0 ||
        selectedType.some((option) => {
          const [t, v] = option.split("#");
          return option === "ALL" || item[t] === v;
        });

      return daysSelected && statusSelected && typeSelected;
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    filterData();
  }, [selectedDays, selectedStatus, selectedType]);

  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const paginatedData = filteredData.slice(
    (current - 1) * pageSize,
    current * pageSize
  );

  const handlePaginationChange = (page, pageSize) => {
    setCurrent(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <div className={classes.selectFilter}>
        <div>
          <img src={icon_filter} className={classes.filterIcon} alt="" />

          <FilterDay
            placeholder={placeholder}
            defaultValue={defaultValue}
            handleOptionDays={handleOptionDays}
            optionDay={optionDay}
          />

          <FilterSelect
            selection={optionStatus}
            selectedData={selectedStatus}
            onChangeSelect={handleOptionStatus}
            text={text1}
          />

          <FilterSelect
            selection={optionType}
            selectedData={selectedType}
            onChangeSelect={handleOptionType}
            text={text2}
            style={{ width: 200 }}
          />
        </div>

        {/* <div className={classes.pagination}> */}
        <Pagination
          current={current}
          pageSize={pageSize}
          total={filteredData.length}
          onChange={handlePaginationChange}
          showTotal={(total) => (
            <div style={{ fontSize: 13 }}>{total} results </div>
          )}
          showSizeChanger
          size="small"
        />
      </div>
      {/* </div> */}

      <Table
        className={classes.table}
        rowSelection={rowSelection}
        dataSource={paginatedData}
        columns={columnData.map((c) => ({
          ...c,
          textWrap: "word-break",
          ellipsis: true,
        }))}
        pagination={false}
        loading={loading}
        expandable={{
          expandedRowRender,
        }}
        scroll={{
          x: true,
          // y: 500,
        }}
        onChange={onChange}
      />
    </>
  );
};

export default DataList;
