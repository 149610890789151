import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Modal, Select } from "antd";

import { ROUTES, COMMON_FEATURES, TEMPLATE } from "../../../../constants";

import * as REDUX_ACTION from "../../../../services/redux/actions";

import styles from "../common.module.css";

const { MODALS } = TEMPLATE.MODAL;
const { MODULE_ROUTE } = ROUTES;

const ModalNotif = (props) => {
  const {
    setmodal,
    isModalOpen,
    setIsModalOpen,
    onClick = () => {},
    extra,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitButton = () => {
    navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUBMISSION]);
    setIsModalOpen();
  };

  const handleTopupButton = () => {
    navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.TOPUP_DASHBOARD]);
    setIsModalOpen();
  };

  const content =
    (extra && Object.keys(extra).length && MODALS[setmodal].content(extra)) ||
    MODALS[setmodal].content;

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={setIsModalOpen}
      width={500}
    >
      <div className={styles["content-container"]}>
        <div className={styles["contents"]}>
          <div>
            <p className={styles.textCard}>{content}</p>
          </div>

          <div className={styles["buttons"]}>
            <button
              onClick={handleTopupButton}
              className={`${styles["button"]} ${styles["active"]}`}
            >
              Top up now
            </button>
            <button
              onClick={handleSubmitButton}
              className={`${styles["button"]} ${styles["middle"]}`}
            >
              Continue submit order
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNotif;
