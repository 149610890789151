// React Thirty Party Library
import { Card, Col, Row } from "antd";

// Constants Library
// eslint-disable-next-line import/namespace
import { ROOT_CONSTANT } from "../../../../../../../constants";

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import styles from "../../common.module.css";

const { icon_user, icon_parcelChecked, icon_star } =
  ROOT_CONSTANT.IMG.ICON.MY_ACCOUNT;

const ProfileInfo = (props) => {

  const {
    year,
    order_completed,
    memPTS
  } = props

  const profileInfoData = [
    { label: "Customer Since", content: year, img: icon_user },
    { label: "Completed Order", content: order_completed, img: icon_parcelChecked },
    { label: "Total Points", content: memPTS || '-', img: icon_star },
  ];

  return (
    <>
      <h1 style={{ padding: "15px 0", fontSize: "22px" }}>Profile</h1>
      <Row gutter={24}>
        {profileInfoData.map((i, key) => (
          <Col span={8} key={key}>
            <Card
              bordered={false}
              className={styles["card-bg"]}
              bodyStyle={{ padding: "18px" }}
            >
              <div className={styles["card-content"]}>
                <div className={styles["card-lines"]}>
                  <h1 className={styles.statusTitle}>{i.content}</h1>
                  {i.label}
                </div>
                <img
                  src={i.img}
                  className={styles["card-icon-center"]}
                  alt=""
                />
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ProfileInfo;
