import boxIcon from "../../../../../assets/images/box.svg";
import docIcon from "../../../../../assets/images/doc.svg";

import { TIME } from "../../../services/util";

export const DATA_FIELD = {
  deliveryType: "deliveryType",
  add_on: "add_on",
  parcelType: "parcelType",
  parcel_weight: "parcel_weight",
  parcel_length: "parcel_length",
  parcel_width: "parcel_width",
  parcel_height: "parcel_height",
  parcel_desc: "parcel_desc",
  parcel_remark: "parcel_remark",
  origid: "origid",
  etd: "etd",
  sender_name: "sender_name",
  sender_contact: "sender_contact",
  sender_email: "sender_email",
  sender_address: "sender_address",
  sender_postcode: "sender_postcode",
  sender_city: "sender_city",
  sender_state: "sender_state",
  sender_remark: "sender_remark",
  dstid: "dstid",
  dst_detail: "dst_detail",
  orig_detail: "orig_detail",
  eta: "eta",
  receiver_name: "receiver_name",
  receiver_contact: "receiver_contact",
  receiver_email: "receiver_email",
  receiver_address: "receiver_address",
  receiver_postcode: "receiver_postcode",
  receiver_city: "receiver_city",
  receiver_state: "receiver_state",
  receiver_remark: "receiver_remark",
};

export const DATA_FIELD_VALUES = {
  [DATA_FIELD.deliveryType]: {
    h2h: "Hub to Hub",
    h2d: "Hub to Doorstep",
    p2p: "Point to Point",
  },
  [DATA_FIELD.add_on]: {
    "svc#dpu": "Doorstep Pick Up",
  },
  [DATA_FIELD.parcelType]: {
    "001": { src: boxIcon, name: "Box" },
    "002": { src: docIcon, name: "Document" },
  },
};

export const DATA_FIELD_TITLE = {
  [DATA_FIELD.deliveryType]: {
    h2h: "Sender self drop-off at hub and receiver self collect from hub.",
    h2d: "Sender self-drop off at hub and parcel delivery right to your receiver’s doorstep.",
  },
};

export const STATE = {
  JOHOR: {
    name: "Johor",
    code: "JHR",
  },
  KEDAH: {
    name: "Kedah",
    code: "KDH",
  },
  KELANTAN: {
    name: "Kelantan",
    code: "KTN",
  },
  KUALA_LUMPUR: {
    name: "WP Kuala Lumpur",
    code: "KUL",
  },
  SELANGOR: {
    name: "Selangor",
    code: "SGR",
  },
  LABUAN: {
    name: "WP Labuan",
    code: "LBN",
  },
  MELAKA: {
    name: "Melaka",
    code: "MLK",
  },
  NEGERI_SEMBILAN: {
    name: "Negeri Sembilan",
    code: "NSN",
  },
  PAHANG: {
    name: "Pahang",
    code: "PHG",
  },
  PENANG: {
    name: "Penang",
    code: "PNG",
  },
  PERAK: {
    name: "Perak",
    code: "PRK",
  },
  PERLIS: {
    name: "Perlis",
    code: "PLS",
  },
  PURTAJAYA: {
    name: "WP Putrajaya",
    code: "PJY",
  },
  SABAH: {
    name: "Sabah",
    code: "SBH",
  },
  SARAWAK: {
    name: "Sarawak",
    code: "SWK",
  },
  TERENGGANU: {
    name: "Terengganu",
    code: "TRG",
  },
};

export const STATE_EX = {
  OTHERS: {
    name: "Others",
    code: "OTH",
  },
};

export const STATE_WITH_EX = {
  ...STATE,
  ...STATE_EX,
};

export const STATE_NAME = Object.fromEntries(
  Object.keys(STATE_WITH_EX).map((k) => [k, k])
);

export const HUBS = {
  OTHERS: {
    hubid: "hub#others",
    name: "Others",
    code: "OTH",
    biz_hrs: {
      day: "*",
      time: "*",
    },
  },
};

export const HUBS_CODE = (hubs) =>
  Object.fromEntries(Object.keys(hubs).map((k) => [k.code, k.code]));

export const HUBS_HUBID = (hubs) =>
  Object.fromEntries(Object.values(hubs).map((v) => [v.hubid, v]));

export const HUBS_BY_STATE_ORG = {
  [STATE_NAME.JOHOR]: [HUBS_CODE.SPT, HUBS_CODE.JHJ],
  [STATE_NAME.PENANG]: [HUBS_CODE.BTW],
  [STATE_NAME.PERAK]: [HUBS_CODE.AMJ],
  [STATE_NAME.KUALA_LUMPUR]: [HUBS_CODE.TBS, HUBS_CODE.DUT],
};

export const HUBS_BY_STATE = {
  [STATE_NAME.JOHOR]: [HUBS_CODE.SPT, HUBS_CODE.JHJ],
  [STATE_NAME.PENANG]: [HUBS_CODE.BTW],
  [STATE_NAME.PERAK]: [HUBS_CODE.AMJ],
  [STATE_NAME.KUALA_LUMPUR]: [HUBS_CODE.TBS, HUBS_CODE.DUT],
  [STATE_NAME.OTHERS]: [HUBS_CODE.OTHERS],
};

export const SpecialFieldHandler = ["parcel", "sender", "receiver"];

export const PRICING_LIST = {
  columns: [
    {
      title: "L + W + H",
      key: "lwh",
      dataIndex: "lwh",
      align: "center",
    },
    {
      title: "Price (RM)",
      key: "price",
      dataIndex: "price",
      align: "center",
    },
  ],
  data: [
    {
      key: 1,
      lwh: "0 - 120",
      price: "25",
    },
    {
      key: 2,
      lwh: "121 -130",
      price: "35",
    },
    {
      key: 3,
      lwh: "131 - 140",
      price: "55",
    },
    {
      key: 4,
      lwh: "141 - 150",
      price: "75",
    },
    {
      key: 5,
      lwh: "151 - 160",
      price: "95",
    },
  ],
};

export const ORDER_STATUS = {
  SUBMITTED: "O0100",
  PENDING_SELF_LODGE: "O0201",
  PENDING_PICK_UP_FROM_CUSTOMER: "O0202",
  PENDING_SELF_LODGE_POINT: "O1200",
  CHECK_OUT_FROM_POINT: "O0203",
  ARRIVED_AT_ORIGIN_HUB: "O0300",
  ARRIVED_AT_ORIGIN_POINT: "O1300",
  DEPARTED_FROM_ORIGIN_HUB: "O0400",
  PENDING_SELF_COLLECTION: "O0501",
  PENDING_SELF_COLLECTION_POINT: "O1500",
  PENDING_DOORSTEP_DELIVERY: "O0502",
  ARRIVED_AT_DEST_HUB: "O0503",
  PENDING_OUTBOUND_TO_POINT: "O1400",
  FULFILLED: "O0600",
  COMPLETED: "O2000",
  CLOSED: "O4100",
  PARKED: "O4200",
  CANCELLED: "O4300",
};

export const PRICE_CALCULATION_DIMENSION = ({
  length,
  width,
  height,
  weight,
}) => {
  const dimensions = length + width + height;

  let dimensionsPrice = 0;
  let weightPrice = 0;

  if (dimensions <= 120) {
    dimensionsPrice = 19;
  } else if (dimensions <= 130) {
    dimensionsPrice = 29;
  } else if (dimensions <= 135) {
    dimensionsPrice = 49;
  } else if (dimensions <= 140) {
    dimensionsPrice = 69;
  } else if (dimensions <= 145) {
    dimensionsPrice = 79;
  } else if (dimensions <= 150) {
    dimensionsPrice = 89;
  } else if (dimensions <= 155) {
    dimensionsPrice = 109;
  } else if (dimensions > 155) {
    dimensionsPrice = 129;
  }

  // Check the weight of the box
  if (weight > 20) {
    if (weight < 32 && weight > 30) {
      weightPrice = 34;
    } else if (weight > 31 && weight <= 32) {
      weightPrice = 38;
    } else if (weight > 32 && weight <= 33) {
      weightPrice = 42;
    } else if (weight > 33 && weight <= 34) {
      weightPrice = 46;
    } else if (weight > 34 && weight <= 35) {
      weightPrice = 50;
    } else if (weight > 35 && weight <= 36) {
      weightPrice = 54;
    } else if (weight > 36 && weight <= 37) {
      weightPrice = 58;
    } else if (weight > 37 && weight <= 38) {
      weightPrice = 62;
    } else if (weight > 38 && weight <= 39) {
      weightPrice = 66;
    } else if (weight > 39 && weight <= 40) {
      weightPrice = 70;
    }
    // Add the weight charge to the base price
    return dimensionsPrice + weightPrice;
  } else {
    return dimensionsPrice;
  }
};

const PARCEL_FIELDS_NAME = {
  PARCEL_QTY: "qty",
  PARCEL_LENGTH: "length",
  PARCEL_WIDTH: "width",
  PARCEL_HEIGHT: "height",
  PARCEL_WEIGHT: "weight",
};

export const PARCEL_FIELDS = {
  [PARCEL_FIELDS_NAME.PARCEL_QTY]: {
    label: "Parcel Quantity",
    name: PARCEL_FIELDS_NAME.PARCEL_QTY,
  },
  [PARCEL_FIELDS_NAME.PARCEL_LENGTH]: {
    label: "Parcel Length (cm)",
    name: PARCEL_FIELDS_NAME.PARCEL_LENGTH,
  },
  [PARCEL_FIELDS_NAME.PARCEL_WIDTH]: {
    label: "Parcel Width (cm)",
    name: PARCEL_FIELDS_NAME.PARCEL_WIDTH,
  },
  [PARCEL_FIELDS_NAME.PARCEL_HEIGHT]: {
    label: "Parcel Height (cm)",
    name: PARCEL_FIELDS_NAME.PARCEL_HEIGHT,
  },
  [PARCEL_FIELDS_NAME.PARCEL_WEIGHT]: {
    label: "Parcel Weight (kg)",
    name: PARCEL_FIELDS_NAME.PARCEL_WEIGHT,
  },
};

export const inTransit = ["O0203", "O0300", "O0400", "O0503", "O1300", "O1400"];

export const GET_OPERATION_HOURS_ARR = (biz_hrs) => {
  const days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];

  let opHrs = [];

  biz_hrs &&
    days.forEach((day) => {
      const opHr = {
        day,
        hour: biz_hrs.find((bz) => day === TIME.getDayNamefromEpoch(bz.day))
          ?.hour,
      };

      opHrs.push(opHr);
    });

  return opHrs;
};

export const HUB_OPTIONS = (hubsList, allIDs) => {
  const hubMap = {};

  allIDs.forEach((hubid) => {
    const hubState = hubsList[hubid].state;

    if (hubState !== undefined) {
      if (!hubMap[hubState]) {
        hubMap[hubState] = [];
      }

      hubMap[hubState].push({
        label: hubsList[hubid].name,
        value: hubid,
      });
    }
  });

  // OptionGroup -- Main OptionGroup value
  const hubList = Object.keys(hubMap).map((hubState) => ({
    label: hubState,
    options: hubMap[hubState],
  }));

  return hubList;
};

export const PARCELS_TOTAL_CALCULATION = (parcels) => {
  const total_volumes =
    parcels.reduce((total, parcel) => {
      const parcelVolume = parcel.width * parcel.height * parcel.length;
      const parcelTotal = parcelVolume * parcel.qty;

      return total + parcelTotal;
    }, 0) / 1000000 || 0;

  const total_pieces =
    parcels.reduce((total, parcel) => {
      return total + parseInt(parcel.qty);
    }, 0) || 0;

  const total_weights =
    parcels.reduce((total, parcel) => {
      const parcelWeight = parcel.weight * parcel.qty;
      return total + parcelWeight;
    }, 0) || 0;

  return { total_volumes, total_pieces, total_weights };
};
