// React Thirty Party Library
import { useDispatch } from "react-redux";
import { Tag } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

// Constant
import { TEMPLATE, ROOT_CONSTANT } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";

import { filter } from "../../../handlers";

// Service
import { TIME } from "../../../services/util";

const { parseTimeMin, parseTimeMinDay } = TIME;

const { TABLE_ORDERLIST_INDEX, TABLE_ORDERLIST_TITLE } = TEMPLATE.ORDERLIST;
const { inTransit } = TEMPLATE.ORDER;
const { OPTION_STATUS_TITLE } = TEMPLATE.BILLING;
const { icon_active_map, icon_inactive_map } = ROOT_CONSTANT.IMG.ICON;

const { getColumnSearchProps } = filter.searchHDLR;
const { getColumnsFilterStatus, getColumnsFilterHub } = filter.filterHDLR;

export const ORDER_SUMMARY_COL = ({
  redux = {},
  func = {},
  exportState = false,
}) => {
  const { orderSorted, orderStates, orderStatuses, hubs, points, uroleid } =
    redux;

  const { NavigateOrderSummary, navigateMap, SORenavigate, handleSearch } =
    func;

  const baseColumns = [
    {
      title: TABLE_ORDERLIST_TITLE.Order_ID,
      key: TABLE_ORDERLIST_INDEX.Order_ID,
      fixed: "left",

      render: (record) => {
        const { ordid, sorted, deliveryType, add_on } = record;

        const status =
          (orderStates[ordid] && orderStates[ordid].status) || record.status;

        return exportState ? (
          ordid
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>
              {ordid}
            </ButtonLink>

            {((orderSorted.SORTED.includes(ordid) || sorted) && (
              <CheckCircleFilled
                style={{
                  marginLeft: 8,
                  color: "#247418",
                }}
              />
            )) || (
              <CheckCircleFilled
                style={{
                  marginLeft: 8,
                  color: "#9c9c9c",
                }}
              />
            )}
          </div>
        );
      },
      //   ...getColumnSearchProps("ordid", handleSearch),
      sorter: (a, b) => {
        a = parseInt(a.ordid.replace("P", ""));
        b = parseInt(b.ordid.replace("P", ""));

        return a - b;
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Order_Status,
      key: TABLE_ORDERLIST_INDEX.Order_Status,
      fixed: "left",
      render: (rowData) => {
        const { ordid, v_plate } = rowData;
        const status =
          (orderStates[ordid] && orderStates[ordid].status) || rowData.status;
        const statusName =
          orderStatuses[status] && orderStatuses[status].display;
        const colorTag =
          (orderStatuses[status] && orderStatuses[status].colorTag) ||
          "default";

        return exportState ? (
          statusName
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {v_plate && inTransit.includes(status) ? (
              <ButtonLink onClick={() => navigateMap(ordid)}>
                <img
                  src={icon_active_map}
                  alt=""
                  style={{
                    width: "18px",
                    marginRight: "5px",
                    marginTop: "6px",
                  }}
                />
              </ButtonLink>
            ) : (
              <img
                src={icon_inactive_map}
                alt=""
                style={{ width: "18px", marginRight: "5px" }}
              />
            )}
            <Tag color={colorTag} key={status}>
              {statusName}
            </Tag>
          </div>
        );
      },
      filters: getColumnsFilterStatus(orderStatuses),
      onFilter: (value, record) => record.status === value,
    },

    {
      title: TABLE_ORDERLIST_TITLE.Delivery_Instruction,
      key: TABLE_ORDERLIST_INDEX.Delivery_Instruction,
      render: (rowData) => {
        const { deliveryType, origid, dstid, origpid, dstpid, uroleid } =
          rowData;
        const orig = "H" + ((hubs[origid] && hubs[origid].code) || "-");
        const dest = "H" + ((hubs[dstid] && hubs[dstid].code) || "-");
        const origPoint =
          "P" + ((points[origpid] && points[origpid].code) || "-");
        const destPoint =
          "P" + ((points[dstpid] && points[dstpid].code) || "-");

        const route =
          deliveryType !== "p2p"
            ? `${orig}-${dest}`
            : `${origPoint}-${orig}-${dest}-${destPoint}`;
        const type = deliveryType !== "p2p" ? "Hub to Hub" : "Point to Point";
        return exportState ? (
          (deliveryType, route)
        ) : (
          <>
            {/* <p>{DATA_FIELD_VALUES["deliveryType"][deliveryType]}</p> */}
            <p>{type}</p>
            <p>{route}</p>
          </>
        );
      },
    },

    {
      title: TABLE_ORDERLIST_TITLE.customer_instruction,
      // key: TABLE_ORDERLIST_INDEX.Origin,
      render: (rowData) => {
        const { add_on, origid, deliveryType, dstid, origpid, dstpid } =
          rowData;

        return exportState ? null : ( //   "-" //   )) || //     "" //     (acc, cur) => `${acc}, ${cur && DATA_FIELD_VALUES.add_on[cur]}`, //   add_on.reduce( //   add_on.length && // (add_on &&
          <>
            {deliveryType === "p2p" ? (
              <>
                <p>{`Self Lodge At Point ${
                  points[origpid] && points[origpid].code
                }`}</p>
                <p>{`Self Collect At Point ${
                  points[dstpid] && points[dstpid].code
                }`}</p>
              </>
            ) : Array.isArray(add_on) &&
              add_on.length > 0 &&
              add_on.every((value) => value !== null) ? (
              <>
                <p>Doorstep Pickup</p>
                <p>
                  {deliveryType === "h2h"
                    ? `Self Collect At Hub ${hubs[dstid] && hubs[dstid].code}`
                    : "Doorstep Drop Off"}
                </p>
              </>
            ) : (
              <>
                <p>{`Self Lodge At Hub ${
                  hubs[origid] && hubs[origid].code
                }`}</p>
                <p>
                  {deliveryType === "h2h"
                    ? `Self Collect At Hub ${hubs[dstid] && hubs[dstid].code}`
                    : "Doorstep Drop Off"}
                </p>
              </>
            )}
          </>
        );
      },
      // filters: filterHDLR.getColumnsFilterHub(hubs),
      // onFilter: (value, record) => record.origid === value,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Origin,
      key: TABLE_ORDERLIST_INDEX.Origin,
      render: (rowData) => {
        const { origid, etd, deliveryType } = rowData;

        const date = deliveryType === "p2p" ? "-" : parseTimeMinDay(etd) || "-";

        return exportState ? (
          (hubs[origid] && hubs[origid].code) || "-"
        ) : (
          <>
            <p>H{(hubs[origid] && hubs[origid].code) || "-"}</p>
            <p>{date}</p>
          </>
        );
      },
      filters: getColumnsFilterHub(hubs),
      onFilter: (value, record) => record.origid === value,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Destination,
      key: TABLE_ORDERLIST_INDEX.Destination,
      render: (rowData) => {
        const { dstid, eta, deliveryType } = rowData;

        const date = deliveryType === "p2p" ? "-" : parseTimeMinDay(eta) || "-";

        return exportState ? (
          (hubs[dstid] && hubs[dstid].code) || "-"
        ) : (
          <>
            <p>H{(hubs[dstid] && hubs[dstid].code) || "-"}</p>
            <p>{date}</p>
          </>
        );
      },
      filters: getColumnsFilterHub(hubs),
      onFilter: (value, record) => record.dstid === value,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Price,
      key: TABLE_ORDERLIST_INDEX.Price,
      render: (record) => {
        const { price, priceSort } = record;

        return exportState ? (
          `${price} / ${priceSort}`
        ) : (
          <>
            <p>Est: RM {price}</p>
            <p>Sorted: {priceSort && priceSort ? `RM${priceSort}` : ""}</p>
          </>
        );
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Parameter,
      key: TABLE_ORDERLIST_INDEX.parameter,
      render: (rowData) => {
        const { parcel = {}, sorted } = rowData;
        const { sort: newSort = [], sort: oldSort = {}, unsort = [] } = parcel;

        const oldUnsortData = Object.values(parcel);
        const oldSortData = Object.values(oldSort);

        const volume = (par) => {
          return Object.values(par)
            .reduce((sum, p) => {
              const qty = parseFloat(p && p.qty) || 0;
              const length = parseFloat(p && p.length) || 0;
              const width = parseFloat(p && p.width) || 0;
              const height = parseFloat(p && p.height) || 0;

              return sum + qty * ((length * width * height) / 1000000);
            }, 0)
            .toFixed(6);
        };

        const unsortVolumeNew = volume(unsort);
        const unsortVolumeOld = volume(oldUnsortData);

        const estVolume = unsort.length > 0 ? unsortVolumeNew : unsortVolumeOld;
        const volumeEstTotal = estVolume ? `${estVolume}m3` : `${0}m3`;

        const sortVolumeNew = volume(newSort);
        const sortVolumeOld = volume(oldSortData);

        const sortVolume = newSort.length > 0 ? sortVolumeNew : sortVolumeOld;
        const volumeSortTotal = sortVolume ? `${sortVolume}m3` : `${0}m3`;

        const totalWeight = (par) => {
          return Object.values(par)
            .reduce((sum, p) => {
              const qty = parseFloat(p && p.qty) || 0;
              const weight = parseFloat(p && p.weight) || 0;

              return sum + qty * weight;
            }, 0)
            .toFixed(2);
        };

        const unsortWeightNew = totalWeight(unsort);
        const unsortWeightOld = totalWeight(oldUnsortData);

        const estWeight = unsort.length > 0 ? unsortWeightNew : unsortWeightOld;
        const weightEstTotal = estWeight ? `${estWeight}kg` : `${0}kg`;

        const sortWeightNew = totalWeight(newSort);
        const sortWeightOld = totalWeight(oldSortData);

        const sortWeight = newSort.length > 0 ? sortWeightNew : sortWeightOld;
        const weightSortTotal = sortWeight ? `${sortWeight}kg` : `${0}kg`;

        const totalQty = (par) => {
          return Object.values(par).reduce((sum, p) => {
            const qty = parseFloat(p && p.qty) || 0;

            return sum + qty;
          }, 0);
        };

        const sortQty = totalQty(newSort);
        const unsortQuantity = parcel.total_pieces || 1;
        const sortQuantity = parcel.sorted_total_pieces || sortQty;

        const est = `${unsortQuantity}/ ${volumeEstTotal}/ ${weightEstTotal} `;
        const sortData = `${
          sortQuantity || unsortQuantity
        }/ ${volumeSortTotal}/ ${weightSortTotal}`;

        return exportState ? (
          {
            /**/
          }
        ) : (
          <>
            <p>Est: {est}</p>
            {newSort.length > 0 || oldSortData > 0 ? (
              <p>Sorted: {sortData}</p>
            ) : (
              <p>Sorted: </p>
            )}
          </>
        );
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Invoice_Status,
      key: TABLE_ORDERLIST_INDEX.Invoice,
      render: (record) => {
        const { url, jnid, statusBill, refid } = record;

        const { tag, color } = OPTION_STATUS_TITLE[statusBill] || "";

        const handleButtonClick = () => {
          window.open(url && url.jn, "_blank");
        };

        const journal = () => {
          if (
            (url && url.jn === undefined) ||
            (url && url.jn === "") ||
            (url && url.jn === "-")
          ) {
            return <Text disabled>{jnid || refid}</Text>;
          } else {
            return (
              <span>
                <ButtonLink onClick={handleButtonClick}>
                  {jnid || refid}
                </ButtonLink>
                <Tag color={color} key={jnid}>
                  {tag}
                </Tag>
              </span>
            );
          }
        };

        const journalid = journal();

        return exportState ? journalid : <>{journalid}</>;
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Last_Updated,
      dataIndex: TABLE_ORDERLIST_INDEX.Last_Updated,
      key: TABLE_ORDERLIST_INDEX.Last_Updated,
      render: (updatedAt) =>
        exportState ? (
          parseTimeMinDay(updatedAt)
        ) : (
          <>{parseTimeMinDay(updatedAt)}</>
        ),
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: TABLE_ORDERLIST_TITLE.SO_Printed,
      key: TABLE_ORDERLIST_INDEX.SO_Printed,
      render: (rowData) => {
        const { soid, printedAt } = rowData;

        return exportState ? (
          parseTimeMin(printedAt)
        ) : (
          <>
            <ButtonLink onClick={() => SORenavigate(soid)}>{soid}</ButtonLink>
            <p>{parseTimeMin(printedAt)}</p>
          </>
        );
      },
      sorter: (a, b) => a.printedAt - b.printedAt,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Status_Remarks,
      key: TABLE_ORDERLIST_INDEX.Status_Remarks,
      render: (rowData) => {
        const { parcel } = rowData;

        return exportState ? (
          parcel?.remark || "-"
        ) : (
          <>{parcel?.remark || "-"}</>
        );
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Created_At,
      dataIndex: TABLE_ORDERLIST_INDEX.Created_At,
      key: TABLE_ORDERLIST_INDEX.Created_At,
      render: (createdAt) => {
        return exportState ? (
          parseTimeMinDay(createdAt)
        ) : (
          <>{parseTimeMinDay(createdAt)}</>
        );
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];
  if (uroleid === "POP") {
    const originPointColumn =
      // {
      //   title: TABLE_ORDERLIST_TITLE.origin_point,
      //   key: TABLE_ORDERLIST_INDEX.origin_point,
      //   width: 180,
      //   render: (rowData) => {
      //     const { origpid } = rowData;
      //     // const orig = "P" + origpid;
      //     const orig = "P" + points[origpid]?.code || '-';

      //     return exportState ? orig : <>{origpid ? orig : '-'}</>;
      //   },
      //   // filters: getColumnsFilterHub(hubs),
      //   // onFilter: (value, record) => record.origid === value,
      // };
      {
        title: TABLE_ORDERLIST_TITLE.origin_point,
        // key: TABLE_ORDERLIST_INDEX.Origin,
        render: (rowData) => {
          const { origpid, etd, deliveryType } = rowData;

          const origin =
            deliveryType === "p2p"
              ? "P" + (points[origpid] && points[origpid].code)
              : "-";
          const date =
            deliveryType === "p2p" ? TIME.parseTimeMinDayFirst(etd) : "-";

          return exportState ? (
            origin
          ) : (
            <>
              <p>{origin}</p>
              <p>{date}</p>
            </>
          );
        },
      };

    const destPointColumn =
      // {
      //   title: TABLE_ORDERLIST_TITLE.destination_point,
      //   key: TABLE_ORDERLIST_INDEX.destination_point,
      //   width: 180,
      //   render: (rowData) => {
      //     const { dstpid } = rowData;

      //     // const dest = "P" + destpid;
      //     const dest = "P" + points[dstpid]?.code || '-';

      //     return exportState ? dest : <>{dstpid ? dest : '-'}</>;
      //   },
      //   // filters: getColumnsFilterHub(hubs),
      //   // onFilter: (value, record) => record.origid === value,
      // };
      {
        title: TABLE_ORDERLIST_TITLE.destination_point,
        // key: TABLE_ORDERLIST_INDEX.Origin,
        render: (rowData) => {
          const { dstpid, eta, deliveryType } = rowData;

          const dest =
            deliveryType === "p2p"
              ? "P" + (points[dstpid] && points[dstpid].code)
              : "-";
          const date =
            deliveryType === "p2p" ? TIME.parseTimeMinDayFirst(eta) : "-";

          return exportState ? (
            dest
          ) : (
            <>
              <p>{dest}</p>
              <p>{date}</p>
            </>
          );
        },
        // filters: filterHDLR.getColumnsFilterHub(hubs),
        // onFilter: (value, record) => record.origid === value,
      };

    // Insert at index 4
    baseColumns.splice(4, 0, originPointColumn);
    baseColumns.splice(7, 0, destPointColumn);
  }
  return baseColumns;
};
