// Third-party Library
import { useState } from "react";

// Constant
import { TEMPLATE } from "../../../../../../constants";

// Components
import { ButtonLink } from "../../../../../../components";

// Styles
import styles from "../../../../common.module.css";

const { SIDEBAR_MENU } = TEMPLATE.ACCOUNT;

const ContentSidebar = (props) => {
  const { onChangePage } = props;

  const [isNavbarActive, setIsNavbarActive] = useState({
    0: true,
    1: false,
    2: false,
  });

  return (
    <div className={styles["sidebar-nav"]}>
      {SIDEBAR_MENU.map((i, key) => (
        <ButtonLink
          key={key}
          className={`${styles["sidebar-navlink"]} ${
            isNavbarActive[key] && styles["active"]
          }`}
          onClick={() => {
            onChangePage(i.onChangeParam);

            setIsNavbarActive({
              ...key,
              [key]: true,
            });
          }}
        >
          {/* <img src={i.icon} alt="" /> */}
          {i.icon}
          {i.label}
        </ButtonLink>
      ))}
    </div>
  );
};
export default ContentSidebar;
