import { FEATURE_NAME } from "../common/features";

export const MODULE_ROUTE = {
  // Order
  [FEATURE_NAME.ORDER_SUBMISSION]: "/order/submit",
  [FEATURE_NAME.ORDER_LIST]: "/order/list",
  [FEATURE_NAME.ORDER_SUMMARY]: "/order/summary",
  [FEATURE_NAME.ORDER_TIMELINE]: "/order/timeline",
  // Finance
  [FEATURE_NAME.TOPUP_DASHBOARD]: "/topup",
  [FEATURE_NAME.TRANSACTION_LIST]: "/transaction/list",
  [FEATURE_NAME.BILLING_LIST]: "/billing/list",
  // App Info
  [FEATURE_NAME.DASHBOARD]: "/",
  [FEATURE_NAME.ACCOUNT_PROFILE]: "/account",
  [FEATURE_NAME.ACCOUNT_PROFILE_DATA]: "/account/profile",
  [FEATURE_NAME.ACCOUNT_PROFILE_PWD_CHANGE]: "/account/change-pass",
  [FEATURE_NAME.ACCOUNT_PROFILE_ADDRESS_BOOK]: "/account/address-book",
};

export const MODULE_ROUTE_TITLE = {
  // Order
  [FEATURE_NAME.ORDER_SUBMISSION]: "Order List",
  [FEATURE_NAME.ORDER_LIST]: "Order List",
  [FEATURE_NAME.ORDER_SUMMARY]: "Order Summary",
  [FEATURE_NAME.ORDER_TIMELINE]: "Order Timeline",

  // Finance
  [FEATURE_NAME.TOPUP_DASHBOARD]: "Top Up",
  [FEATURE_NAME.TRANSACTION_LIST]: "Transaction List",
  [FEATURE_NAME.BILLING_LIST]: "Billing List",
  // App Info
  [FEATURE_NAME.DASHBOARD]: "Dashboard",
  [FEATURE_NAME.ACCOUNT_PROFILE]: "My Account",
};

export const MODULE_ROUTE_BY_PATH = Object.fromEntries(
  Object.entries(MODULE_ROUTE).map(([key, value]) => [value, key])
);

export const MODULE_ROUTE_FEATURE_TITLE_BY_PATH = Object.fromEntries(
  Object.entries(MODULE_ROUTE).map(([key, value]) => [
    value,
    MODULE_ROUTE_TITLE[key],
  ])
);
