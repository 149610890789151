import * as ActionTypes from "../action-types";

const transaction_defaultState = {
  byID: {},
  allIDs: [],
};

export const transaction = (state = transaction_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_TRANSACTION_TIMEFRAME: {
      const transactions = action.transactions;

      transactions &&
        transactions.length &&
        transactions.map((transaction) => {
          const txnid = transaction.txnid;

          newState.byID[txnid] = transaction;

          if (!newState.allIDs.includes(txnid)) newState.allIDs.push(txnid);

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
