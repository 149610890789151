import { useNavigate } from "react-router";
import { Space, Button } from "antd";
import { useSelector } from "react-redux";

// Constants
import { ROUTES as ROUTE_CONSTANT, COMMON_FEATURES } from "../../constants";

// Components
import AvatarIcon from "../AvatarIcon";
import { ButtonModal } from "../../components";

import { REDUX as REDUX_UTIL } from "../../services/util";

// Styles
import cs from "./index.module.css";

const { MODULE_ROUTE } = ROUTE_CONSTANT;
const { FEATURE_NAME } = COMMON_FEATURES;
const { reduxStoreWrapper } = REDUX_UTIL;

const PageHeaderBar = ({
  title,
  name,
  memid,
  wallet_currency,
  wallet_amount,
}) => {
  const navigate = useNavigate();

  const wallet = useSelector(reduxStoreWrapper("wallet"));
  const user = useSelector(reduxStoreWrapper("user"))
  return (
    <div className={cs["header-container"]}>
      <h1 style={{ fontSize: "20px" }}>{title}</h1>
      <div className={cs["header-content-right"]}>
        {user.paymentModel === 'PM01' ?
        <Button
            type="primary"
            onClick={() =>
              navigate(
                MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUBMISSION]
              )
            }
          >
            Place Order
          </Button> :
        
        (wallet.balance <= 30 ? (
          <ButtonModal setmodal="wallet_notif" model={"default"}>
            Place Order
          </ButtonModal>
        ) : (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUBMISSION]
              )
            }
          >
            Place Order
          </Button>)
        )}

        <Space direction="horizontal" className={cs["topup-container"]}>
          <p className={cs.walletVal}>
            {wallet_currency} {wallet_amount.toFixed(2)}
          </p>
          <Button
            type="primary"
            size="small"
            onClick={() => navigate(MODULE_ROUTE[FEATURE_NAME.TOPUP_DASHBOARD])}
          >
            + Top Up
          </Button>
        </Space>
        <div className={cs["profile-container"]}>
          <Space>
            <AvatarIcon />
            <Space.Compact direction="vertical">
              <p className={cs.name}>{name}</p>
              <p className={cs.id}>{memid}</p>
            </Space.Compact>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default PageHeaderBar;
