import * as ActionTypes from "../action-types";

// Constants & Config

// Services

const STYLES_INITIAL_STATE = {
  loading: {
    general: false,
    order_submit: false,
    order_list: false,
    order_state_proceeding: false,
    orderLog: false,
    transaction_list: false,
    billing_list: false,
    address_book: false
  },
};

export const style = (state = STYLES_INITIAL_STATE, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SET_LOADING_GENERAL: {
      newState.loading.general = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GENERAL: {
      newState.loading.general = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_SUBMIT: {
      newState.loading.order_submit = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_SUBMIT: {
      newState.loading.order_submit = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_TRANSACTION_LIST: {
      newState.loading.transaction_list = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_TRANSACTION_LIST: {
      newState.loading.transaction_list = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_BILLING_LIST: {
      newState.loading.billing_list = true;
      return newState;
    }

    case ActionTypes.SET_LOADING_ADDRESS_BOOK: {
      newState.loading.address_book = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ADDRESS_BOOK: {
      newState.loading.address_book = false;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_BILLING_LIST: {
      newState.loading.billing_list = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_LIST: {
      newState.loading.order_list = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_LIST: {
      newState.loading.order_list = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_LOG: {
      newState.loading.orderLog = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_LOG: {
      newState.loading.orderLog = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_STATE_PROCEEDING: {
      newState.loading.order_state_proceeding = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_STATE_PROCEEDING: {
      newState.loading.order_state_proceeding = false;
      return newState;
    }

    default: {
      return state;
    }
  }
};
