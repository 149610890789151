import 
    React
from "react"
import {
    useDispatch
} from 'react-redux'
import { useNavigate } from 'react-router'
import { 
    Form, 
    Input, 
    Button
    // message 
} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

// Constant
import { ROUTE as ROUTE_CONSTANT, ROOT_CONSTANT } from '../../../../constants'

// Components
import { CopyrightFooter, ProgressCircleNumber } from '../../../../components'

/**
 * Redux Actions
 */
import * as ACTIONS from '../../../../services/redux/actions';

// Style
import classStyles from '../../../index.module.css'
import './index.css'

const SSOForgotPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [form] = Form.useForm();

    const onFinish = (e) => {
        const {
            email
        } = e

        dispatch(ACTIONS.main_user.sso_user_auth_rp_request(email))
        navigate(ROUTE_CONSTANT.MODULE_ROUTE.ResetCredential_Forgot_Password_Pending, {state:{email}})
    }

    const validateMessages = {
        required: '${name} is required!'
    }

    return (
        <div className={classStyles.inputForm}>
            {window.innerWidth <= 1000 &&
                <div className={classStyles.progress}>
                    <ProgressCircleNumber turn={1} circles={4}/>
                </div>
            }
            <div className={classStyles.tagline}>
                <h4>
                    <strong>Forget Password?</strong>
                </h4>
                <p style={{marginTop: "20px"}}>Enter your email associated with your account and we will send a link to reset your password.</p>
            </div>

            <Form 
                form={form}
                name="form_login" 
                layout="vertical" 
                validateMessages={validateMessages}
                onFinish={onFinish}
            >
                <div className={classStyles.inputWrap}>
                    <Form.Item
                        name="email"
                        label={<span className={classStyles.inputLabel}>Email</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Email is required',
                                type: "email"
                            }
                        ]}
                        hasFeedback
                    >
                        <Input size="large"/>
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button className={classStyles.inputButton} type="primary" htmlType="submit">
                        Continue
                    </Button>
                </Form.Item>
            </Form>
                    
            <div className={classStyles.backtoLOGIN}>
                <Button 
                    className={classStyles.transparentBTN}
                    onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Login)}
                >
                    <ArrowLeftOutlined /> Back to Login!
                </Button>
            </div>

            <div className={classStyles.footer}>
                <CopyrightFooter/>
            </div>
            <br /><br />
        </div>
    )
}

export default SSOForgotPassword