import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton, Result } from 'antd'

// Constant
import { ROOT_CONSTANT } from '../../../../../../constants'

// Service
import { REDUX } from '../../../../../../services/util'

// Styles
import cs from '../../index.module.css'

const {
    ORDER_BOX_ICON
} = ROOT_CONSTANT.IMG.ICON

const {
    reduxStoreWrapper
} = REDUX

const OrderSubmitted = ({
    orderCb
}) => {

    const { msg = 'not submitted', status = '', ordid = '' } = orderCb

    const loading = useSelector(reduxStoreWrapper('style.loading.order_submit'))

    return (
        <div className={cs.container}>
            <Skeleton loading={loading} active>
                {
                    status === 200 ?
                        (
                            <div className={cs.OSContainer}>
                                <img src={ORDER_BOX_ICON} className={cs.icon} alt={'order-submitted-box'} />
                                <h4 className={cs.strong}>Your Order Is Submitted!</h4>
                                <div className={cs.desc}>Thank you for submitting an order to IPICK. Your order ID is  <span className={cs.ordid}>{ordid}</span>. Our customer service will contact you shortly to sort the order for you. </div>
                            </div>
                        )
                    :
                        (
                            <Result
                                status="error"
                                title={`Submission Failed. Error Code: ${status}`}
                                subTitle={`Please check and modify the following information before resubmitting. Message: ${msg}`}
                            />
                        )
                } 
            </Skeleton>
        </div>
    )
}

export default OrderSubmitted