import * as ActionTypes from "../action-types";

const journalNote_defaultState = {
  byID: {},
};

export const journalNote = (state = journalNote_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_JOURNAL_NOTE_BY_ID: {
      const journalNote = action.journalNotes;

      journalNote &&
        journalNote.length &&
        journalNote.map((jn) => {
          const { jnid } = jn;

          newState.byID[jnid] = jn;

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
