import * as ActionTypes from "../action-types";

const wallet_defaultState = {
  currency: "MYR",
  balance: 0,
};

export const wallet = (state = wallet_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_USER_WALLET: {
      const wallets = action.wallets;

      wallets &&
        wallets.length &&
        wallets.map((wallet) => {
          newState = wallet;

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
