import { useNavigate } from 'react-router'
import logoWhite from "../../assets/images/logo-white.svg";
import logoColor from "../../assets/images/logo-color.svg"

// Config
import { APP_INFO } from '../../config'

// Constant
import { ROUTE, IMG } from '../../constants'

// Styles
import classes from "./index.module.css";

const HeaderBar = () => {

  const navigate = useNavigate()
  const logo = window.innerWidth <= 1000 ? logoColor : logoWhite

  return (
    <div className={classes.navbar}>
      <img src={logo} className={classes["icon-img"]} alt="" />
      <p
        role="presentation"
        className={classes.tagline}
        onClick={() => navigate(ROUTE.MODULE_ROUTE.Landing_Page)}
      >
        Customer Portal <span className={classes.version}>v {APP_INFO.APP_VERSION}</span>
      </p>
    </div>
  );
}

export default HeaderBar