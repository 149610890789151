import { useDispatch } from "react-redux";
import { List, Tag } from "antd";

import { ButtonModal } from "../../../../../../../../../components";

import * as REDUX_ACTION from "../../../../../../../../../services/redux/actions";

import style from "../../../../../../../common.module.css";

const SRList = ({
  srData,
  srType,
  icon_address_edit,
  icon_address_del,
  setEditTrue,
  setEditForm,
}) => {
  const dispatch = useDispatch();

  const handleGotoForm = (sr) => {
    setEditTrue();
    setEditForm(sr);
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={srData}
      renderItem={(sr, i) => (
        <List.Item
          actions={[
            <input
              type="image"
              src={icon_address_edit}
              alt=""
              onClick={() => handleGotoForm(sr)}
            />,
            <ButtonModal
              model={"link"}
              setmodal="deletePerson"
              personrole={srType}
              personname={sr.name}
              className={style["reset-button"]}
              onClick={() => {
                dispatch(REDUX_ACTION.v1_address_book.archive_address_book(sr));
              }}
            >
              <img src={icon_address_del} alt="" />
            </ButtonModal>,
          ]}
        >
          <List.Item.Meta
            title={
              <>
                {sr.name}{" "}
                {(sr.default && <Tag color={"orange"}>Default</Tag>) || <></>}
              </>
            }
            description={
              <>
                {sr.contact} | {sr.email}
                <p>
                  {sr.address}, {sr.postcode} {sr.city}, {sr.state}
                </p>
              </>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default SRList;
