// React Thirty Party Library
import React from "react";
import { useNavigate } from "react-router";

// Constants Library
import {
  ROUTES,
  COMMON_FEATURES,
} from "../../../../constants";

// Components Library
import { ButtonLink, BankInformation } from "../../../../components";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import styles from "../common.module.css";

const { TOPUP_DASHBOARD } = COMMON_FEATURES.FEATURE_NAME;
const { MODULE_ROUTE } = ROUTES;

const TopUpInformation = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.containerTopUpInformation}>
        <div
            style={{
            display: "flex",
            justifyContent: "space-between",
            }}
        >
            <h2 className={styles["label-title"]}>Top Up Information</h2>
            <ButtonLink
                onClick={() => navigate(MODULE_ROUTE[TOPUP_DASHBOARD])}
                style={{ alignSelf: "center" }}
            >
                How to top up?
            </ButtonLink>
        </div>
      <BankInformation 
        style={{ 
            backgroundColor: "#FBFAFF" 
        }}
      />
    </div>
  );
};

export default TopUpInformation;
