/**
 * App Info
 */
export const APP_INFO = {
  APP_VERSION: process.env.REACT_APP_PROJECT_VERSION,
  APP_MODE: process.env.REACT_APP_PROJECT_MODE,
  APP_MAINTENANCE_MODE: process.env.REACT_APP_PROJECT_MAINTENANCE_MODE,
  APP_PLATFORM: process.env.REACT_APP_PLATFORM,
  APP_PLATFORM_TYPE: process.env.REACT_APP_PLATFORM_TYPE,
  COPYRIGHT_YEAR: process.env.REACT_APP_COPYRIGHT_YEAR,
  PROJECT_NAME: process.env.REACT_APP_PROJECT_NAME,
  PUBLICATION_COMPANY: process.env.REACT_APP_PUBLICATION_COMPANY,
  ASSET: {
    HUB_PRICE_LIST: process.env.REACT_APP_FILE_HUB_PRICE_LIST,
    WHATSAPP_NUMBER: process.env.REACT_APP_MEDIA_WA_CHANNEL,
  },
};

const AWS_AMPLIFY_EXTRA = JSON.parse(
  process.env.REACT_APP_AWS_AMPLIFY_CONFIG_EXTRA
);

/**
 * App Config
 */
export const APP_CONFIG = {
  APP_MASTER_KEY: process.env.REACT_APP_MASTER_KEY,
  AWS_AMPLIFY: JSON.parse(process.env.REACT_APP_AWS_AMPLIFY_CONFIG),
  AWS_AMPLIFY_EXTRA: AWS_AMPLIFY_EXTRA,
  FIREBASE: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG),
  API_KEY: {
    GOOGLE_MAP: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  },
  AUTHENTICATION_PROVIDER: process.env.REACT_APP_AUTHENTICATION_PROVIDER,
  CP_V1_SSO_API_GATEWAY: process.env.REACT_APP_CP_V1_SSO_API_GATEWAY,
  CP_V1_FE_API_GATEWAY: process.env.REACT_APP_CP_V1_FE_API_GATEWAY,
  CP_V1_FE_WS_GATEWAY: process.env.REACT_APP_CP_V1_FE_WS_GATEWAY,
  CP_V1_FE_WS_GATEWAY_RECONNECTION_INTERVAL:
    process.env.REACT_APP_CP_V1_FE_WS_GATEWAY_RECONNECTION_INTERVAL,
  AWS_SDK_CONFIG: JSON.parse(process.env.REACT_APP_AWS_SDK_CONFIG),
  AWS_URI: JSON.parse(process.env.REACT_APP_AWS_URI),
  PT_URL: process.env.REACT_APP_PT_URL,
};
