// React Thirty Party Library
import React from "react";
import { useSelector } from 'react-redux'
import { Card } from "antd";

// Constants Library
import { ROOT_CONSTANT } from "../../../../constants";

// Components Library

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from '../../../../services/util'

// Redux Actions

// Styles Library
import styles from "../../common.module.css";

const { img_wallet_bg } = ROOT_CONSTANT.IMG.IMG.TOPUP;

const {
  reduxStoreWrapper
} = REDUX_UTIL

const WalletAmountInfo = () => {

  const wallet = useSelector(reduxStoreWrapper("wallet"))

  return (
    <>
      <Card className={styles.myWallet}>
        <div className={styles.myWalletContent}>
          <h2>My Wallet</h2>
          <h2>
            {wallet.currency} <strong style={{ fontSize: "36px" }}>{wallet.amount}</strong>
          </h2>
        </div>
        <img src={img_wallet_bg} alt="" className={styles.walletBg} />
      </Card>
    </>
  );
};

export default WalletAmountInfo;
