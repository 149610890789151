// Third Party Library
import { Modal, DatePicker, Input } from "antd";

// Components
import StatusUpdateAttachment from "./StatusUpdateAttachment";

// Constants
import { TEMPLATE } from "../../../../constants";

// Services
import { TIME } from "../../../../services/util";

import "./index.css";
import classes from "../ModalStatusUpdate/";

const { MODALS } = TEMPLATE.MODAL;

const { dayJS } = TIME;

const ModalStatusUpdate = ({
  setmodal,
  isModalOpen,
  setIsModalOpen,
  extra,
  data,
}) => {
  const content =
    MODALS[setmodal] !== MODALS.wallet_notif
      ? (extra &&
          Object.keys(extra).length &&
          MODALS[setmodal].content &&
          MODALS[setmodal].content(extra)) ||
        (MODALS[setmodal].content && MODALS[setmodal].content)
      : MODALS[setmodal].content;

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      width={450}
    >
      <div className="content-container">
        <img src={MODALS[setmodal].img} alt="" />
        <h3>{MODALS[setmodal].title}</h3>
        <br />
        <p className={classes.textCard}>{content}</p>
      </div>

      <div className="inputs">
        <Input
          name="updatedAt"
          style={{
            width: "100%",
            marginBottom: "20px",
            color: "black",
            backgroundColor: "white",
          }}
          defaultValue={
            data && data.updatedAt ? dayJS(data && data.updatedAt) : null
          }
          disabled
        />
        <Input.TextArea
          name="remark"
          autoSize={{
            minRows: 3,
            maxRows: 4,
          }}
          defaultValue={data && data.remark ? data && data.remark : null}
          disabled
          style={{ color: "black", backgroundColor: "white" }}
        />
        <div className="textarea">
          <Input.TextArea
            name="remark"
            autoSize={{
              minRows: 6,
              maxRows: 10,
            }}
            disabled
          />
          <StatusUpdateAttachment data={data} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalStatusUpdate;
