import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions

export const get_journal_note_by_jnid = (refid, createdNote, cb) => (dispatch) => {
  API.getJournalNoteByJNID({ jnid: refid, createdAt: createdNote }).then(
    (info) => {
      const { status, journalNote, msg } = info;

      if (status === 200 && journalNote) {
        window.open(journalNote.url.jn, "_blank");
        dispatch(get_journal_note_by_jnid_success(journalNote));
      } else {
        message.warning(msg);
      }
    }
  ).finally(() => {
    cb();
  });
};

const get_journal_note_by_jnid_success = (journalNotes) => {
  return {
    type: ActionTypes.GET_JOURNAL_NOTE_BY_ID,
    journalNotes,
  };
};
