import { useNavigate } from 'react-router'
import { Button } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'

// Constant
import { ROUTE as ROUTE_CONSTANT } from '../../constants'

// Components
import { ButtonLink, CopyrightFooter } from '../../components'

// Style
import classStyles from '../index.module.css'

const SSOSignUpSuccess = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className={classStyles.inputForm}>
                {window.innerWidth <= 1000 &&
                <div className={classStyles.progress}>
                    <ProgressCircleNumber turn={3} circles={3}/>
                </div>
                }
                <div className={classStyles.tagline}>
                    <h4>
                        <strong>Account Created!</strong>
                        <CheckCircleFilled className={classStyles.checkedIcon}/>
                        <p style={{marginTop: "20px", lineHeight: "25px", fontWeight: "400"}}>Your account has been created. Login now to place your first order with iPick.</p>
                    </h4>
                </div>
                <div style={{height: "45vh"}} />
                <Button 
                    className={classStyles.inputButton} type="primary"
                    onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Landing_Page)}
                >
                    Completed
                </Button>

                <div className={classStyles.footer}>
                    <CopyrightFooter />
                </div>
            </div>
        </>
    )
}

export default SSOSignUpSuccess