import { Tabs } from "antd";

// Constant
import { ROOT_CONSTANT, TEMPLATE } from "../../../../../../../constants";

// Components
import { ButtonLink } from "../../../../../../../components";
import SRList from "./components/SRList";
import { useState } from "react";

const { ADDRB_TYPE, ADDRB_TYPE_CODE } = TEMPLATE.ADDRESS_BOOK;

const AddressBookList = ({
  setAddTrue,
  setEditTrue,
  setEditForm,
  dataSource,
  setType,
  type
}) => {
  const AddrBFilter = (dataSrc, selectedADDRT) =>
    dataSrc.filter((data) => data && Number(data.type) === selectedADDRT);

  const SenderADDRBList = AddrBFilter(
    dataSource,
    ADDRB_TYPE_CODE[ADDRB_TYPE.SENDER]
  );
  const ReceiverADDRBList = AddrBFilter(
    dataSource,
    ADDRB_TYPE_CODE[ADDRB_TYPE.RECEIVER]
  );

  const items = [
    {
      key: 1,
      label: `Sender (${SenderADDRBList.length})`,
      children: (
        <SRList
          srData={SenderADDRBList}
          srType={"Sender"}
          setEditTrue={setEditTrue}
          setEditForm={setEditForm}
          {...ROOT_CONSTANT.IMG.ICON.MY_ACCOUNT}
        />
      ),
    },
    {
      key: 2,
      label: `Receiver (${ReceiverADDRBList.length})`,
      children: (
        <SRList
          srData={ReceiverADDRBList}
          srType={"Receiver"}
          setEditTrue={setEditTrue}
          setEditForm={setEditForm}
          {...ROOT_CONSTANT.IMG.ICON.MY_ACCOUNT}
        />
      ),
    },
  ];

  const operations = (
    <>
      <ButtonLink onClick={setAddTrue} style={{ color: "#ff5f1f" }}>
        + Add
      </ButtonLink>
    </>
  );

  return (
    <Tabs
      tabBarExtraContent={operations}
      defaultActiveKey={type}
      items={items}
      onChange={e => setType(e)}
      style={{ fontSize: "16px" }}
    />
  );
};

export default AddressBookList;
