// Third Party Lib
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";

// Constant
import { TEMPLATE } from "../../../../../../../constants";

// Components
import { AddEditFormAction, AddEditFormContent } from "./components";

// Service
import { REDUX as REDUX_UTIL } from "../../../../../../../services/util";

// Redux Action
import * as REDUX_ACTION from "../../../../../../../services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ADDRB_TYPE_THRU_CODE } = TEMPLATE.ADDRESS_BOOK;

const AddEditAddressBookForm = ({
  setEditFalse,
  setAddFalse,
  edit,
  editForm,
  add,
  type,
  setType,
  messageApi
}) => {
  const [form] = Form.useForm();
//   const [ messageApi, contextHolder ] = message.useMessage()
  const dispatch = useDispatch();

  const defaultAddrB = useSelector(reduxStoreWrapper("addressBook.byDefault"));
  const addrBs = useSelector(reduxStoreWrapper("addressBook.byID"));
  const uid = useSelector(reduxStoreWrapper("user.uid"));

  const [forms, setForms] = useState({
    uid,
    name: "",
    type,
    contact: "",
    email: "",
    address: "",
    postcode: "",
    city: "",
    state: "",
    default: false,
  });

  useEffect(() => {
    if (edit && editForm) {
      setForms(editForm);
    }
  }, [edit, editForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForms((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addrbid_id = forms.addrbid;

  const reduxValidatedDispatch = () => {
    const { type } = forms;
    const _default = forms.default;

    if (type > 0 && _default) {
      const preDefaultADDRID = defaultAddrB[ADDRB_TYPE_THRU_CODE[type]];

    //   if (preDefaultADDRID !== addrbid_id) {
      if (preDefaultADDRID !== addrbid_id && preDefaultADDRID !== '') {
        dispatch(
          REDUX_ACTION.v1_address_book.update_address_book_request({
            uid,
            addrbid: preDefaultADDRID,
            addrb: {
              ...addrBs[preDefaultADDRID],
              default: false,
            },
          })
        );
      }
    }

    messageApi.open({
        type: "loading",
        content: `${edit ? 'Updating' : 'Adding'} the address book...`,
        duration: 0,
    });

    if (edit) {
      dispatch(
        REDUX_ACTION.v1_address_book.update_address_book_request({
          uid,
          addrbid: addrbid_id,
          addrb: forms,
        }, () => messageApi.destroy())
      );
      setEditFalse();
    } 
    else {
      dispatch(
        REDUX_ACTION.v1_address_book.add_address_book_request({
          addrb: forms,
          uid,
        }, () => messageApi.destroy())
      );
      setAddFalse();
    }
    
  };

  const handleSubmit = async () => {

    await form.validateFields().then(() => reduxValidatedDispatch());
  };

  const validateMessages = {
    required: "${name} is required!",
  };

  return (
    <>
      <AddEditFormAction
        name="AddEditFormAction"
        handleSubmit={handleSubmit}
        setEditFalse={setEditFalse}
        setAddFalse={setAddFalse}
        setType={setType}
        edit={edit}
      />
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onSubmitCapture={() => {}}
        initialValues={add ? {contactPrefix: '+60', type} : editForm}
        validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <AddEditFormContent
          form={form}
          type={type}
          setType={setType}
          name="AddEditFormContent"
          forms={forms}
          setForms={setForms}
          handleChange={handleChange}
        />
      </Form>
    </>
  );
};

export default AddEditAddressBookForm;
