// Components
// import { ButtonLink } from "../../../../../../components";
import AvatarIcon from "../../../../../../components/AvatarIcon";

// Styles
import styles from "../../../../common.module.css";

const HeaderSidebar = ({
    name, 
    memid, 
}) => {

  return (
    <div className={styles["profile-container"]}>
        <AvatarIcon />
        <br />
        <h1 >{name}</h1>
        <p >{memid}</p>
        {/* <ButtonLink>Change Picture</ButtonLink> */}
    </div>
  );
};

export default HeaderSidebar;
