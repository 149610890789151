import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config

// Services

// Redux Action
import {
    setLoadingOrderLog,
    unsetLoadingOrderLog
 } from './style'

// Neccessary Actions

export const get_order_logs_thru_ordid_request = (ordid) => dispatch => {
    dispatch(setLoadingOrderLog())

    API
    .getOrderLogsByORDID({ordid})
    .then(info => {

        const {
            status,
            orderLogs,
            msg
        } = info

        if(status === 200) {
            dispatch(get_order_logs_thru_ordid_success(ordid, orderLogs))
        } else {
            message.error(msg)
        }

        dispatch(unsetLoadingOrderLog())

    })
    .catch(() => {
        dispatch(unsetLoadingOrderLog())
    })
    .finally(() => {
        dispatch(unsetLoadingOrderLog())
    })
}

const get_order_logs_thru_ordid_success = (ordid, orderLogs) => {
    return {
        type: ActionTypes.ORDER_LOG_GET_THRU_ORDID,
        ordid,
        orderLogs
    };
}