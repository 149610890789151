// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE } from "../../../../constants";
import { IMG } from "../../../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../components";

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions

// Styles Library
// Styles Library
import classes from "../../../../components/index.module.css";

const { TABLE_TRANSACTION_INDEX, TABLE_TRANSACTION_TITLE } =
  TEMPLATE.TRANSACTION;
const { icon_export } = IMG.IMG.TRANSACTION;
const { TRANSACTION_SUMMARY_COL } = TEMPLATE.REPORT.TXN;

const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;

const SearchTransaction = ({ setFilteredData, dataSource, filteredData, selectedRowKeys }) => {
  const billing = Object.values(useSelector(reduxStoreWrapper("billing.byID")));
  const transactions = useSelector(reduxStoreWrapper('transaction.byID'))

    // selected data used for exporting selected order
    const selectedData = selectedRowKeys?.length && selectedRowKeys.map(key => ({
    key,
    ...transactions[key]
    })) || Object.values(transactions)

  const template = TRANSACTION_SUMMARY_COL({
    redux: { billing },
    exportState: true,
  });

  const options = [
    {
      value: TABLE_TRANSACTION_INDEX.Transaction_Id,
      label: TABLE_TRANSACTION_TITLE.Transaction_Id,
      type: "text",
    },
    {
      value: TABLE_TRANSACTION_INDEX.Billing_ID,
      label: TABLE_TRANSACTION_TITLE.Billing_ID,
      type: "text",
    },
    {
      value: TABLE_TRANSACTION_INDEX.Transacted_At,
      label: TABLE_TRANSACTION_TITLE.Transacted_At,
      type: "timeframe",
    },
  ];

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_TRANSACTION_INDEX.Transaction_Id}
      />
      <div>
        <BtnSubmit
          display="inline-block"
          text="Export"
          bgColor="transparent"
          color="#4CAF50"
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: selectedData,
              filename: `TransactionSummary_${parseExportTime()}`,
              template: template,
            })
          }
          showIcon={true}
          icon={icon_export}
          icon_alt={"export"}
        />
      </div>
    </div>
  );
};

export default SearchTransaction;
