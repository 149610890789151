import React from "react";
import { 
  Layout 
} from "antd";

// Constants & Config
import * as CONFIG from '../../../config'
import {
  COMMON_FEATURES,
} from '../../../constants'

// Componets
import Logo from "./Logo";
import SidebarItems from "./SidebarItems";

// Styles
import "./index.css";

const { Sider } = Layout;

const Sidebar = props => {
  return (
    <Sider
        collapsed = {props.collapsed}
        style = {{
            height: "100vh",
            position: "fixed",
            backgroundColor: props.themeColor
        }}
        width={"15vw"}
    >
        <Logo 
            pKey={COMMON_FEATURES.FEATURE_NAME.DASHBOARD} 
            onClick = {() => props.onClick({
                key:COMMON_FEATURES.FEATURE_NAME.DASHBOARD
            })} 
            selectedkeys = {props.selectedkeys} 
        />
        <SidebarItems
            onClick = {props.onClick}
            theme = {props.theme}
            collapsed = {props.collapsed}
            items = {props.items}
            selectedkeys = {props.selectedkeys.toString()}
        />
        <div className="version">
            V {CONFIG.APP_INFO.APP_VERSION}
        </div>
    </Sider>
  );
};

Sidebar.defaultProps = {
  collapsed: "true",
  items: [],
  onClick: () => {}
};

export default Sidebar;
