import { Modal, Row, Col } from "antd";

import { ROOT_CONSTANT } from "../../../../constants";
import { TIME } from "../../../../services/util";

import styles from "./common.module.css";

const { parseTimeMin } = TIME

const PopupBusInfo = (props) => {
  const { isModalOpen, setIsModalOpen, busInfoData } = props;

  const { ordid, busCode, busName, tripManifestId, manifestStatus, etd, eta } =
    busInfoData;

  const { icon_bus } = ROOT_CONSTANT.IMG.ICON.MODAL;

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={setIsModalOpen}
      width={550}
    >
      <div className={styles["content-container"]}>
        <h1 className={styles["label-title"]}>
          <img src={icon_bus} alt="" />
          <strong>{busCode}</strong> ({busName})
        </h1>
        <p className={styles["grey-label"]}>
          Below is the bus information for your order {ordid}:
        </p>
        <p className={styles["grey-label"]}>TRIP MANIFEST</p>
        <Row>
          <Col span={13}>
            <label htmlFor="tripManifestId">Trip Manifest ID</label>
          </Col>
          <Col span={11}>{tripManifestId}</Col>
        </Row>
        <Row>
          <Col span={13}>
            <label htmlFor="manifestStatus">Manifest Status</label>
          </Col>
          <Col span={11}>{manifestStatus}</Col>
        </Row>
        <Row>
          <Col span={13}>
            <label htmlFor="atd">(ATD) Actual Departure Date</label>
          </Col>
          <Col span={11}>{parseTimeMin(etd)}</Col>
        </Row>
        <Row>
          <Col span={13}>
            <label htmlFor="eta">(ETA) Estimated Arrival Time</label>
          </Col>
          <Col span={11}>{parseTimeMin(eta)}</Col>
        </Row>
        <br />
        <div className={styles["buttons"]}>
          <button className={styles["button"]} onClick={setIsModalOpen}>Cancel</button>
          <button className={`${styles["button"]} ${styles["active"]}`}>
            Copy
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default PopupBusInfo;
