import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space} from 'antd';
import React, { useRef, useState } from 'react';


// export const getColumnSearchProps = (dataIndex, handleSearch) => {
export const getColumnSearchProps = (name, handleSearch) => {
//   const searchInput = useRef(null);

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
        //   ref={searchInput}
          placeholder={"Search"} 
        //   value={selectedKeys[0]}
        //   onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        //   onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        onChange={(e) => handleSearch(name, e.target.value)}
          onPressEnter={(e) => handleSearch(name, e.target.value)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          {/* <Button
            type="primary"
            // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onClick={(e) => handleSearch(name, e)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button> */}
          <Button type="link" size="small" style={{ marginLeft: '6vw'}} onClick={() => confirm()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) => handleFilter(value, record), 
    // onFilterDropdownOpenChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => searchInput.current?.select(), 100);
    //   }
    // },
  };
};


