import * as ActionTypes from "../action-types";
import * as API from "../../api";

import { message } from "antd";

// Constants and Config

// Services

// Redux Action
import { setLoadingAddressBook, unsetLoadingAddressBook } from "./style";

// Neccessary Actions

export const get_address_book_thru_uid_request = (uid) => (dispatch) => {
    dispatch(setLoadingAddressBook())
  API.getAddressBookThruUID({
    uid,
  }).then((info) => {
    const { status, addrb, msg } = info;

    if (status === 200) {
      dispatch(get_address_book_thru_uid_success(addrb));
    } else {
      message.warning(msg);
    }
  }).finally(() => dispatch(unsetLoadingAddressBook()))
};

export const add_address_book_request =
  ({ addrb, uid }, cb = () => {}) =>
  (dispatch) => {
    API.addAddressBookThruUID({ addrb: addrb, uid: uid }).then((info) => {
      const { status, addrb, msg } = info;
      if (status === 200) {
        dispatch(add_address_book_success(addrb));
        message.success("succesfully add to address books");
      } else {
        message.warning(msg);
      }
    })
    .finally(() => cb())
  };

export const update_address_book_request =
  ({ uid, addrbid, addrb }, cb = () => {}) =>
  (dispatch) => {
    API.updateAddressBook({
      uid: uid,
      addrbid: addrbid,
      items: addrb,
    }).then((info) => {
      const { status, msg } = info;

      if (status === 200) {
        dispatch(update_address_book_thru_uid_success(addrbid, addrb));
        message.success("succesfully update the address book");
      } else {
        message.warning(msg);
      }
    })
    .finally(() => cb())
  };

const get_address_book_thru_uid_success = (addrb) => {
  return {
    type: ActionTypes.GET_ADDRESS_BOOK_LIST,
    addrb,
  };
};

const add_address_book_success = (addrb, addrbid) => {
  return {
    type: ActionTypes.ADD_ADDRESS_BOOK,
    addrb,
    addrbid,
  };
};

const update_address_book_thru_uid_success = (addrbid, addrb) => {
  return {
    type: ActionTypes.EDIT_ADDRESS_BOOK_BY_ID,
    addrbid,
    addrb,
  };
};

export const archive_address_book = (items) => (dispatch) => {
  const itemAddr = { ...items, activeStatus: 0 };
  API.updateAddressBook({
    uid: items.uid,
    addrbid: items.addrbid,
    items: itemAddr,
  }).then((info) => {
    const { status, msg } = info;

    if (status === 200) {
      dispatch(archive_address_book_success(itemAddr));
    } else {
      message.warning(msg);
    }
  });
};

const archive_address_book_success = (addrb) => {
  return {
    type: ActionTypes.ARCHIVE_ADDRESS_BOOK_BY_ID,
    addrb,
  };
};
