import { ROOT_CONSTANT } from "../../../constants";

const { icon_profile, icon_changePass, icon_addressBook } =
  ROOT_CONSTANT.IMG.ICON.MY_ACCOUNT;

import { KeyOutlined, UserOutlined, BookOutlined } from "@ant-design/icons";

export const SIDEBAR_FEATURE = {
  PROFILE: "PROFILE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  ADDRESS_BOOK: "ADDRESS_BOOK",
};

export const SIDEBAR_MENU = [
  {
    icon: <UserOutlined />,
    onChangeParam: SIDEBAR_FEATURE.PROFILE,
    label: "Profile",
  },
  {
    icon: <KeyOutlined />,
    onChangeParam: SIDEBAR_FEATURE.CHANGE_PASSWORD,
    label: "Change Password",
  },
  {
    icon: <BookOutlined />,
    onChangeParam: SIDEBAR_FEATURE.ADDRESS_BOOK,
    label: "Address Book",
  },
];

export const MODAL_ACTIONS = {
  deletePerson: "deletePerson",
  logout: "logout",
  busInfo: "busInfo",
};

export const MODAL_ACTION_ROLE = {
  sender: "sender",
  receiver: "receiver",
};
