// React Thirty Party Library
import React from "react";
import { Card } from "antd";
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE } from "../../../../constants";

// Components Library

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions

// Styles Library
import styles from "../../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const { CARD_CONTENT, CARD_CONTENT_FEATURES } = TEMPLATE.TOPUP;
const { ACCOUNT_INFO } = TEMPLATE.BANK;

const TopUpStep = ({ messageApi }) => {
  const user = useSelector(reduxStoreWrapper("user"));

  const handleCopyButton = (card) => {
    switch (card.copy) {
      case CARD_CONTENT_FEATURES.iPickAccInfo:
        navigator.clipboard.writeText(ACCOUNT_INFO.Account_Number);
        messageApi();
        break;
      case CARD_CONTENT_FEATURES.custId:
        navigator.clipboard.writeText(user.memid);
        messageApi();
        break;
      default:
        window.open(card.action, "_blank", "noreferrer");
    }
  };

  return (
    <div className={styles.content}>
      <div className={`${styles.titleLabel} ${styles.flex}`}>
        <div className={`${styles.trapezoid}`}>
          <h3
            className={styles.title}
            style={{
              marginTop: "18px",
              marginLeft: "30px",
              color: "#fff",
              zIndex: 2,
            }}
          >
            Guidance
          </h3>
        </div>
        <h3
          className={styles.title}
          style={{
            marginTop: "8px",
            marginLeft: "15px",
          }}
        >
          Top up in{" "}
          <strong style={{ fontSize: "24px", color: "#FF5F1F" }}>3</strong>{" "}
          steps:
        </h3>
      </div>
      <div
        className={styles.flex}
        style={{ justifyContent: "space-evenly", padding: "40px 25px" }}
      >
        {CARD_CONTENT.map((card, key) => (
          <Card key={key} style={{ border: "none", width: "30%" }}>
            <img src={card.img} alt="" />
            <span
              style={{ display: "flex", flexDirection: "column", gap: "3em" }}
            >
              <div className={styles.cardsFlex}>
                <h3 style={{ marginBottom: "12px" }}>{card.title}</h3>
                <span className={styles.cardContent}>{card.content}</span>
              </div>
              <div>
                <input
                  type="image"
                  src={card.actionImg}
                  alt=""
                  style={{ height: "35px" }}
                  onClick={() => handleCopyButton(card)}
                />
              </div>
            </span>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default TopUpStep;
