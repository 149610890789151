// Third-party Library
import { Steps, Button } from "antd"

// Components 
import { useNavigate } from "react-router"
import { ButtonLink } from "../../../../../../components"
import { LeftOutlined } from "@ant-design/icons"

import styles from "../../index.module.css"
import "./index.css"

const OrderHeader = ({
    step,
    items,
    loading,
    status,
    nextStepHDLR,
    prevStepHDLR
}) => {
    const navigate = useNavigate()

    return (
        <>
            <div className={styles["header-action-container"]}>
                <div className={styles["header-action"]}>
                    <LeftOutlined onClick={() => navigate(-1)} />
                    <h1>Submit Order</h1>
                </div>
                <div className={styles["header-action"]}>
                    <ButtonLink style={{ color: 'var(--greyColor)' }} onClick={prevStepHDLR}>{step !== 4 && 'Back' || `Back to Order List`}</ButtonLink>
                    <Button loading={loading} disabled={step === 4 && status !== 200} onClick={nextStepHDLR} type="primary" style={{padding: '8px 18px 28px 18px'}}>{step !== 4 && 'Next' || `Print Shipment Order`}</Button>
                </div>
            </div>
            <div className={styles["header-container"]}>
                <Steps className={styles["header-steps"]} current={step} labelPlacement="vertical" items={items} />
            </div>
        </>
    )
}

export default OrderHeader