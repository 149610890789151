// Third-party Library 
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Space,
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    Button,
    Tag,
    Switch,
    Card,
    Radio,
    Skeleton, 

  } from "antd";

// Constants
import { TEMPLATE } from "../../../../../../constants"; 
import { APP_CONFIG } from "../../../../../../config"; 

// Components
import { GoogleMapIFrame, NumericInput } from "../../../../../../components";

// Services Library
import {
    REDUX as REDUX_UTIL,
    // TIME,
} from "../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Styles
import coms from "../../index.module.css";

const { 
    DATA_FIELD, 
    STATE,
    HUB_OPTIONS
} = TEMPLATE.ORDER;

const { reduxStoreWrapper } = REDUX_UTIL;

const SenderInformation = ({
    form,
    dstpid
}) => {
    const dispatch = useDispatch()

    const user = useSelector(reduxStoreWrapper("user"));
    const hubs = useSelector(reduxStoreWrapper("hub.byID"));
    const points = useSelector(reduxStoreWrapper('point.byID'));
    const hubsIDs = useSelector(reduxStoreWrapper("hub.allIDs"));
    const addressBook = useSelector(reduxStoreWrapper("addressBook.byID"));
    const defSender = useSelector(reduxStoreWrapper('addressBook.byDefault.SENDER'))
    // const loadingAddrb = useSelector(reduxStoreWrapper('style.loading.address_book'))

    const { uid, uroleid } = user

    const [ hub, setHub ] = useState(uroleid === 'C' ? 'hub#amj' : 'point#mkr')
    const [ defaultSender, setDefaultSender ] = useState(defSender)

    const [ senderPostcode, setSenderPostcode ] = useState('')
    const [ filteredPoint, setFilteredPoint ] = useState(Object.values(points))

    const prefixContactSelector = (
        <Form.Item name={["sender","contactPrefix"]} noStyle>
            <Select style={{ width: 70 }}>
                <Select.Option value="+60">+60</Select.Option>
            </Select>
        </Form.Item>
    )

    const hubList = HUB_OPTIONS(hubs, hubsIDs)

    // OptionGroup -- Main OptionGroup value for suggested point
    const orderDstpid = points[dstpid]
    const filteredPoints = (senderPostcode !== ''? filteredPoint : Object.values(points)).filter(item => item.zone !== orderDstpid?.zone)
    // const pointList = (filteredPoint.length > 0 ? filteredPoint : Object.values(points)).map(point => ({
    const pointList = filteredPoints.map(point => ({
        label: point.address,
        value: point.hubid
    }))

    useEffect(() => {
        dispatch(
          REDUX_ACTION.v1_address_book.get_address_book_thru_uid_request(uid)
        );
        
        uroleid === 'POP'
        &&
        dispatch(
          REDUX_ACTION.v1_point.get_all_points_request()
        );

        const defaultValueSender = Object.values(addressBook).find(ab => ab.default === 1 && ab.type === 1) // get desired details of selected addressbook
        
        setDefaultSender(defaultValueSender?.addrbid)
        
        if(defaultValueSender && Object.values(addressBook).length) {
            let { name, contact, email, address, postcode, city, state } = defaultValueSender
    
            form.setFieldsValue({
                sender: {
                    name,
                    contact,
                    email,
                    address,
                    postcode,
                    city,
                    state,
                }
            });


        }
    
      }, [Object.values(addressBook).length]);

    const senderSelector = (addrbid) => {

        const defaultValueSender = Object.values(addressBook).find((ab) => ab.addrbid === addrbid); // get desired details of selected addressbook
        let { name, contact, email, address, postcode, city, state } = defaultValueSender

        form.setFieldsValue({
            sender: {
                name,
                contact,
                email,
                address,
                postcode,
                city,
                state,
            }
        });

    };

    const optionsSender = Object.values(addressBook)
        .filter((ab) => ab.type === 1)
        .map((ab) => ({
            label: (
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    {ab.name}
                    {ab.default === 1 && <Tag color="orange">Default</Tag>}
                </div>
            ),
            value: ab.addrbid,
        }));
    
    const optionsState = Object.keys(STATE).map((state) => ({
        label: STATE[state].name,
        value: STATE[state].code,
    }));

    const coordinates = uroleid === 'C' ? hubs[hub]?.coordinate : points[hub]?.coordinate

    // const disabledDate = current => {
    //     // Disable dates before today (including today)
    //     const today = new Date();
    //     return current && current < today;
    //   };

    // Search Point function
    const searchPointHDLR = () => {
        let selectedPoint = []

        selectedPoint = Object.values(points).filter(point => point.postcode.includes(senderPostcode))

        setFilteredPoint(selectedPoint)

    }

    return (
        <>
            <p>SENDER INFORMATION</p>
            <Card className={coms["card"]}>
                <Row gutter={3} justify={'space-between'}>
                    <Col span={10}>
                        <Form.Item label='Delivery Instruction' name={'add_on'} initialValue={''}>
                            <Radio.Group>
                                <Space direction='vertical'>
                                    {
                                        uroleid === 'C'
                                        &&
                                        <>
                                            <Radio value={'svc_dpu'}>Doorstep Pick Up</Radio>
                                            <Radio value={''}>Drop off at hub</Radio>                                            
                                        </>
                                        ||
                                            <Radio value={''}>Drop off at point</Radio>
                                    }
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {
                            uroleid === "C"
                            &&
                            <Form.Item label={`Select a origin hub`} name={'origid'} rules={[{ required: true }]}>
                                <Select
                                    options={hubList}
                                    onChange={e => setHub(e)}
                                />
                            </Form.Item>
                            ||
                            <>
                                <Row gutter={10}>
                                    <Col span={18}>
                                        <Form.Item label={'Sender Postcode'}>
                                            <Input maxLength={5} onChange={(e) => setSenderPostcode(e.target.value)}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Button type='primary' style={{ position: 'absolute', bottom: '30%' }} onClick={searchPointHDLR}>Search</Button>
                                    </Col>
                                </Row>
                                <Skeleton loading={Object.values(points).length === 0}>
                                    <Form.Item label={`Suggested Drop Off Point (${filteredPoint.length})`} name={'origid'} rules={[{ required: true }]} >
                                        <Select
                                            showSearch
                                            options={pointList}
                                            onChange={e => setHub(e)}
                                            filterOption={(input, option) => {
                                                return option.label.toLowerCase().includes(input.toLowerCase())
                                            }}
                                        />
                                    </Form.Item>
                                </Skeleton>
                            </>
                        }
                        <Form.Item label='Desired Departure Datetime' name='etd' rules={[{ required: true }]}>
                            <DatePicker showTime format={"DD/MM/YYYY hh a"} style={{ width: '100%' }} 
                            // disabledDate={disabledDate} 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {
                            coordinates 
                            &&
                            <GoogleMapIFrame 
                                title={uroleid === 'C' ? hubs[hub]?.code : points[hub]?.code}
                                APIKey={APP_CONFIG.API_KEY.GOOGLE_MAP}
                                coordinate={coordinates}
                                width="100%"
                                height="100%"
                            />
                        }
                    </Col>
                </Row>
            </Card>
            <Card className={coms["card"]}>
                <Col span={8}>
                   { (defaultSender || optionsSender.length > 0) && <Form.Item label={"Favorite's Sender"}>
                        <Select
                            onSelect={(ab) => senderSelector(ab)}
                            defaultValue={defaultSender}
                            options={optionsSender}
                        />
                    </Form.Item>}
                </Col>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            label={"Sender Name"}
                            name={DATA_FIELD.sender_name.split("_")}
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={"Sender Contact"}
                            name={DATA_FIELD.sender_contact.split("_")}
                            rules={[{ required: true }]}
                        >
                            <NumericInput
                                addonBefore={prefixContactSelector}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={"Sender Email"}
                            name={DATA_FIELD.sender_email.split("_")}
                            rules={[{ required: false, type: "email" }]}
                        >
                            <Input
                                placeholder="example@example.com"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={"Sender Address"}
                            name={DATA_FIELD.sender_address.split("_")}
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row  gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            label={"Postcode"}
                            name={DATA_FIELD.sender_postcode.split("_")}
                            rules={[{ required: true }]}
                        >
                            <Input maxLength={5} />
                        </Form.Item>
                    </Col>
                <Col span={8}>
                    <Form.Item
                        label={"City"}
                        name={DATA_FIELD.sender_city.split("_")}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={"State"}
                        name={DATA_FIELD.sender_state.split("_")}
                        rules={[{ required: true }]}
                    >
                        <Select options={optionsState} />
                    </Form.Item>
                </Col>
                </Row>
                <Row>
                <Col span={24}>
                    <Form.Item
                        label={"Sender Remark"}
                        name={DATA_FIELD.sender_remark.split("_")}
                    >
                        <Input placeholder={"Eg: Will come to self collect"} />
                    </Form.Item>
                </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item
                            name={"addrbook_save_sender"}
                            label={"Save to address book"}
                            valuePropName='checked'
                            noStyle
                        >
                            <Switch
                                size="small"
                                style={{
                                    marginRight: "10px",
                                }}
                            />
                        </Form.Item>
                        Save to address book
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default SenderInformation
