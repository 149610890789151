// React Thirty Party Library

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library

const { ORDER_STATUS } = TEMPLATE.ORDER;


  export const getTitleCancelPark = (os, OrderStatusDisplay) => {

    if (os === ORDER_STATUS.CANCELLED) {
      return OrderStatusDisplay(ORDER_STATUS.CANCELLED);
    } else if (os === ORDER_STATUS.PARKED || os === ORDER_STATUS.CLOSED) {
      return OrderStatusDisplay(ORDER_STATUS.PARKED);
    } else if (os === ORDER_STATUS.COMPLETED) {
      return ''; 
    } else {
      return `${OrderStatusDisplay(ORDER_STATUS.CANCELLED)} / ${OrderStatusDisplay(ORDER_STATUS.PARKED)}`;
    }
  };

  export const getTitleCompleteClose = (os, OrderStatusDisplay) => {
    if (os === ORDER_STATUS.COMPLETED || os === ORDER_STATUS.CANCELLED) {
      return OrderStatusDisplay(ORDER_STATUS.COMPLETED);
    } else if (os === ORDER_STATUS.CLOSED || os === ORDER_STATUS.PARKED) {
      return OrderStatusDisplay(ORDER_STATUS.CLOSED);
    } else {
      return `${OrderStatusDisplay(ORDER_STATUS.COMPLETED)} / ${OrderStatusDisplay(ORDER_STATUS.CLOSED)}`;
    }
  };

  export const getTitleDoorstep = (os, OrderStatusDisplay, record) => {
    if (os === ORDER_STATUS.PENDING_SELF_LODGE) {
      return OrderStatusDisplay(ORDER_STATUS.PENDING_SELF_LODGE);
    }
    else if (os === ORDER_STATUS.PENDING_SELF_LODGE_POINT || record.deliveryType === 'p2p') {
      return OrderStatusDisplay(ORDER_STATUS.PENDING_SELF_LODGE_POINT);
    }
    else if (os === ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER) {
      return OrderStatusDisplay(ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER);
    } else if (os >= ORDER_STATUS.SUBMITTED) {
      return record.deliveryType === "h2h" || !record.add_on 
        ? OrderStatusDisplay(ORDER_STATUS.PENDING_SELF_LODGE)
        : OrderStatusDisplay(ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER);
    }
  };
  
  export const getDescDoorstep = (os, OrderStatusDesc, record) => {
    if (os === ORDER_STATUS.PENDING_SELF_LODGE) {
      return OrderStatusDesc(ORDER_STATUS.PENDING_SELF_LODGE);
    } 
    else if (os === ORDER_STATUS.PENDING_SELF_LODGE_POINT || record.deliveryType === 'p2p') {
      return OrderStatusDesc(ORDER_STATUS.PENDING_SELF_LODGE_POINT);
    }else if (os === ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER) {
      return OrderStatusDesc(ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER);
    } else if (os >= ORDER_STATUS.SUBMITTED) {
      return record.add_on === undefined
        ? OrderStatusDesc(ORDER_STATUS.PENDING_SELF_LODGE)
        : OrderStatusDesc(ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER);
    }
  };
  
  export const getTitleArrive = (os, OrderStatusDisplay, record) => {
    if (os === ORDER_STATUS.PENDING_SELF_COLLECTION) {
      return OrderStatusDisplay(ORDER_STATUS.PENDING_SELF_COLLECTION);
    }
    else if (os === ORDER_STATUS.PENDING_SELF_COLLECTION_POINT || record.deliveryType === 'p2p') {
      return OrderStatusDisplay(ORDER_STATUS.PENDING_SELF_COLLECTION_POINT);
    }  
    else if (os === ORDER_STATUS.PENDING_DOORSTEP_DELIVERY) {
      return OrderStatusDisplay(ORDER_STATUS.PENDING_DOORSTEP_DELIVERY);
    } 
    else if (os >= ORDER_STATUS.SUBMITTED) {
      return record.deliveryType === "h2h"
        ? OrderStatusDisplay(ORDER_STATUS.PENDING_SELF_COLLECTION)
        : OrderStatusDisplay(ORDER_STATUS.PENDING_DOORSTEP_DELIVERY);
    }
  };
  
  export const getDescArrive = (os, OrderStatusDesc, record) => {
    if (os === ORDER_STATUS.PENDING_SELF_COLLECTION) {
      return OrderStatusDesc(ORDER_STATUS.PENDING_SELF_COLLECTION);
    } else if (os === ORDER_STATUS.PENDING_DOORSTEP_DELIVERY) {
      return OrderStatusDesc(ORDER_STATUS.PENDING_DOORSTEP_DELIVERY);
    } else if (os >= ORDER_STATUS.SUBMITTED) {
      return record.deliveryType === "h2h"
        ? OrderStatusDesc(ORDER_STATUS.PENDING_SELF_COLLECTION)
        : OrderStatusDesc(ORDER_STATUS.PENDING_DOORSTEP_DELIVERY);
    }
  };

