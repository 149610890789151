import { ROOT_CONSTANT } from "../../../constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { icon_update_status } = ROOT_CONSTANT.IMG.ICON.MODAL;

const ordid = "ORD0001";

export const MODAL_ACTION = {
  update_status_self_lodge: "update_status_self_lodge",
  update_status_pickup: "update_status_pickup",
  update_status_sort: "update_status_sort",
  update_status_load_to_bus: "update_status_load_to_bus",
  update_status_self_collect: "update_status_self_collect",
  update_status_pending_delivery: "update_status_pending_delivery",
  update_status_received_by_receiver: "update_status_received_by_receiver",
  update_status_mark_as_resolved: "update_status_mark_as_resolved",
  update_status_park_order: "update_status_park_order",
  update_status_cancel_order: "update_status_cancel_order",
  wallet_notif: "wallet_notif",
};

export const MODALS = {
  [MODAL_ACTION.update_status_self_lodge]: {
    img: icon_update_status,
    title: "Status Update",
    content: "",
  },
  [MODAL_ACTION.update_status_pickup]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) => `Confirm the pending pick-up time for ${ordid} at:`,
  },
  [MODAL_ACTION.update_status_sort]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) =>
      `Confirm the pending outbound/received at origin hub time for ${ordid} at:`,
  },
  [MODAL_ACTION.update_status_load_to_bus]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) =>
      `Confirm the pending inbound/departing to destination hub time for ${ordid} at:`,
  },
  [MODAL_ACTION.update_status_self_collect]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) =>
      `Confirm the arrived and ready for self-collection time for ${ordid} at:`,
  },
  [MODAL_ACTION.update_status_pending_delivery]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) =>
      `Confirm the arrived and ready for delivery time for ${ordid} at:`,
  },
  [MODAL_ACTION.update_status_received_by_receiver]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) =>
      `Confirm the received by receiver / receiver collected time for ${ordid} at:`,
  },
  [MODAL_ACTION.update_status_mark_as_resolved]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid, oparkid }) =>
      `Confirm the resolved time for the parking ticket (${oparkid}) and the park order closed time (${ordid}) at:`,
  },
  [MODAL_ACTION.update_status_park_order]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) => `Confirm the parking time for ${ordid} at:`,
  },
  [MODAL_ACTION.update_status_cancel_order]: {
    img: icon_update_status,
    title: "Status Update",
    content: ({ ordid }) => `Confirm the cancellation time for ${ordid} at:`,
  },
  [MODAL_ACTION.wallet_notif]: {
    img: "",
    title: "",
    content: (
      <span>
        <ExclamationCircleOutlined /> Please ensure your wallet has a minimum
        balance of RM30 to place an order.
      </span>
    ),
  },
};

// console.log(MODALS)

export const MODALS_UPDATE_STATUS = [
  MODAL_ACTION.update_status_self_lodge,
  MODAL_ACTION.update_status_pickup,
  MODAL_ACTION.update_status_sort,
  MODAL_ACTION.update_status_load_to_bus,
  MODAL_ACTION.update_status_self_collect,
  MODAL_ACTION.update_status_pending_delivery,
  MODAL_ACTION.update_status_received_by_receiver,
  MODAL_ACTION.update_status_mark_as_resolved,
  MODAL_ACTION.update_status_park_order,
  MODAL_ACTION.update_status_cancel_order,
];
