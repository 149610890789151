import * as API_CONSTANT from "../constants";

export const getAddressBookThruUID = ({ uid }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/user/addrb/get`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
    }),
  }).then((res) => res.json());

export const addAddressBookThruUID = ({ uid, addrb }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/user/addrb/cu`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      action: API_CONSTANT.API_ACTION[API_CONSTANT.ACTION.CREATE],
      uid,
      addrb
    }),
  }).then((res) => res.json());

export const updateAddressBook = ({ uid, addrbid, items }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/user/addrb/cu`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      action: API_CONSTANT.API_ACTION[API_CONSTANT.ACTION.UPDATE],
      uid,
      addrbid,
      items
    }),
  }).then((res) => res.json());
