import { Col, Row } from "antd";

// Constants
import { TEMPLATE } from '../../../../../../../../../constants'

const { STATE } = TEMPLATE.ORDER

const ProfileData = ({
    forms,
    // userRole
}) => {

    const {
        name = "-",
        memid = "-",
        email = "-",
        contactPrefix = "+60",
        contact = "-",
        address = "-",
        postcode = "-",
        city = "-",
        state = "-",
    } = forms;

    const state_name = Object.values(STATE).find(s => s.code === state)?.name

  return (
    <div style={{ padding: "25px", fontSize: "16px" }}>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="userName" style={{ color: "#414141" }}>
                Username
            </label>
            </Col>
            <Col span={16}>{name}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="userRole" style={{ color: "#414141" }}>
                User Role
            </label>
            </Col>
            <Col span={16}>Customer</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="customerId" style={{ color: "#414141" }}>
                Customer ID
            </label>
            </Col>
            <Col span={16}>{memid}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="email" style={{ color: "#414141" }}>
                Email
            </label>
            </Col>
            <Col span={16}>{email}</Col>
        </Row>
        <Row style={{ marginBottom: "35px" }}>
            <Col span={8}>
            <label htmlFor="contact" style={{ color: "#414141" }}>
                Contact
            </label>
            </Col>
            <Col span={16}>{contact.includes('-') ? contact : contactPrefix + "-" + contact}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="address" style={{ color: "#414141" }}>
                Address
            </label>
            </Col>
            <Col span={16}>{address}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="postCode" style={{ color: "#414141" }}>
                Postcode
            </label>
            </Col>
            <Col span={16}>{postcode}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="city" style={{ color: "#414141" }}>
                City
            </label>
            </Col>
            <Col span={16}>{city}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="state" style={{ color: "#414141" }}>
                State
            </label>
            </Col>
            <Col span={16}>{state_name || state}</Col>
        </Row>
    </div>
  );
};

export default ProfileData;
