import { ROOT_CONSTANT } from "../../../constants";

const {
  icon_copyBig,
  icon_topupBig,
  icon_whatsappBig,
  icon_copyInfo,
  icon_copyId,
  icon_whatsapp_link,
} = ROOT_CONSTANT.IMG.ICON.TOPUP;

export const CARD_CONTENT_FEATURES = {
  iPickAccInfo: "iPickAccInfo",
  custId: "custId",
};

export const CARD_CONTENT = [
  {
    img: icon_copyBig,
    title: "Copy our account information",
    content:
      "Before top up, copy our iPICK account information then login to your preferred bank. ",
    actionImg: icon_copyInfo,
    copy: CARD_CONTENT_FEATURES.iPickAccInfo,
  },
  {
    img: icon_topupBig,
    title: "Top up",
    content:
      "Transfer us the amount you wish to top up and copy and paste your customer ID in the “Payment Reference” column.",
    actionImg: icon_copyId,
    copy: CARD_CONTENT_FEATURES.custId,
  },
  {
    img: icon_whatsappBig,
    title: "WhatsApp us you receipt",
    content:
      "Send your receipt to us via WhatsApp (+60 1163641488). Once verified, we will add credits to your wallet within 4 hours.",
    actionImg: icon_whatsapp_link,
    action: "https://wa.me/601163641488",
  },
];
