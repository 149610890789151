// React Thirty Party Library
import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { 
    Table, 
    message 
} from "antd";

// Constants Library
import { 
    TEMPLATE, 
    ROOT_CONSTANT,
} from "../../../../constants";
import { APP_INFO } from "../../../../config";

// Components Library
import { ButtonLink } from "../../../../components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions

// Styles Library
import styles from '../common.module.css'

const { reduxStoreWrapper } = REDUX_UTIL;

const { PRICING_LIST } = TEMPLATE.ORDER
const {
    img_point_fee
} = ROOT_CONSTANT.IMG.ICON.DASHBOARD

const PricingList = () => {
    const navigate = useNavigate();
    const uroleid = useSelector(reduxStoreWrapper("user.uroleid"));

    const [messageApi, contextHolder] = message.useMessage();

    const onDownload = () => {
        // 👇 Open link in new tab programmatically
        window.open(APP_INFO.ASSET.HUB_PRICE_LIST, "_blank", "noreferrer");

        messageApi.open({
            type: "success",
            content: "File downloaded successfully!",
            duration: 4,
        });
    };

  return (
    <div>
        {contextHolder}
        <h2 className={styles["label-title"]}>Pricing List</h2>
        {
            uroleid === 'C' 
            && 
            <>
                <Table
                    scroll={{
                    y: 360,
                    }}
                    columns={PRICING_LIST["columns"]}
                    dataSource={PRICING_LIST["data"]}
                    pagination={false}
                    className={styles["table-lwh"]}
                />

                <div className={styles["container-submit-content"]}>
                    Max. Weight Is 20Kg.
                    <br />
                    <ButtonLink style={{ display: "inline" }} onClick={onDownload}>
                    Download
                    </ButtonLink>{" "}
                    to check overweight charges.
                </div>
            </> 
            ||
            <>
                <img src={img_point_fee} alt="point fee" style={{ width: '100%' }}/>
            </>
        }
    </div>
  );
};

export default PricingList;
