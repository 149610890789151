// Third Party Library

// Config
import { APP_CONFIG } from '../../config'

// Service 
import { AWSSDK } from '../../services/cloud'
import { TIME } from '../../services/util'

const {
    AWS_URI
} = APP_CONFIG

const {
    upload2S3
} = AWSSDK

const {
    getCurrentDateFormat,
    getCurrentDateTimeFormat
} = TIME

export const decodeFile = (file, decode = () => {}) => {

    const reader = new FileReader();

    reader.onload = (e) => {
        const d = e.target.result;
        decode(d)
    }

    reader.readAsBinaryString(file)
}

export const getBase64 = (file, cb = () => {}) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => cb(reader.result));
    reader.readAsDataURL(file);
}

export const put2AssetS3CustomeRequest = async (
    {
        file,
        onError,
        onProgress,
        onSuccess
    }
) => {

    const {
        name: filename
    } = file
    
    try {
        const data = await upload2S3({
            bucket: AWS_URI.s3.asset.bucket,
            key: `${AWS_URI.s3.asset.key.order_attachment}/${getCurrentDateFormat()}/${getCurrentDateTimeFormat()}_${filename}`,
            file
        })
            .on("httpUploadProgress", ({ loaded, total }) => {
                onProgress(
                    {
                        percent: Math.round((loaded / total) * 100)
                    },
                    file
                )
            })
            .done()
        onSuccess(data, file)
    } catch (err) {
        onError(err)
    }
}
