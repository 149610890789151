import { Tag } from "antd";
// Contanst
import { TEMPLATE } from "../../constants";

const { OPTION_STATUS_TITLE, OPTION_STATUS, OPTION_TYPE_TITLE, OPTION_TYPE } =
  TEMPLATE.TRANSACTION;

export const transactionStatus = () => {
  let tree = [];

  if (OPTION_STATUS) {
    const allChildren = Object.values(OPTION_STATUS).map((status) => {
      const { tag, color } = OPTION_STATUS_TITLE[status];

      const title = (
        <Tag color={color} key={status}>
          {tag}
        </Tag>
      );

      const key = `status#${status}`;
      const value = `status#${status}`;

      // console.log(value);

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const transactionType = () => {
  let tree = [];

  if (OPTION_TYPE) {
    const allChildren = Object.values(OPTION_TYPE).map((type) => {
      const title = OPTION_TYPE_TITLE[type];
      const key = `type#${type}`;
      const value = `type#${type}`;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }

  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};
