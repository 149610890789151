import { Form, Row, Col, Input, Select } from "antd";

// Components 
import { NumericInput } from "../../../../../../../../../components";

const { Option } = Select

const ProfileForm = ({
    optionsState
}) => {
    const prefixContactSelector = (
        <Form.Item name="contactPrefix" noStyle>
            <Select style={{ width: 70 }}>
                <Option value="+60">+60</Option>
            </Select>
        </Form.Item>
    );

    return (
        <>
            <Row>
                <Col span={8}>
                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col span={8}>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type:"email" }]}
                    >
                        <Input
                            placeholder="example@example.com"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="contact" label="Contact" 
                        rules={[{ required: true, max: 12, min: 9 }]}
                    >
                        <NumericInput
                            addonBefore={prefixContactSelector}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="userRole" label="User Role">
                        <label htmlFor="Customer">Customer</label>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={16}>
                    <Form.Item 
                      name="address"
                      label="Address"
                      rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col span={8}>
                    <Form.Item name='postcode' label="Postcode" rules={[{ required: true, max: 5 }]}>
                    <Input
                        type="number"
                        placeholder="Eg: 50100"
                    />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="city" label="City" rules={[{ required: true }]}>
                        <Input
                            placeholder="Eg: WP Kuala Lumpur"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Form.Item name="state" label="State" rules={[{ required: true }]}>
                        <Select
                            placeholder="-- SELECT --"
                            options={optionsState}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default ProfileForm;
