// React Thirty Party Library
import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { message } from "antd";

// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import { TableList } from "../../../../components";

// Handler Library
import { billingHDLR } from "../../../../handlers";

// Services Library

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";
 
// Styles Library

const { TABLE_BILLING_INDEX, SELECT_OPTION_DAYS, OPTION_DAYS } =
  TEMPLATE.BILLING;
const { BILLING_SUMMARY_COL } = TEMPLATE.REPORT.BILLING;
const { MODULE_ROUTE } = ROUTES;

const TableBilling = ({
  dataSource,
  filteredData,
  setFilteredData,
  loading,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const NavigateOrderSummary = (ordid) => {
    navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY], {
      state: { ordid },
    });
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState('');

    // handler for search, for filtering data shown in table
    const handleSearch = (name, keyword) => {
        const filteredKeyword = dataSource.filter(data => data[name]?.includes(keyword))

        setFilteredData(filteredKeyword)
    };

    const getJournal = (refid, createdNote) => {
      messageApi.open({
        type: "loading",
      content: "getting the attachment...",
      duration: 0,
    });
      dispatch(
        REDUX_ACTION.v1_journalNote.get_journal_note_by_jnid(
          refid,
          createdNote,
          () => messageApi.destroy()
        )
      );
    };

  const columns = BILLING_SUMMARY_COL({
    func: {
      NavigateOrderSummary,
      handleSearch,
      getJournal
    },
  });
  const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    createdAt: (a, b) => a.createdAt - b.createdAt,
    updatedAt: (a, b) => a.updatedAt - b.updatedAt,
  };

  const filtered = sortedData
    .filter((record) => {
      let include = true;

      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] && filteredInfo[key].length > 0) {
          include = include && filteredInfo[key].includes(record[key]);
        }
      });

      if (searchText) {
        if(searchedColumn === "billid") {
          if ((typeof record.billid === 'string' && record.billid.includes(searchText))) {
            include = true;
          }
        }
        if(searchedColumn === "ordid") {
          if ((typeof record.ordid === 'string' && record.ordid.includes(searchText))) {
            include = true;
          }
        }
      }   

      return include;
    })
    .sort((a, b) => {
      const { columnKey } = sortedInfo;
      return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
    });

  return (
    <>
      <TableList.DataList
              rowSelection={{
                selectedRowKeys,
                onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys)
            }}
        dataSource={dataSource}
        filteredData={filtered}
        setFilteredData={setFilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionStatus={billingHDLR.billingStatus()}
        optionType={billingHDLR.billingType()}
        dataDate={TABLE_BILLING_INDEX.Billing_Date}
        defaultValue={`DATE : ${OPTION_DAYS.LAST_30_DAYS}`}
        text1={`BILLING STATUS : ALL`}
        text2={`BILLING TYPE : ALL`}
        columnData={columns}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableBilling;
