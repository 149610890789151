import { useNavigate } from 'react-router'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

// Constant
import { ROUTE as ROUTE_CONSTANT } from '../../constants'

// Components
import { CopyrightFooter } from '../../components'

// Style
import classStyles from './index.module.css'

const SSOLinkUnvalidated = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className={classStyles.inputForm}>
                <div className={classStyles.inputContainer}>
                    <h4>
                        <CloseCircleOutlined className={classStyles.checkedIcon}/>
                        <strong>Ops, the link has expired or invalid</strong>
                    </h4>
                    <p className="mt-1">It looks like your link has expired or invalid. Please try again.</p>
                    <div className={classStyles.inputWrap}>

                        <div className={classStyles.invisibleBox} />

                        <div className={classStyles.inputFormContent}>
                            <Button 
                                className={classStyles.inputButton} type="primary" htmlType="submit"
                                onClick={() => navigate(ROUTE_CONSTANT.MODULE_ROUTE.Landing_Page)}
                            >
                                Back
                            </Button>
                        </div>

                        <CopyrightFooter />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SSOLinkUnvalidated