// Third-party Libraray
import { useState } from 'react'
import { Form, Input, Card, Col, Row, Upload, Select, InputNumber } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

// Constants 
import { TEMPLATE } from '../../../../../../constants';

// Handler
import { uploadHDLR } from '../../../../../../handlers';

// Styles
import coms from "../../index.module.css";

const {
    PARCEL_FIELDS,
    PARCELS_TOTAL_CALCULATION
} = TEMPLATE.ORDER

const { 
    getBase64,
    put2AssetS3CustomeRequest
} = uploadHDLR

const ParcelInformation = ({
    setOrder,
    order
}) => {

    // Some state to store temporary value needed
    const [ tempQty, setTempQty ] = useState({})

    // handleChange for some input field - number
    const handleChange = (e, name, fname) => { // fname stands for fieldname

        // Update totalParcelQty for the specific field
        setTempQty((qty) => ({
            ...qty,
            [name]: {
                ...qty[name],
                [fname]: parseFloat(e),
            }
        }));
    }

    
    // used as parameter in totals
    const parcelArray = Object.values(tempQty)
    const parcelUnsort = order.parcel?.unsort

    // make the value in banner changeable if certain area changed
    const parcels = parcelUnsort?.map((parcel, un) => {
        const matchingTemp = parcelArray.find((_, t) => t === un)

        return matchingTemp ? { ...parcel, ...matchingTemp } : parcel

    }) || parcelArray

    // parcel calculation used in banner
    const totals = PARCELS_TOTAL_CALCULATION(parcels)

    const { total_pieces, total_volumes, total_weights } = totals

    // AddOn in input Qty
    const parcelQuantityType = (
        <Select defaultValue="pieces">
            <Select.Option value="pieces">Pieces</Select.Option>
        </Select>
    )

    const handleChangeImg = (info) => {
        const { status, originFileObj, response = {} } = info.file;

        if (status === "done") {
            getBase64(originFileObj, () => {
                const { Location: url } = response;

                setOrder((order) => ({
                    ...order,
                    img: Array.isArray(order.img) ? [...order.img, url] : [url]
                }));
            });
        }
    };

    // Used for img's initial value (if user click back, the already assigned img will be there)
    const fileList = order.img?.map((url, index) => ({
        uid: index,
        name: `image-${index + 1}.jpg`,
        status: 'done',
        url,
    }));


    return (
        <>
            <span className={coms["title"]}>
                <p>PARCEL INFORMATION</p>
            </span>
            <div className={coms["banner"]}>
                <span className={coms["banner-trapezoid"]}>
                    <div className={coms["banner-trapezoid-sub"]}>Summary</div>
                </span>
                <span className={coms["banner-sub"]}>
                    <div>Parcel Quantity: {total_pieces || '-'}</div>
                    <div>Total Volume (m³): {total_volumes || '-'}</div>
                    <div>Total Weight (kg): {total_weights || '-'}</div>
                </span>
            </div>

            <Card className={coms["card"]}>
                <Form.List name={['parcel', 'unsort']} initialValue={[{}]}>
                    {(fields, {add, remove}) => {

                        return (
                            <>
                                { fields.map((field, i) => {
                                    
                                    const { name } = field

                                    return (

                                        <Row gutter={20} key={i}>

                                            { 
                                                Object.values(PARCEL_FIELDS).map((pfield, i) => (

                                                    <Col span={4} key={i}>
                                                        <Form.Item label={pfield.label} name={[name, pfield.name]} rules={[{ required: true }]} >
                                                            <InputNumber 
                                                                type='number' 
                                                                min={1} 
                                                                addonAfter={i === 0 && parcelQuantityType}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => handleChange(e, name, pfield.name)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ))
                                            }

                                            
                                            <Col span={2} style={{ display: 'flex', gap: '15px', fontSize: '1.5em' }}>
                                                <PlusOutlined onClick={() => {
                                                    add()
                                                }} />
                                                {"        "}
                                                {i > 0 && (
                            <DeleteOutlined onClick={() => remove(name)} />
                          )}
                                            </Col>
                                        </Row>
                                )} ) }
                            </>
                        )
                    }}
                </Form.List>
            </Card>

            <Card className={coms["card"]} >
                <Row gutter={20}>
                    <Col span={16}>
                        <Form.Item name={"remark"} label="Parcel Remarks" >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Declared Value (RM)" name={"dv"} >
                            <InputNumber type='number' min={0} addonBefore="RM" />
                        </Form.Item>
                    </Col>
                </Row>
                    <Form.Item label="Parcel Images">
                        <Upload
                            onChange={handleChangeImg}
                            customRequest={put2AssetS3CustomeRequest}
                            defaultFileList={fileList}
                            listType="picture-card"
                            accept=".jpg,.jpeg,.png"
                        >
                            {"+ upload"}
                        </Upload>
                    </Form.Item>
            </Card>

        </>
    )
}

export default ParcelInformation