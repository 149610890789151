import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config

// Services

// Redux Action

// Neccessary Actions

export const get_all_hubs_request = () => dispatch => {

    API
    // .getAllHubs()
    .getAllActiveHubs()
    .then(info => {

        const {
            status,
            hubs,
            msg
        } = info

        if(status === 200) {
            dispatch(get_all_hubs_success(hubs))
        } else {
            message.error(msg)
        }
    })
}

const get_all_hubs_success = (hubs) => {
    return {
        type: ActionTypes.HUB_GET_ALL,
        hubs
    };
}