import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config

// Services
import { TIME } from "../../util";

// Redux Action
import {
  setLoadingTransactionList,
  unsetLoadingTransactionList,
} from "./style";

// Neccessary Actions
const { lastNDay, latestTime } = TIME;

// const dummyTransactionData = [
//   {
//     key: "1",
//     Transaction_Id: "TRS001",
//     Transacted_At: "02/28/2023 16:33",
//     Transaction_Status: "SUCCESSFUL",
//     Transaction_Type: "TOPUP",
//     Before_Transaction: "RM56",
//     Amount: "+RM23",
//     After_Transaction: "RM79",
//     Billing_ID: "TP0001",
//     Attachment: "",
//     Transacted_By: "IPHQ0001",
//     Remarks: "-",
//   },
//   {
//     key: "2",
//     Transaction_Id: "TR5002",
//     Transacted_At: "10/12/2021 16:33",
//     Transaction_Status: "SUCCESSFUL",
//     Transaction_Type: "REFUND",
//     Before_Transaction: "RM38",
//     Amount: "+RM18",
//     After_Transaction: "RM56",
//     Billing_ID: "RF00001",
//     Attachment: "",
//     Transacted_By: "IPHQ0001",
//     Remarks: "Order Damage",
//   },
//   {
//     key: "3",
//     Transaction_Id: "TRS003",
//     Transacted_At: "01/28/2023 16:33",
//     Transaction_Status: "SUCCESSFUL",
//     Transaction_Type: "PAYMENT",
//     Before_Transaction: "RM50",
//     Amount: "-RM12",
//     After_Transaction: "RM38",
//     Billing_ID: "IVC0001",
//     Attachment: "",
//     Transacted_By: "IPHQ0001",
//     Remarks: "Payment for ORD001 (auto)",
//   },
//   {
//     key: "4",
//     Transaction_Id: "TRS004",
//     Transacted_At: "01/28/2023 10:21",
//     Transaction_Status: "REJECTED",
//     Transaction_Type: "TOPUP",
//     Before_Transaction: "RM56",
//     Amount: "+RM23",
//     After_Transaction: "RM79",
//     Billing_ID: "TP001",
//     Attachment: "",
//     Transacted_By: "IPHQ0001",
//     Remarks: "-",
//   },
// ];

// export const get_transaction_timeframe_request = () => (dispatch) => {
//   dispatch(setLoadingTransactionList());

//   dispatch(get_transaction_timeframe_success(dummyTransactionData));

//   dispatch(unsetLoadingTransactionList());

//   message.success("Get Transaction Data SUCCESSFULly");
// };
export const get_transaction_timeframe_thru_uid_last_90_days_request =
  (uid) => (dispatch) => {
    dispatch(
      get_transaction_timeframe_thru_uid_request(uid, lastNDay(90), latestTime)
    );
  };

export const get_transaction_timeframe_thru_uid_request =
  (uid, startTime, endTime) => (dispatch) => {
    dispatch(setLoadingTransactionList());

    API.getTransactionTimeFrameThruUID({
      uid,
      startTime,
      endTime,
    })
      .then((info) => {
        const { status, transactions, msg } = info;

        // console.log(transactions);

        if (status === 200) {
          dispatch(get_transaction_timeframe_thru_uid_success(transactions));
          message.success("Get Transaction List Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingTransactionList());
      });
  };

const get_transaction_timeframe_thru_uid_success = (transactions) => {
  return {
    type: ActionTypes.GET_TRANSACTION_TIMEFRAME,
    transactions,
  };
};
