// Third Party Lib
import { useState } from "react";
import { Col, Row, Form, Input, Select, Switch, Radio } from "antd";

// Constant
import { TEMPLATE } from "../../../../../../../../../constants";

// Components
import { NumericInput } from "../../../../../../../../../components";

// Style
import styles from "../../../../../../../common.module.css";

const { ADDRB_TYPE, ADDRB_TYPE_CODE, ADDRB_TYPE_THRU_CODE, ADDRB_TYPE_TITLE } =
  TEMPLATE.ADDRESS_BOOK;

const AddEditFormContent = ({ form, forms, setForms, handleChange, type, setType }) => {
//   const [AddrBType, SetAddrBType] = useState(1);

  const prefixContactSelector = (
    <Form.Item name="contactPrefix" noStyle>
      <Select style={{ width: 70 }} onChange={e => handleChange({ target: {name: 'contactPrefix', value: e }})}>
        <Select.Option value="+60">+60</Select.Option>
      </Select>
    </Form.Item>
  );

//   const type = form.getFieldValue('type')

  return (
    <>
      <div className={styles.toggle}>
        <Switch
          style={{
            width: "8px",
          }}
          name="default"
          checked={forms.default}
          onChange={(val) =>
            setForms((p) => ({
              ...p,
              default: val,
            }))
          }
        />
        <label htmlFor="setAsDefSender">
          Set as Default {ADDRB_TYPE_TITLE[ADDRB_TYPE_THRU_CODE[type]]}
        </label>
      </div>

      <Row gutter={30}>
        <Col span={9}>
          <Form.Item name="name" label="Name">
            <Input name="name" value={forms.name} onChange={handleChange} />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Radio.Group
              onChange={(e) => {
                // SetAddrBType(e.target.value);
                handleChange(e);
                setType(e.target.value)
              }}
              name="type"
            //   value={forms.type}
            >
              <Radio value={ADDRB_TYPE_CODE[ADDRB_TYPE.SENDER]}>Sender</Radio>
              <Radio value={ADDRB_TYPE_CODE[ADDRB_TYPE.RECEIVER]}>
                Receiver
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={9}>
          <Form.Item name="email" label="Email">
            <Input
              type="email"
              name="email"
              value={forms.email}
              onChange={handleChange}
              placeholder="example@example.com"
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="contact" label="Contact">
            <NumericInput
              value={forms.contact}
              onChange={(e) => setForms((p) => ({ ...p, contact: e }))}
              addonBefore={prefixContactSelector}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={14}>
          <Form.Item name="address" label="Address">
            <Input
              name="address"
              value={forms.address}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={9}>
          <Form.Item name="postcode" label="Postcode">
            <Input
            //   type="number"
              maxLength={5}
              placeholder="Eg: 50100"
              name="postcode"
              value={forms.postcode}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="city" label="City">
            <Input
              placeholder="Eg: WP Kuala Lumpur"
              name="city"
              value={forms.city}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <Form.Item name="state" label="State">
            <Select
              name="state"
              value={forms.state}
              onChange={(value) => {
                setForms((prev) => ({ ...prev, state: value }));
              }}
              placeholder="-- SELECT --"
              options={[
                { value: "Johor", label: "Johor" },
                { value: "Kedah", label: "Kedah" },
                { value: "Melaka", label: "Melaka" },
                { value: "Negeri Sembilan", label: "Negeri Sembilan" },
                { value: "Pahang", label: "Pahang" },
                { value: "Penang", label: "Penang" },
                { value: "Perak", label: "Perak" },
                { value: "Perlis", label: "Perlis" },
                { value: "Sabah", label: "Sabah" },
                { value: "Sarawak", label: "Sarawak" },
                { value: "Selangor", label: "Selangor" },
                { value: "Trengganu", label: "Trengganu" },
                { value: "WP Labuan", label: "WP Labuan" },
                { value: "WP Putrajaya", label: "WP Putrajaya" },
                { value: "WP Kuala Lumpur", label: "WP Kuala Lumpur" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default AddEditFormContent;
