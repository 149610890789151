import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Result, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { ROOT_CONSTANT } from "../../../../../constants";

import { AddEditAddressBookForm, AddressBookList } from "./components";

import { REDUX as REDUX_UTIL } from "../../../../../services/util";

import * as REDUX_ACTION from "../../../../../services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL;
const { THEME } = ROOT_CONSTANT;

const AddressBook = () => {
	const dispatch = useDispatch();

	const address = useSelector(reduxStoreWrapper("addressBook.byID"));
	const uid = useSelector(reduxStoreWrapper("user.uid"));
    const loading = useSelector(reduxStoreWrapper("style.loading.address_book"))

	const dataSource = Object.values(address).filter((addr) => addr.activeStatus);

	useEffect(() => {
		dispatch(
			REDUX_ACTION.v1_address_book.get_address_book_thru_uid_request(uid)
		);

		return () => {};
	}, [dispatch]);

	const [ add, setAdd ] = useState(false);
	const [ edit, setEdit ] = useState(false);
    const [ type, setType ] = useState(1)

	const [editForm, setEditForm] = useState({});
    const [ messageApi, contextHolder ] = message.useMessage()

	return (
		<>
            {contextHolder}
			<h1 style={{ padding: "15px 0", fontSize: "22px" }}>
				Address Book
			</h1>
			{!edit && !add ? (
                <>
                {
                    loading
                    &&
                    <Result
                        icon={
                            <LoadingOutlined
                                style={{ fontSize: 96, color: THEME.THEME_COLOR }}
                                spin
                            />
                        }
                        title="Loading..."
                    />
                    ||
                    <AddressBookList
                        setAddTrue={() => setAdd(true)}
                        setEditTrue={() => setEdit(true)}
                        setEditForm={setEditForm}
                        dataSource={dataSource}
                        setType={setType}
                        type={type}
                    />
                }
                </>
			) : (
				<AddEditAddressBookForm
					setAddFalse={() => setAdd(false)}
					setEditFalse={() => setEdit(false)}
					edit={edit}
                    type={type}
                    setType={setType}
					editForm={editForm}
					uid={uid}
					setEditForm={setEditForm}
					add={add}
                    messageApi={messageApi}
				/>
			)}
		</>
	);
};

export default AddressBook;
