// eslint-disable-next-line import/namespace
// Constants
import { ROOT_CONSTANT } from "../../../../../../../../../constants";

// Components
import { ButtonLink } from "../../../../../../../../../components";

// Style
import styles from "../../../../../../../common.module.css";

const { BACK_ICON } = ROOT_CONSTANT.IMG.ICON;

const AddEditFormAction = ({
    setEditFalse,
    setAddFalse,
    setType,
    edit,
    handleSubmit
}) => {
    return (
        <div className={styles["form-action"]}>
            <ButtonLink
                style={{
                    display: "flex",
                    gap: "8px",
                    fontSize: "18px",
                    color: "#000",
                }}
                onClick={edit ? setEditFalse : setAddFalse}
            >
                <img src={BACK_ICON} alt="" />
                <h3>
                    <b>{edit ? "Edit" : "Add"}</b>
                </h3>
            </ButtonLink>
            <div className={styles.right}>
                <button
                    type="submit"
                    className={styles["reset-button"]}
                    style={{ color: "#FF5F1F" }}
                    onClick={handleSubmit}
                >
                    Save
                </button>
                <button
                    type="button"
                    className={styles["reset-button"]}
                    onClick={edit ? setEditFalse : setAddFalse}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default AddEditFormAction;
