export const COMMON_ACTION = {
    EDIT: 'EDIT',
    UPDATE: 'UPDATE',
    ADD: 'ADD',
    CREATE: 'CREATE',
    DELETE: 'DELETE',
    ARCHIVE: 'ARCHIVE',
    READ: 'READ'
}

export const COMMON_ACTION_TITLE = {
    [COMMON_ACTION.EDIT]: 'Edit',
    [COMMON_ACTION.ADD]: 'Add',
    [COMMON_ACTION.CREATE]: 'Create',
    [COMMON_ACTION.DELETE]: 'Delete',
    [COMMON_ACTION.ARCHIVE]: 'Archive'
}

export const ACTION_API = {
    [COMMON_ACTION.EDIT]: 'U',
    [COMMON_ACTION.UPDATE]: 'U',
    [COMMON_ACTION.ADD]: 'C',
    [COMMON_ACTION.CREATE]: 'C',
    [COMMON_ACTION.DELETE]: 'D',
    [COMMON_ACTION.ARCHIVE]: 'D',
    [COMMON_ACTION.READ]: 'R'   
}