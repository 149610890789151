// React Thirty Party Library
// import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FloatButton } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

// Constants Library
import { APP_INFO } from "../../config";
import { TEMPLATE } from "../../constants";

// Components Library
import {
  HubInformation,
  PricingList,
  ProgressSummary,
  TopUpInformation,
} from "./components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../.../../../services/util";

// Redux Actions
// import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import styles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ORDER_STATUS } = TEMPLATE.ORDER;

const Dashboard = () => {
  // const dispatch = useDispatch();

  const points = useSelector(reduxStoreWrapper("point.byID"));
  const pointsIDs = useSelector(reduxStoreWrapper("point.allIDs"));
  const hubs = useSelector(reduxStoreWrapper("hub.byID"));
  const hubsIDs = useSelector(reduxStoreWrapper("hub.allIDs"));
  const wallet = useSelector(reduxStoreWrapper("wallet"));
  const user = useSelector(reduxStoreWrapper("user"));
  const orderStates = useSelector(reduxStoreWrapper("order"));
  // const loadingOSP = useSelector(reduxStoreWrapper("style.loading.order_state_proceeding"));
  const loadingOSP = useSelector(reduxStoreWrapper("style.loading.order_list"));

  const statusLen = (arrs = []) =>
    arrs.reduce((acc = [], cur) => (cur && [...acc, ...cur]) || acc, []).length;

  const initialPoint = Object.values(points).find((p) => p.hubid);
  const initialHub = Object.values(hubs).find((p) => p.hubid);

  // console.log(Object.values(hubs).length > 0 && initialHub);
  // console.log(Object.values(points).length > 0 && initialPoint);

  //   useEffect(() => {

  //     if(user.uid) {
  //         dispatch(
  //             REDUX_ACTION.v1_order.get_order_timeframe_thru_uid_last_90_days_request(
  //               user.uid
  //             )
  //           );
  //     }

  //     // return () => {};
  //   }, [dispatch, user.uid]);

  return (
    <div className={styles.container}>
      <div className={styles["container-head"]}>
        <ProgressSummary
          loading={loadingOSP}
          wallet={wallet.balance}
          unsorted_order_num={statusLen([orderStates.bySorted.UNSORTED])}
          pending_self_lodge_num={statusLen([
            orderStates.byStatus[ORDER_STATUS.PENDING_SELF_LODGE],
            orderStates.byStatus[ORDER_STATUS.PENDING_SELF_LODGE_POINT],
          ])}
          ongoing_order_num={statusLen([
            // orderStates.byStatus[ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER],
            orderStates.byStatus[ORDER_STATUS.CHECK_OUT_FROM_POINT],
            orderStates.byStatus[ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB],
            orderStates.byStatus[ORDER_STATUS.PENDING_OUTBOUND_TO_POINT],
            orderStates.byStatus[ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB],
            orderStates.byStatus[ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT],
            // orderStates.byStatus[ORDER_STATUS.PENDING_DOORSTEP_DELIVERY],
            orderStates.byStatus[ORDER_STATUS.ARRIVED_AT_DEST_HUB],
          ])}
          pending_self_collection_num={statusLen([
            orderStates.byStatus[ORDER_STATUS.PENDING_SELF_COLLECTION],
            orderStates.byStatus[ORDER_STATUS.PENDING_SELF_COLLECTION_POINT],
          ])}
          park_order_num={statusLen([
            orderStates.byStatus[ORDER_STATUS.PARKED],
          ])}
        />
      </div>

      <div className={styles["container-main"]}>
        <div>
          <TopUpInformation />
          {user?.uroleid &&
            (user.uroleid === "C" ? (
              <HubInformation
                hubs={hubs}
                hubsIDs={hubsIDs}
                hubInit={initialHub?.hubid}
              />
            ) : (
              <HubInformation
                hubs={points}
                hubsIDs={pointsIDs}
                hubInit={initialPoint?.hubid}
              />
            ))}
        </div>
        <div className={styles["container-submit"]}>
          <PricingList />
        </div>
      </div>

      <FloatButton
        type="success"
        shape="circle"
        style={{ position: "fixed", right: "8vw", backgroundColor: "#7AD06D" }}
        icon={<WhatsAppOutlined style={{ color: "#fff" }} />}
        onClick={() => window.open(APP_INFO.ASSET.WHATSAPP_NUMBER, "_blank")}
      />
    </div>
  );
};

export default Dashboard;
