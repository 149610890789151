import { ROOT_CONSTANT } from "../../../constants"

const {
    icon_wallet,
    icon_unsorted_order_num,
    icon_pending_self_lodge_num,
    icon_ongoing_order_num,
    icon_pending_self_collection_num,
    icon_park_order_num
} = ROOT_CONSTANT.IMG.ICON.DASHBOARD

export const PROGRESS_SUMMARY_FEATURE = {
    wallet: "wallet",
    unsorted_order_num: "unsorted_order_num",
    pending_self_lodge_num: "pending_self_lodge_num",
    ongoing_order_num: "ongoing_order_num",
    pending_self_collection_num: "pending_self_collection_num",
    park_order_num: "park_order_num"
}

export const PROGRESS_SUMMARY_TITLE = {
    wallet: "Wallet Balance (RM)",
    unsorted_order_num: "Unsorted Orders",
    pending_self_lodge_num: "Pending Self Lodge",
    ongoing_order_num: "In Transit Orders",
    pending_self_collection_num: "Pending Self-Collection",
    park_order_num: "Park Orders"
}

export const PROGRESS_SUMMARIES = {
    [PROGRESS_SUMMARY_FEATURE.wallet]: {
        icon: icon_wallet,
        title: PROGRESS_SUMMARY_TITLE.wallet
    },
    [PROGRESS_SUMMARY_FEATURE.unsorted_order_num]: {
        icon: icon_unsorted_order_num,
        title: PROGRESS_SUMMARY_TITLE.unsorted_order_num
    },
    [PROGRESS_SUMMARY_FEATURE.pending_self_lodge_num]: {
        icon: icon_pending_self_lodge_num,
        title: PROGRESS_SUMMARY_TITLE.pending_self_lodge_num
    },
    [PROGRESS_SUMMARY_FEATURE.ongoing_order_num]: {
        icon: icon_ongoing_order_num,
        title: PROGRESS_SUMMARY_TITLE.ongoing_order_num
    },
    [PROGRESS_SUMMARY_FEATURE.pending_self_collection_num]: {
        icon: icon_pending_self_collection_num,
        title: PROGRESS_SUMMARY_TITLE.pending_self_collection_num
    },
    [PROGRESS_SUMMARY_FEATURE.park_order_num]: {
        icon: icon_park_order_num,
        title: PROGRESS_SUMMARY_TITLE.park_order_num
    },
}