// React Thirty Party Library
import React, { useState, useEffect } from "react";
import { Select, Skeleton } from "antd";

// Constants Library
import { APP_CONFIG } from "../../../../config";
import { TEMPLATE } from "../../../../constants";

// Components Library
import {
  ButtonLink,
  ButtonCopy,
  GoogleMapIFrame,
} from "../../../../components";

// Handler Library

// Services Library
import { TIME } from "../../../../services/util";

// Redux Actions

// Styles Library
import styles from "../common.module.css";

const { GET_OPERATION_HOURS_ARR, HUB_OPTIONS } = TEMPLATE.ORDER;

const HubInformation = ({ hubInit, hubs: hubsList, hubsIDs }) => {
  const [hub, setHub] = useState(hubInit);

  const hubList = HUB_OPTIONS(hubsList, hubsIDs);

  useEffect(() => {
    if (hub === undefined) {
      setHub(hubInit);
    }
  }, [hubInit]);

  const coordinates = {
    lat: parseFloat(hubsList[hub]?.coordinate.lat),
    lon: parseFloat(hubsList[hub]?.coordinate.lon),
  };
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let businessDays;
  let businessDaysCopy;

  if (hubsList[hub]?.biz_hrs?.time) {
    const hubid = hubsList[hub].hubid;
    const hourCopy = hubsList[hubid].biz_hrs.time.split("/");
    const hour = hubsList[hub].biz_hrs.time.split("/");
    const resultArrayCopy = hourCopy.map((item) => item.split(","));
    const resultArray = hour.map((item) => item.split(","));
    const openHrs = resultArray[0] || [];
    const closeHrs = resultArray[1] || [];

    businessDays = days.map(function (day, index) {
      const isOpen = hubsList[hub].biz_hrs.day[index];
      const openHr = openHrs[index];
      const closeHr = closeHrs[index];

      const format = (e) => {
        const date = new Date(e * 1000);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        return `${hours}:${minutes}`;
      };

      return (
        <>
          <div key={index} style={{ display: "flex" }}>
            <p style={{ width: "70px" }}>{day}</p>
            <p>
              {isOpen ? `${format(openHr)} - ${format(closeHr)}` : "Closed"}
            </p>
          </div>
        </>
      );
    });
    businessDaysCopy = days.map(function (day, index) {
      const isOpen = hubsList[hub].biz_hrs.day[index];
      const openHr = openHrs[index];
      const closeHr = closeHrs[index];

      const format = (e) => {
        const date = new Date(e * 1000);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        return `${hours}:${minutes}`;
      };

      return `${day} = ${isOpen ? `${format(openHr)} - ${format(closeHr)}` : "Closed"}`;
    });
  }

  const onHubCopy = (hubid) => {
    const str = `
            ${hubsList[hubid]?.code}

            ${(hubsList[hubid] && hubsList[hubid].name) || "-"}

            ${(hubsList[hubid] && hubsList[hubid].address) || "-"}

            ${(hubsList[hubid] && hubsList[hubid].url) || "-"}

            ${
              (hubsList[hubid] &&
                hubsList[hubid].pic &&
                hubsList[hubid].pic.map((p) => `${p.name} ${p.contact}`)) ||
              "-"
            }
            

            ${
              (Array.isArray(hubsList[hubid].biz_hrs) &&
                GET_OPERATION_HOURS_ARR(hubsList[hub].biz_hrs)
                  .map(
                    (hr) =>
                      `${hr.day}   ${
                        hr.hour ? TIME.getHoursNameFromEpoch(hr.hour) : "Closed"
                      } \n`
                  )
                  .join("\t    ")) ||
              (typeof hubsList[hubid]?.biz_hrs.day === "string" ? (
                `${hubsList[hubid].biz_hrs.day} ${hubsList[hubid].biz_hrs.time} `
              ) : (
                <div>{businessDays}</div>
              ))
            }
            
            `;

    return str;
  };

  const isLoading = hubsList[hub] === undefined;
  const name = `(${hubsList[hub] && hubsList[hub].code}) ${
    hubsList[hub] && hubsList[hub].name
  } `;

  return (
    <>
      <Skeleton loading={isLoading} active>
        {!isLoading && (
          <>
            <h2 className={styles["label-title"]}>Hub Information</h2>
            <Select
              style={{
                width: 280,
                marginBottom: "15px",
              }}
              defaultValue={hubsList[hub] && name}
              onChange={(val) => setHub(val)}
              options={hubList}
            />
            {/* <Skeleton loading={isLoading} active>
            {!isLoading && ( */}
            <div className={styles["card-location"]}>
              <GoogleMapIFrame
                title={hubsList[hub]?.code}
                APIKey={APP_CONFIG.API_KEY.GOOGLE_MAP}
                coordinate={coordinates}
                width="100%"
              />
              <div className={styles["card-location-detail"]}>
                <strong>{`(${hubsList[hub].code}) ${hubsList[hub].name}`}</strong>
                <p className={styles["card-location-content"]}>
                  {hubsList[hub]?.address}
                </p>
                <ButtonLink
                  onClick={() =>
                    window.open(hubsList[hub]?.url, "_blank", "noreferrer")
                  }
                >
                  {hubsList[hub].url}
                </ButtonLink>
                <ul className={styles["card-location-content"]}>
                  {hubsList[hub]?.pic.map((i, key) => (
                    <li key={key}>
                      {i.name} {i.contact}
                    </li>
                  ))}
                </ul>
                <div className={styles["hub-copy"]}>
                  <div className={styles["card-location-content"]}>
                    {(Array.isArray(hubsList[hub].biz_hrs) &&
                      GET_OPERATION_HOURS_ARR(hubsList[hub].biz_hrs).map(
                        (bz, i) => (
                          <div
                            key={i}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "40% 80%",
                              width: "10em",
                            }}
                          >
                            <p>{bz.day}</p>
                            <p>
                              {" "}
                              {bz.hour
                                ? TIME.getHoursNameFromEpoch(bz.hour)
                                : "Closed"}
                            </p>
                          </div>
                        )
                      )) ||
                      (typeof hubsList[hub]?.biz_hrs.day === "string" ? (
                        `${hubsList[hub].biz_hrs.day} ${hubsList[hub].biz_hrs.time} `
                      ) : (
                        <div>{businessDays}</div>
                      ))}
                  </div>
                  <ButtonCopy copiedtext={onHubCopy(hubsList[hub]?.hubid)} />
                </div>
              </div>
            </div>
          </>
        )}
      </Skeleton>
    </>
  );
};

export default HubInformation;
