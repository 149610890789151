// Third-party Library 
import { Form, Row, Col, Input, Select } from "antd";

const ProfileForm = ({
    optionsState
}) => {

    return (
        <>
            <Row>
                <Col span={8}>
                    <Form.Item label="Company Name" name={["company", "name"]} rules={[{ required: true }]} >
                        <Input name="name" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={16}>
                    <Form.Item 
                       name={["company", "address"]}
                      label="Address"
                      rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col span={8}>
                    <Form.Item name={["company", "postcode"]} label="Postcode" rules={[{ required: true, max: 5 }]}>
                    <Input
                        type="number"
                        placeholder="Eg: 50100"
                    />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={["company", "city"]} label="City" rules={[{ required: true }]}>
                        <Input
                            placeholder="Eg: WP Kuala Lumpur"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Form.Item name={["company", "state"]} label="State" rules={[{ required: true }]}>
                        <Select
                            placeholder="-- SELECT --"
                            options={optionsState}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default ProfileForm;
