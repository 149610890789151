import { Row, Col } from 'antd'

// Styles
import styles from './index.module.css'

const ProgressCircleNumber = (props) => {

    const UniHTMLCircle1 = 9312

    const {
        turn = 1,
        circles = 4
    } = props

    const arr = Array(circles).fill(1).map((x,i) => x+i)

    const uniCodeIncrement = (n) => String.fromCharCode(UniHTMLCircle1+n)

    return (
        <Row className={styles.progressRow}>
            {
                arr
                .map((x, i) => {
                    const active = i < turn
                    const color = active ? styles.circleActive : styles.circleInActive;
                    return <Col key={`circle#${x}`} className={`${styles.progressCol} ${color}`}>{uniCodeIncrement(i)}</Col>
                })
            }
        </Row>
    )
}

export default ProgressCircleNumber