import { Select } from "antd";

const { Option } = Select;

const FilterDays = ({
  placeholder,
  defaultValue,
  handleOptionDays,
  optionDay,
}) => {
  return (
    <Select
      showSearch
      placeholder={placeholder}
      defaultValue={defaultValue}
      style={{ width: 170 }}
      onChange={handleOptionDays}
    >
      {optionDay.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default FilterDays;
