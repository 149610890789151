import * as ActionTypes from "../action-types/style";

export const setLoadingGeneral = () => {
  return {
    type: ActionTypes.SET_LOADING_GENERAL,
  };
};

export const unsetLoadingGeneral = () => {
  return {
    type: ActionTypes.UNSET_LOADING_GENERAL,
  };
};

export const setLoadingTransactionList = () => {
  return {
    type: ActionTypes.SET_LOADING_TRANSACTION_LIST,
  };
};

export const unsetLoadingTransactionList = () => {
  return {
    type: ActionTypes.UNSET_LOADING_TRANSACTION_LIST,
  };
};

export const setLoadingBillingList = () => {
  return {
    type: ActionTypes.SET_LOADING_BILLING_LIST,
  };
};

export const unsetLoadingBillingList = () => {
  return {
    type: ActionTypes.UNSET_LOADING_BILLING_LIST,
  };
};

export const setLoadingAddressBook = () => {
  return {
    type: ActionTypes.SET_LOADING_ADDRESS_BOOK,
  };
};

export const unsetLoadingAddressBook = () => {
  return {
    type: ActionTypes.UNSET_LOADING_ADDRESS_BOOK,
  };
};

// Order

export const setLoadingOrderSubmit = () => ({type: ActionTypes.SET_LOADING_ORDER_SUBMIT})
export const unsetLoadingOrderSubmit = () => ({type: ActionTypes.UNSET_LOADING_ORDER_SUBMIT})

export const setLoadingOrderList = () => ({type: ActionTypes.SET_LOADING_ORDER_LIST})
export const unsetLoadingOrderList = () => ({type: ActionTypes.UNSET_LOADING_ORDER_LIST})

export const setLoadingOrderLog = () => ({type: ActionTypes.SET_LOADING_ORDER_LOG})
export const unsetLoadingOrderLog = () => ({type: ActionTypes.UNSET_LOADING_ORDER_LOG})

export const setLoadingOrderStateProceeding = () => ({type: ActionTypes.SET_LOADING_ORDER_STATE_PROCEEDING})
export const unsetLoadingOrderStateProceeding = () => ({type: ActionTypes.UNSET_LOADING_ORDER_STATE_PROCEEDING})