import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config
import { TIME } from "../../util";

// Services

// Redux Action
import { setLoadingBillingList, unsetLoadingBillingList } from "./style";

// Neccessary Actions
const { lastNDay, latestTime } = TIME;

export const get_billing_timeframe_thru_uid_last_90_days_request =
  (uid) => (dispatch) => {
    dispatch(
      get_billing_timeframe_thru_uid_request(uid, lastNDay(90), latestTime)
    );
  };

export const get_billing_timeframe_thru_uid_request =
  (uid, startTime, endTime) => (dispatch) => {
    dispatch(setLoadingBillingList());

    API.getBillingTimeFrameThruUID({
      uid,
      startTime,
      endTime,
    })
      .then((info) => {
        const { status, msg, billings } = info;

        if (status === 200) {
          dispatch(get_billing_timeframe_thru_uid_success(billings));
          message.success("Get Billing List Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingBillingList());
      });
  };

const get_billing_timeframe_thru_uid_success = (billing) => {
  return {
    type: ActionTypes.GET_BILLING_LIST,
    billing,
  };
};
