// React Thirty Party Library
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import { TableList } from "../../../../components";

// Handler Library
import { transactionHDLR } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions

// Styles Library

const { TABLE_TRANSACTION_INDEX, SELECT_OPTION_DAYS, OPTION_DAYS } =
  TEMPLATE.TRANSACTION;
const { TRANSACTION_SUMMARY_COL } = TEMPLATE.REPORT.TXN;
const { MODULE_ROUTE } = ROUTES;
const { reduxStoreWrapper } = REDUX_UTIL;

const TableTransaction = ({
  dataSource,
  filteredData,
  setFilteredData,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const navigate = useNavigate();

  const billing = Object.values(useSelector(reduxStoreWrapper("billing.byID")));

  const NavigateOrderSummary = (ordid) => {
    navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY], {
      state: { ordid },
    });
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //   const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //     confirm();
  //     setSearchText(selectedKeys[0]);
  //     setSearchedColumn(dataIndex);
  //   };

  // handler for search, for filtering data shown in table
  const handleSearch = (name, keyword) => {
    const filteredKeyword = dataSource.filter((data) =>
      data[name]?.includes(keyword)
    );

    setFilteredData(filteredKeyword);
  };

  const columns = TRANSACTION_SUMMARY_COL({
    redux: { billing },
    func: {
      NavigateOrderSummary,
      handleSearch,
    },
  });
  const sortedData = filteredData.sort((a, b) => b.createdAt - a.createdAt);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    createdAt: (a, b) => a.createdAt - b.createdAt,
  };

  const filtered = sortedData
    .filter((record) => {
      let include = true;

      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] && filteredInfo[key].length > 0) {
          include = include && filteredInfo[key].includes(record[key]);
        }
      });

      if (searchText) {
        if (searchedColumn === "txnid") {
          if (
            typeof record.txnid === "string" &&
            record.txnid.includes(searchText)
          ) {
            include = true;
          }
        }
        if (searchedColumn === "ordid") {
          if (
            typeof record.ordid === "string" &&
            record.ordid.includes(searchText)
          ) {
            include = true;
          }
        }
        if (searchedColumn === "billid") {
          if (
            typeof record.billid === "string" &&
            record.billid.includes(searchText)
          ) {
            include = true;
          }
        }
      }

      return include;
    })
    .sort((a, b) => {
      const { columnKey } = sortedInfo;
      return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
    });

  return (
    <>
      <TableList.DataList
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) =>
            setSelectedRowKeys(newSelectedRowKeys),
        }}
        dataSource={dataSource}
        filteredData={filtered}
        setFilteredData={setFilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionStatus={transactionHDLR.transactionStatus()}
        optionType={transactionHDLR.transactionType()}
        dataDate={TABLE_TRANSACTION_INDEX.Transacted_At}
        defaultValue={`DATE : ${OPTION_DAYS.LAST_30_DAYS}`}
        text1={`TRANSACTION STATUS : ALL`}
        text2={`TRANSACTION TYPE : ALL`}
        columnData={columns}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableTransaction;
