// Third-party Library
import { useState, useEffect } from "react"
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Form, message } from 'antd'

// Constant
import { ROUTES, COMMON_FEATURES, TEMPLATE } from "../../../../constants";

// Redux Action
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Components 
import {
    OrderHeader,
    OrderSubmitted,
    OrderSummary,
    ParcelInformation,
    ReceiverInformation,
    SenderInformation
} from "./components"
import { ButtonLink } from "../../../../components";

// Services
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Handlers
import { reactHDLR } from "../../../../handlers";

// Styles 
import styles from "./index.module.css"

const { reduxStoreWrapper } = REDUX_UTIL

const { MODULE_ROUTE } = ROUTES

const { PARCELS_TOTAL_CALCULATION } = TEMPLATE.ORDER


const OrderSubmission = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector(reduxStoreWrapper('user'))
    const points = useSelector(reduxStoreWrapper('point.byID'))
    const zones = useSelector(reduxStoreWrapper("zone.byZoneid"))

    const [ form ] = Form.useForm()

    /*
        the reason of submitting through useState is,
        the Form is constantly changing due to dynamic-changing component.
        at the same time, this 'constant changing behavior' also needed by one-by-one form validation
    */
    const [ step, setStep ] = useState(0)  // step value
    const [ order, setOrder ] = useState({})  // for order value in payload
    const [ orderCb, setOrderCb ] = useState({})
    const [ loading, setLoading ] = useState(false) // loading button condition
    const [ tempStep, setTempStep ] = useState(0) // used for set up the clickable step 

    /**
     * 
     * Pricing logic in Point-to-Point Order
     *  
    **/
    user.uroleid === 'C' && delete order.price
    
    // get zoneid for needed get_price API param 
    const zoneOrigin = points[order.origid]?.zone
    const zoneDest = points[order.dstid]?.zone

    useEffect(() => {
        if(user.uroleid === 'POP' && zoneOrigin) {
            dispatch(REDUX_ACTION.v1_zone.get_price_list_thru_zonid_request(zoneOrigin)) // call get_zone_price
        }
    }, [zoneOrigin])

    // zone price calculation
    useEffect(() => {
        if(zoneOrigin) {
            const total_dimension = PARCELS_TOTAL_CALCULATION(order.parcel.unsort).total_volumes
            let zonePrice = zones[zoneOrigin]?.find(zone => zone.origin === zoneOrigin && zone.dest === zoneDest)?.price
    
            if(total_dimension > 0.00012) {
                zonePrice = zonePrice * 2
            }

            setOrder(order => ({
                ...order,
                price: parseFloat(zonePrice) || 0
            }))
        }
    }, [zones, zoneOrigin, zoneDest, order.parcel?.unsort])

    
    /**
     * 
     * Next-Previous button logic
     *  
    **/

    // Previous Step handler
    const prevStepHDLR = () => {

        if(step <= 3 && step > 0) {     // Step 3 is Order Summary
            setStep(step => step - 1)
        } 
        if(step === 4) {
            navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.ORDER_LIST]);
        }
    }

    // Next Step handler 
    const nextStepHDLR = async () => { // async needed for form validation

        await form
            .validateFields()
            .then(() => { 
                if(step <= 3) {     // Step 3 is Order Summary
                    const formValue = form.getFieldsValue()
                    setOrder({ ...order, ...formValue }) // storing to order state

                    setStep(step => step + 1)

                    tempStep <= step && setTempStep(step + 1)
                }
                if(step === 3) {
                    onSubmitHDLR()
                }
                if(step === 4) {    // Step 4 is Order Submitted
                    printSOHDLR()
                }
            })
            .catch(() => {
                message.error("fill the blank")
            })
    }

    /**
     * 
     * Submit Handler
     * 
     */
    const onSubmitHDLR = () => {

        const { uid, memid } = user

        order.parcel = { 
            ...order.parcel, 
            total_pieces: PARCELS_TOTAL_CALCULATION(order.parcel.unsort).total_pieces, 
            img: order.img || []
        }
        order.etd = TIME.dayJSMilliSecond(order.etd)
        order.eta = TIME.dayJSMilliSecond(order.eta)
        order.add_on = order.add_on !== (null || undefined) && [order.add_on] || [""] // Prevent null value

        if(order.addrbook_save_sender) {
            dispatch(REDUX_ACTION.v1_address_book.add_address_book_request({ addrb: {...order.sender, type: 1}, uid }))
        } 
        if(order.addrbook_save_receiver) {
            dispatch(REDUX_ACTION.v1_address_book.add_address_book_request({ addrb: {...order.receiver, type: 2}, uid }))
        } 

        // delete the unused
        delete order.img
        delete order.addrbook_save_sender 
        delete order.addrbook_save_receiver 

        // console.log(order)

        dispatch(REDUX_ACTION.v1_order.submit_order_request({
                order,
                uid,
                memid,
            },
            (cb) => {
                setOrderCb({ status: cb.status, msg: cb.msg, ordid: cb.order?.ordid })
            }
        ))}
        
    /**
     * 
     * Print Shipment Order Handler
     * 
     */

    const printSOHDLR = () => {

        message.loading('Getting shipment order...', 0)
        setLoading(true)

        dispatch(REDUX_ACTION.v1_shipmentOrder.get_shipment_order_thru_ordid_request(
            orderCb.ordid,
            res => {
                const { status, url } = res

                if(status === 200) {
                    reactHDLR.openInNewTab(url)
                }

                message.destroy()
                setLoading(false)
                
            }
        ))
    }

    // Components in every step
    const steps = [
        {
            title: <p className={styles["step"]}>Parcel Information</p>,
            content: <ParcelInformation setOrder={setOrder} order={order}/> 
        },
        {
            title: <p className={styles["step"]}>Sender Information</p>,
            content: <SenderInformation form={form} dstpid={order.dstid}/>
        },
        {
            title: <p className={styles["step"]}>Receiver Information</p>,
            content: <ReceiverInformation form={form} order={order} />
        },
        {
            title: <p className={styles["step"]}>Order Summary</p>,
            content: <OrderSummary order={order} form={form}/>
        },
        {
            title: <p className={styles["step"]}>Order Submitted</p>,
            content: <OrderSubmitted orderCb={orderCb}/>
        },
    ]

    const stepHDLR = (i) => {
        const formValue = form.getFieldsValue()
        setOrder({ ...order, ...formValue }) 
        
        setStep(i)
    }

    // modified array for OrderHeader's Steps
    const items = steps.map((s, i) => ({
        key: s.title,
        title: (i <= tempStep) && <ButtonLink onClick={() => stepHDLR(i)}>{s.title}</ButtonLink> || s.title
    }))

    return (
        <div className={styles["container"]}>
            <OrderHeader 
                step={step}
                items={items}
                loading={loading}
                status={orderCb?.status}
                nextStepHDLR={nextStepHDLR}
                prevStepHDLR={prevStepHDLR}
            />
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    sender: { contactPrefix: '+60' },
                    receiver: { contactPrefix: '+60' }
                }}
            >
                {steps[step]?.content}  {/* Show specific component based on step value */}
            </Form>
        </div>
    )
}

export default OrderSubmission