import * as API_CONSTANT from "../constants";

export const getTransactionTimeFrameThruUID = ({ uid, startTime, endTime }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/transaction/get/timeframe/uid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
      startTime,
      endTime,
    }),
  }).then((res) => res.json());

export const getUserWallet = ({ uid }) =>
  fetch(`${API_CONSTANT.cp_v1_fe_path}/user/getWallet`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
    }),
  }).then((res) => res.json());
