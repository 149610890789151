export const TABLE_TRANSACTION_INDEX = {
  key: "key",
  Transaction_Id: "txnid",
  Transacted_At: "createdAt",
  Transaction_Status: "status",
  Transaction_Type: "type",
  Before_Transaction: "amount_before",
  Amount: "amount",
  Wallet_Balance: "balance",
  // After_Transaction: "After_Transaction",
  Billing_ID: "billid",
  Attachment: "attachment",
  orderId: "ordid",
  Transacted_By: "transactedBy",
  Remarks: "remark",
};

export const TABLE_TRANSACTION_TITLE = {
  key: "key",
  Transaction_Id: "Transaction ID",
  Transacted_At: "Transacted At",
  Transaction_Status: "Transaction Status",
  Transaction_Type: "Transaction Type",
  Before_Transaction: "Before Transaction",
  Amount: "Amount",
  Wallet_Balance: "Wallet Balance",
  // After_Transaction: "After Transaction",
  Billing_ID: "Billing ID",
  Attachment: "Attachment",
  orderId: "Order ID",
  Transacted_By: "Transacted By",
  Remarks: "Remarks",
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "LAST 30 DAYS",
  LAST_60_DAYS: "LAST 60 DAYS",
  LAST_90_DAYS: "LAST 90 DAYS",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const OPTION_STATUS = {
  REJECTED: 404,
  SUCCESSFUL: 200,
};

export const OPTION_STATUS_TITLE = {
  [OPTION_STATUS.REJECTED]: { tag: "Rejected", color: "red", status: 404 },
  [OPTION_STATUS.SUCCESSFUL]: {
    tag: "Successful",
    color: "green",
    status: 200,
  },
};

export const OPTION_TYPE = {
  Topup: "TXN001",
  Refund: "TXN002",
  Payment: "TXN003",
};

export const OPTION_TYPE_TITLE = {
  [OPTION_TYPE.Topup]: "Top Up",
  [OPTION_TYPE.Refund]: "Refund",
  [OPTION_TYPE.Payment]: "Payment",
};

export const OPTION_STATUS_ALL = "ALL";
export const OPTION_TYPE_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));

export const SELECT_OPTION_TYPE = Object.keys(OPTION_TYPE).map((option) => ({
  label: OPTION_TYPE[option],
  value: OPTION_TYPE[option],
}));

export const SELECT_OPTION_STATUS = Object.keys(OPTION_STATUS).map(
  (option) => ({
    label: OPTION_STATUS[option],
    value: OPTION_STATUS[option],
  })
);
